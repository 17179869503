import { styled } from '@grupoboticario/flora'

const Table = styled('table', {
  width: '100%',
  fontSize: '$bodySmall',
  lineHeight: '$bodySmallShort',
  fontWeight: '$medium',
  tableLayout: 'fixed',

  thead: {
    backgroundColor: '$backgroundSecondary',
    th: {
      width: '25%',
      padding: '$6 $2',
      verticalAlign: 'middle',

      '@mobile': {
        padding: '$6 $6',
      },
    },
  },

  tbody: {
    'tr:nth-child(even)': {
      backgroundColor: '$backgroundSecondary',
    },

    'tr:hover': {
      backgroundColor: '$nonPrimaryButtonHover',
    },

    td: {
      padding: '$5 $2',
      verticalAlign: 'middle',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',

      '@mobile': {
        padding: '$5 $6',
      },
    },
  },

  tr: {
    'th, td': {
      borderBottomWidth: '$hairline',
      borderBottomStyle: 'solid',
      borderBottomColor: '$nonInteractiveOutline',
    },
  },
})

export { Table }
