import React, { type ChangeEvent } from 'react'

import { Dropdown, DropdownItem, FloraTypography as Typography } from '@grupoboticario/flora-react'

import { useEvents } from '@shared/events'
import { useTranslation } from '@shared/i18n'

import { OrderStatusType } from '../orders-history.types'
import { Header } from './orders-header.styles'

interface OrdersHeaderProps {
  onStatusChange: (status: string) => void
  orderStatus: string
}

const dropdownItemObject = {
  [OrderStatusType.CREATED]: 'created',
  [OrderStatusType.APPROVED]: 'approved',
  [OrderStatusType.CANCELLED]: 'canceled',
  [OrderStatusType.SEPARATION]: 'separation',
  [OrderStatusType.TRANSPORT]: 'transport',
  [OrderStatusType.DELIVERED]: 'delivered',
}

const OrdersHeader: React.FC<OrdersHeaderProps> = ({ onStatusChange, orderStatus }) => {
  const { t } = useTranslation('orders', { keyPrefix: 'orderHistory.header' })
  const events = useEvents()

  const onChangeFilter = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value as unknown as string[]
    const selectedOptions = value.map((value: OrderStatusType) => t(dropdownItemObject[value]))

    events.clickOrderFilter(selectedOptions)

    onStatusChange(event.target.value)
  }

  return (
    <Header align="center" justify="space-between">
      <Typography
        as="h3"
        fontSize="bodyLargeStandard"
        fontWeight="medium"
        css={{ color: '$nonInteractivePredominant' }}
      >
        {t('title')}
      </Typography>

      <Dropdown
        id="dropdown-status"
        labelText={t('filter')}
        onChange={onChangeFilter}
        css={{ width: '100%', '@tablet': { width: '270px' } }}
        value={orderStatus ?? ''}
        multiple
      >
        {Object.entries(dropdownItemObject).map(([value, label], index) => (
          <DropdownItem key={`${index}-${label}`} value={value}>
            {t(label)}
          </DropdownItem>
        ))}
      </Dropdown>
    </Header>
  )
}

export { OrdersHeader }
