import { toLocaleDate } from '@grupoboticario/vdi-mfe-utils'

import { useTranslation } from '@shared/i18n'

import { ResellerAppStatus } from '../reseller-app-status'

import { Container, ContainerDescription, LastAccess } from './reseller-app.styles'
import { type ResellerAppProps } from './reseller-app.types'

const ResellerApp = ({ description, enabled, appLastAccess }: ResellerAppProps): JSX.Element => {
  const { t } = useTranslation('resellerApp')

  const renderDate = appLastAccess && (
    <LastAccess as="span">
      {t('appLastAccessDate', { appLastAccess: toLocaleDate(appLastAccess) })}
    </LastAccess>
  )

  return (
    <Container align="center">
      <ResellerAppStatus enabled={enabled} />

      <ContainerDescription>
        {description}
        {renderDate}
      </ContainerDescription>
    </Container>
  )
}

export { ResellerApp }
