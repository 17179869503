import { useConfigCatClient } from 'configcat-react'
import { useCallback, useRef, useState } from 'react'

import { useApiData, type UseApiDataOutput } from '@shared/api'

import { type ResellerTitlesDTO } from './reseller-bills.types'

type UseResellerTitles = UseApiDataOutput<ResellerTitlesDTO> & {
  onPaginate: (page: number) => void
  page: number
  limit: number
}

const useResellerTitles = (resellerId: string): UseResellerTitles => {
  const [page, onPaginate] = useState(1)
  const client = useConfigCatClient()
  const limitRef = useRef(5)

  const getApiParams = useCallback(async () => {
    const limit = await client.getValueAsync('billsPerPage', limitRef.current)
    limitRef.current = limit
    return { page, limit, resellerId }
  }, [client, page, resellerId])

  const apiData = useApiData<ResellerTitlesDTO>({
    dataSource: 'getResellerTitles',
    pollingOnError: true,
    getApiParams,
  })

  return {
    ...apiData,
    limit: limitRef.current,
    onPaginate,
    page,
  }
}

export { useResellerTitles }
