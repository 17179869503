import { styled, Tabs } from '@grupoboticario/flora-react'

const StyledTabs = styled(Tabs, {
  height: 'calc(100%)',

  '@mobile': {
    height: 'calc(100% - 48px)',
  },
})

export { StyledTabs }
