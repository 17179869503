import { createContext, useContext, useState, type Dispatch, type SetStateAction } from 'react'

interface AppStateProps {
  withoutStructure: boolean
}

interface AppProviderProps {
  children: React.ReactNode
}

interface AppContextProps {
  appState: AppStateProps
  setAppState: Dispatch<SetStateAction<AppStateProps>>
}

const Context = createContext<AppContextProps | null>(null)

const useAppContext = (): AppContextProps => {
  const context = useContext(Context)

  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider')
  }

  return context
}

const AppProvider = ({ children }: AppProviderProps): JSX.Element => {
  const [appState, setAppState] = useState<AppStateProps>({ withoutStructure: false })

  return <Context.Provider value={{ appState, setAppState }}>{children}</Context.Provider>
}

export { AppProvider, useAppContext }
