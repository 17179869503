import { Flex, Image } from '@grupoboticario/flora-react'
import { SquareArrowRightUpIcon } from '@grupoboticario/flora-react-icons'

import { useResellerDataTabControl } from '@reseller-data/hooks'
import { type ResellerClub } from '@reseller-data/providers'
import { ResellerDataTab } from '@reseller-data/reseller-data.types'

import { DataBlock } from '@shared/components'
import { Description } from '@shared/components/data-block'
import { useIsMobile } from '@shared/hooks'
import { useTranslation } from '@shared/i18n'
import { ClickableOverlay, Content, FidelityTabButton } from './fidelity.styles'

interface FidelityProps {
  club?: ResellerClub
}

const Fidelity = ({ club }: FidelityProps): JSX.Element => {
  const { t } = useTranslation('fidelity')
  const isMobile = useIsMobile()
  const { setActiveTab } = useResellerDataTabControl()

  const renderFidelityValue = club?.value ? club.value : t('withoutSegmentation')

  const onClickGoToFidelityButton = (): void => {
    setActiveTab(ResellerDataTab.PROFILE)
  }

  return (
    <DataBlock
      title={club?.isMcm ? t('titleMcm') : t('title')}
      content={
        <Content>
          <Flex align="center" gap="$4">
            <Description>{renderFidelityValue}</Description>

            <FidelityTabButton
              has="iconOnly"
              icon={<SquareArrowRightUpIcon />}
              hierarchy="tertiary"
              size="small"
              onClick={onClickGoToFidelityButton}
              aria-label={t('redirectToFidelityButton')}
            />
          </Flex>

          {club?.imageUrl && (
            <Image
              src={club?.imageUrl}
              alt={t('fidelityIconAlt', { tier: club?.value })}
              size={40}
              css={{ position: 'absolute', right: 0, top: '-16px' }}
            />
          )}
          {isMobile && (
            <ClickableOverlay
              onClick={onClickGoToFidelityButton}
              role="button"
              aria-label={t('redirectOverlayLabel')}
            />
          )}
        </Content>
      }
    />
  )
}

export { Fidelity, type FidelityProps }
