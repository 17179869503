import { Box, Flex, FloraTypography as Typography, useMaxWidth } from '@grupoboticario/flora-react'
import { MovementsTable } from '@shared/components/movements-table'
import { useTranslation } from '@shared/i18n'
import { CCR_MOVEMENTS_PAGE_SIZE as PAGE_SIZE } from '../ccr-movements'
import { CCRMovement } from '../ccr-movements.types'
import { CCRMovementAmount } from './ccr-movement-amount'
import { Situation } from './situation'

interface CCRMovementTable {
  loading: boolean
  data: CCRMovement[]
}

const CCRMovementTable: React.FC<CCRMovementTable> = ({ loading, data }) => {
  const { t } = useTranslation(['resellerCCRMovements', 'generalData'])

  const isSmallScreen = useMaxWidth('1420px')

  return (
    <Box css={{ overflowX: 'auto' }}>
      <MovementsTable
        data={data || []}
        loading={loading}
        renderHeader={() => {
          return (
            <thead>
              <tr>
                <th scope="col">{isSmallScreen ? t('date') : t('creationDate')}</th>
                <th scope="col">{t('amountInReais')}</th>
                <th scope="col">{t('origin')}</th>
                <th style={{ maxWidth: '130px' }} scope="col">
                  {t('situation')}
                </th>
                <th scope="col">{t('useDate')}</th>
              </tr>
            </thead>
          )
        }}
        renderRow={(item) => {
          const {
            date,
            amount,
            operationType,
            situation,
            origin,
            originDescription,
            useDate,
            useDescription,
          } = item
          const formattedCreationDate = new Date(date).toLocaleDateString()
          const formattedUseDate = useDate ? new Date(useDate).toLocaleDateString() : '---'
          return (
            <tr key={item.amount.toString().concat(date)}>
              <td>{formattedCreationDate}</td>
              <td>
                <CCRMovementAmount amount={amount} operationType={operationType} />
              </td>
              <td style={{ paddingTop: '4px', paddingBottom: '4px' }}>
                <Flex direction="column">
                  {origin}
                  <Typography
                    fontSize="exceptionsRestricted"
                    fontWeight="medium"
                    css={{ color: '$nonInteractiveAuxiliar', whiteSpace: 'initial' }}
                  >
                    {originDescription}
                  </Typography>
                </Flex>
              </td>
              <td>
                <Situation situation={situation} />
              </td>
              <td style={{ paddingTop: '4px', paddingBottom: '4px' }}>
                {formattedUseDate}
                <Typography
                  fontSize="exceptionsRestricted"
                  fontWeight="medium"
                  css={{ color: '$nonInteractiveAuxiliar', whiteSpace: 'initial' }}
                >
                  {useDescription}
                </Typography>
              </td>
            </tr>
          )
        }}
        pageSize={PAGE_SIZE}
        scrollable
      />
    </Box>
  )
}

export { CCRMovementTable }
