import {
  CircleDashIcon,
  ExclamationMarkTriangleFillIcon,
  TwoPeopleIcon,
} from '@grupoboticario/flora-react-icons'

import { Container, Message } from './information.styles'

interface InformationProps {
  status: 'error' | 'success' | 'warning'
  message: string
  icon?: JSX.Element
}

const statusIcon = {
  error: <CircleDashIcon color="$statusErrorNonInteractiveEmphasis" data-testid="error-icon" />,
  success: <TwoPeopleIcon color="$statusInfoNonInteractiveEmphasis" data-testid="success-icon" />,
  warning: (
    <ExclamationMarkTriangleFillIcon
      color="$statusAlertNonInteractiveEmphasis"
      data-testid="warning-icon"
    />
  ),
}

const Information = ({ status, message, icon }: InformationProps): JSX.Element => (
  <Container role="alert" status={status}>
    {icon ?? statusIcon[status]}
    <Message as="p">{message}</Message>
  </Container>
)

export { Information }
