import { FilterTypes } from '../mcm-fidelity.types'

const mapMovementTypeToString = (type: FilterTypes | string): string => {
  switch (type) {
    case FilterTypes.All:
      return 'all'
    case FilterTypes.Compra:
      return 'purchase'
    case FilterTypes.Cancelado:
      return 'cancelled'
    case FilterTypes.AjusteManual:
      return 'manualAdjustment'
    case FilterTypes.Bonificacao:
      return 'bonification'
    case FilterTypes.Estorno:
      return 'return'
    case FilterTypes.Expiracao:
      return 'expiration'
    case FilterTypes.Resgate:
      return 'redeem'
    case FilterTypes.MissaoCumprida:
      return 'missionAccomplished'
    case FilterTypes.UsoDeBeneficio:
      return 'benefitUse'
    case FilterTypes.CampanhaAniversario:
      return 'birthdayCampaign'
    default:
      return ''
  }
}

export { mapMovementTypeToString }
