import { Hidden, Tag, FloraTypography as Typography } from '@grupoboticario/flora-react'

import {
  CalendarIcon,
  CardIcon,
  DollarSignCircleIcon,
  ShoppingBagIcon,
} from '@grupoboticario/flora-react-icons'

import { toCurrency, toLocaleDate } from '@grupoboticario/vdi-mfe-utils'

import { AccordionItem } from '@shared/components'
import { useEvents } from '@shared/events'
import { useTranslation } from '@shared/i18n'

import { ResellerBillsDownloadButton } from '../reseller-bills-download-button'
import { type ResellerTitle } from '../reseller-bills.types'

import {
  ContainerTags,
  HeaderExtra,
  HeaderText,
  InfoContentExtra,
  ItemContainer,
} from './reseller-bills-item.styles'

import {
  ResellerPaymentStatus,
  ResellerTitleStatus,
  type TagProps,
} from './reseller-bills-item.types'

interface ResellerBillsItemProps {
  title: ResellerTitle
}

const ResellerBillsItem = ({ title }: ResellerBillsItemProps): JSX.Element => {
  const { t } = useTranslation('resellerBillsItem')
  const events = useEvents()
  let billExpanded = false

  const {
    emissionDate,
    expirationDate,
    orderId,
    paymentStatus,
    paymentType,
    paymentPlan,
    titleId,
    titleStatus,
    value,
  } = title

  const onClickAccordion = (): void => {
    if (billExpanded) {
      billExpanded = false
    } else {
      billExpanded = true
      events.clickBilletAccordion(titleId)
    }
  }

  const tagList = [titleStatus, paymentStatus].map((item, index) => {
    const { text, color } = getTag(item)

    if (!text) {
      return null
    }

    return (
      <Tag shape="ghost" key={index} variant={color}>
        {t(text)}
      </Tag>
    )
  })

  const headerInfoContent = (
    <HeaderExtra>
      <ContainerTags>{tagList}</ContainerTags>

      <InfoContentExtra>
        <HeaderText as="p">{t('orderId', { orderId })}</HeaderText>
        <HeaderText as="p">
          {t('emissionDate', { emissionDate: toLocaleDate(emissionDate) })}
        </HeaderText>
      </InfoContentExtra>
    </HeaderExtra>
  )

  const textSize = 'bodyLargeStandard'
  const textWeight = 'medium'
  const textColor = '$nonInteractivePredominant'
  const iconColor = '$9'
  const iconSize = '16px'

  const accordionItemContent = (
    <ItemContainer data-testid="reseller-bills-item">
      <Hidden from="desktop">
        <div style={{ gridArea: 'emissionDate' }}>
          <CalendarIcon size={iconSize} color={iconColor} />
          <Typography fontSize={textSize} fontWeight={textWeight} css={{ color: textColor }}>
            {t('emissionDate', { emissionDate: toLocaleDate(emissionDate) })}
          </Typography>
        </div>
      </Hidden>

      <div style={{ gridArea: 'expirationDate' }}>
        <CalendarIcon size={iconSize} color={iconColor} />
        <Typography fontSize={textSize} fontWeight={textWeight} css={{ color: textColor }}>
          {t('expirationDate', { expirationDate: toLocaleDate(expirationDate) })}
        </Typography>
      </div>

      <Hidden from="desktop">
        <div style={{ gridArea: 'orderId' }}>
          <ShoppingBagIcon size={iconSize} color={iconColor} />
          <Typography fontSize={textSize} fontWeight={textWeight} css={{ color: textColor }}>
            {t('orderId', { orderId })}
          </Typography>
        </div>
      </Hidden>

      <div style={{ gridArea: 'titleValue' }}>
        <DollarSignCircleIcon size={iconSize} color={iconColor} />
        <Typography fontSize={textSize} fontWeight={textWeight} css={{ color: textColor }}>
          {t('titleValue', { value: toCurrency(value) })}
        </Typography>
      </div>

      <div style={{ gridArea: 'paymentType' }}>
        <CardIcon size={iconSize} color={iconColor} />
        <Typography fontSize={textSize} fontWeight={textWeight} css={{ color: textColor }}>
          {t('paymentType', { paymentType })}
        </Typography>
      </div>

      <div style={{ gridArea: 'paymentPlan' }}>
        <Typography fontSize={textSize} fontWeight={textWeight} css={{ color: textColor }}>
          {t('paymentPlan', { paymentPlan })}
        </Typography>
      </div>

      <div style={{ gridArea: 'downloadButton' }}>
        <ResellerBillsDownloadButton paymentType={paymentType} titleId={titleId} />
      </div>
    </ItemContainer>
  )

  return (
    <AccordionItem
      title={t('titleId', { titleId })}
      titleExtra={headerInfoContent}
      onClick={onClickAccordion}
      content={accordionItemContent}
    />
  )
}

const getTag = (type: string): TagProps => {
  switch (type) {
    case ResellerPaymentStatus.OPENED:
      return { text: 'status.opened', color: 'alert' }
    case ResellerPaymentStatus.PAID_OFF:
      return { text: 'status.paidOff', color: 'success' }
    case ResellerTitleStatus.OVERDUE:
      return { text: 'status.overdue', color: 'error' }
    case ResellerTitleStatus.NOT_OVERDUE:
      return { text: 'status.notOverdue', color: 'assorted9' }
    default:
      return { text: '', color: 'assorted9' }
  }
}

export { ResellerBillsItem, type ResellerBillsItemProps }
