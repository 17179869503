import { InformationCircleFillIcon } from '@grupoboticario/flora-react-icons'
import { Alert } from './not-found-state.styles'

import { useTranslation } from '@shared/i18n'

interface NotFoundStateProps {
  text?: string
}

const NotFoundState = ({ text }: NotFoundStateProps): JSX.Element => {
  const { t } = useTranslation('notFountState')

  return (
    <Alert>
      <InformationCircleFillIcon size="24px" color="$statusInfoNonInteractiveEmphasis" />
      <p>{text ?? t('defaultMessage')}</p>
    </Alert>
  )
}

export { NotFoundState, NotFoundStateProps }
