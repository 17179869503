import { styled } from '@grupoboticario/flora-react'

const ResumeContainer = styled('div', {
  display: 'flex',
  gap: '$2',
  flexWrap: 'wrap',

  '& > div': {
    flex: 1,
  },
})

const OrderInfoContainer = styled('p', {
  display: 'flex',

  '& span:first-child': {
    flex: 1,
  },
})

export { OrderInfoContainer, ResumeContainer }
