import { useMemo } from 'react'
import { useTranslation as useI18nTranslation, UseTranslationOptions } from 'react-i18next'

import { Language } from './locales'

interface UseTranslationOutput {
  t: (key: string, interpolation?: Record<string, string | number | undefined>) => string
  changeLang: (lang: Language) => void
}

const useTranslation = (
  namespace: string | string[],
  opts?: UseTranslationOptions<string>,
): UseTranslationOutput => {
  const { t, i18n } = useI18nTranslation(namespace, opts)

  return useMemo(
    () => ({
      t: (key, interpolation = {}) => t(key, interpolation),
      changeLang: (lang: Language) => void i18n.changeLanguage(lang),
    }),
    [i18n, t],
  )
}

export { useTranslation, Language }
