import { theme } from '@grupoboticario/flora-react'

import { DataBlock, DataSection, GridMobile } from '@shared/components'
import { useTranslation } from '@shared/i18n'
import { getCapitalizedText } from '@shared/utils'

import { useResellerDataView } from '../../../../hooks'

interface ZoneAndDistanceProps {
  text: string
  color: string
}

const ResellerSpace = (): JSX.Element => {
  const { t } = useTranslation('resellerSpace')
  const { error, filled, loading, model, refetch, notFound } = useResellerDataView()

  const defaultColor = theme.colors.nonInteractivePredominant.value
  const alertColor = theme.colors.statusAlertNonInteractiveEmphasis.value

  const getZoneER = (zoneER: string): ZoneAndDistanceProps => {
    if (!zoneER || zoneER === 'N/D') {
      return {
        text: t('notEnoughDataOnER'),
        color: alertColor,
      }
    }

    return {
      text: getCapitalizedText(zoneER, true),
      color: defaultColor,
    }
  }

  const getDistanceER = (distanceER: number): ZoneAndDistanceProps => {
    if (!distanceER || distanceER <= 0) {
      return {
        text: t('notEnoughDataOnER'),
        color: alertColor,
      }
    }

    return {
      text: `${distanceER} Km`,
      color: defaultColor,
    }
  }

  return (
    <DataSection
      data-testid="profile-data-space"
      title={t('title')}
      fallbackHeight={80}
      loading={loading}
      filled={filled}
      error={error}
      data={model}
      notFound={notFound}
      onRetry={refetch}
      render={({ zoneER, distanceER }) => (
        <GridMobile templateColumns="4fr 4fr" mobileTemplateColumns="4fr" gap="$1">
          <DataBlock title={t('zoneER')} description={getZoneER(zoneER)} />
          <DataBlock title={t('distanceER')} description={getDistanceER(distanceER)} />
        </GridMobile>
      )}
    />
  )
}

export { ResellerSpace }
