import { Flex, styled, FloraTypography as Typography } from '@grupoboticario/flora-react'

const Container = styled(Flex, {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  gap: '$2',
  width: '100%',
  height: '$10',
  borderRadius: '$medium',
  padding: '$2 $4',

  variants: {
    status: {
      success: {
        backgroundColor: '$statusInfoBackgroundDefault',
        color: '$statusInfoNonInteractiveEmphasis',
      },
      warning: {
        backgroundColor: '$statusAlertBackgroundDefault',
        color: '$statusAlertNonInteractiveEmphasis',
      },
      error: {
        backgroundColor: '$statusErrorBackgroundDefault',
        color: '$statusErrorNonInteractiveEmphasis',
      },
    },
  },
})

const Message = styled(Typography, {
  color: 'inherit',
  fontWeight: '$medium',
  lineHeight: '$normal',
  fontSize: '$bodySmall',

  '@mobile': {
    fontSize: '$bodySmall',
  },
})

export { Container, Message }
