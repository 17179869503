import { Flex, styled } from '@grupoboticario/flora-react'

const Container = styled(Flex, {
  flexDirection: 'column',
  gap: '$2',
  padding: '0 0 $4 0',
  marginBottom: '$4',

  '@mobile': {
    marginBottom: 0,
  },
})

export { Container }
