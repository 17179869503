import { createContext, useCallback, useContext, useState, type PropsWithChildren } from 'react'

import { MetaState } from '@shared/api/api.types'

import { type AttendanceItem } from '../../modules/attendance-list/attendance-list.types'

const AttendanceProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [currentAttendance, setCurrentAttendance] = useState<CurrentAttendance | null>(null)
  const [listState, setListState] = useState<AttendanceListState>({
    list: [],
    status: MetaState.IDLE,
  })

  const setList = useCallback((state: AttendanceListState) => {
    setListState((l) => ({ ...l, ...state }))
  }, [])

  return (
    <CurrentContext.Provider value={{ currentAttendance, setCurrentAttendance }}>
      <ListContext.Provider value={{ ...listState, setList }}>{children}</ListContext.Provider>
    </CurrentContext.Provider>
  )
}

interface AttendanceListContext extends AttendanceListState {
  setList: (list: Partial<AttendanceListState>) => void
}

interface AttendanceListState {
  list: AttendanceItem[]
  status: MetaState
}

const ListContext = createContext<AttendanceListContext>({
  list: [],
  status: MetaState.IDLE,
  setList() {},
})

const CurrentContext = createContext<CurrentAttendanceContext | null>(null)

const useCurrentAttendanceContext = (): CurrentAttendanceContext => {
  const context = useContext(CurrentContext)
  if (!context) throw new Error('Attendance context not found')
  return context
}

const useAttendanceListContext = (): AttendanceListContext => {
  return useContext(ListContext)
}

interface CurrentAttendance {
  resellerId: string
  resellerName: string
  attendanceId: string
}

interface CurrentAttendanceContext {
  currentAttendance: CurrentAttendance | null
  setCurrentAttendance: (attendance: CurrentAttendance | null) => void
}

export { AttendanceProvider, useAttendanceListContext, useCurrentAttendanceContext }
