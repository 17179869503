import { Box, Flex, FloraButton, styled } from '@grupoboticario/flora-react'

const EditButton = styled(FloraButton, {
  boxShadow: 'inset 0 0 0 2px transparent',
  transition: 'box-shadow 0.2s ease-out',
  width: '100%',

  '&:focus, &:focus-visible': {
    boxShadow: 'inset 0 0 0 2px $assorted9',
  },

  '@mobile': {
    width: 'initial',
  },
})

const Button = styled(FloraButton, {
  width: '150px',
})

const Form = styled(Box, {
  position: 'relative',
  width: '100%',
  height: 'calc(100vh - 82px - 32px)',

  '@mobile': {
    width: '350px',
  },
})

const FormActions = styled(Flex, {
  position: 'absolute',
  bottom: 0,
  width: '100%',
})

const AdditionalInfoContainer = styled(Flex, {
  flexDirection: 'column-reverse',
  alignItems: 'stretch',
  gap: '$2',

  '@mobile': {
    flexDirection: 'column',
  },
})

export { AdditionalInfoContainer, Button, EditButton, Form, FormActions }
