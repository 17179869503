import { TabItems } from './tab-items'
import { TabPanels } from './tab-panels'
import { StyledTabs } from './tabs.styles'

interface TabsProps {
  onTabChange: (value: string) => void
  activeTab: string
  resellerId: string
}

const Tabs = ({ onTabChange, activeTab, resellerId }: TabsProps): JSX.Element => {
  return (
    <StyledTabs value={activeTab} onValueChange={onTabChange} orientation="horizontal">
      <TabItems />
      <TabPanels resellerId={resellerId} />
    </StyledTabs>
  )
}

export { Tabs }
