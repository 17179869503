import {
  ActionType,
  CategoryType,
  EventName,
  InteractionDetail,
  TabName,
  type EventBaseData,
  type EventTracker,
} from './events.types'

interface GeneralEvents {
  clickShowMoreGeneralInfo: () => void
  clickShowLessGeneralInfo: () => void
  clickEditAdditionalInfo: () => void
  clickGeneralTab: () => void
  clickRetryGeneralData: () => void
}

const createGeneralEvents = (tracker: EventTracker, data: EventBaseData): GeneralEvents => ({
  clickRetryGeneralData() {
    tracker.track({
      event: 'analytics-event',
      event_parameters: {
        event_name: EventName.interactionAttendance,
        cd_interaction_detail: `${InteractionDetail.click}:tentar-novamente`,
        cd_codigo_atendimento: data.attendanceId,
        cd_codigo_representante: data.reId,
        cd_section: TabName.general,
      },
    })
  },
  clickGeneralTab: function () {
    tracker.track({
      event: 'event',
      action: ActionType.clickButton,
      category: CategoryType.general,
      label: 'menu_visao_geral',
    })
  },
  clickShowMoreGeneralInfo: function () {
    tracker.track({
      event: 'event',
      action: ActionType.clickButton,
      category: CategoryType.general,
      label: 'detalhes:mostrar-mais',
    })
  },
  clickShowLessGeneralInfo: function () {
    tracker.track({
      event: 'event',
      action: ActionType.clickButton,
      category: CategoryType.general,
      label: 'detalhes:mostrar-menos',
    })
  },
  clickEditAdditionalInfo: function () {
    tracker.track({
      event: 'event',
      action: ActionType.clickButton,
      category: CategoryType.general,
      label: 'editar-informacoes-adicionais',
    })

    tracker.track({
      event: 'analytics-event',
      event_parameters: {
        event_name: EventName.interactionAttendance,
        cd_interaction_detail: `${InteractionDetail.click}:editar-informacoes-adicionais`,
        cd_codigo_atendimento: data.attendanceId,
        cd_codigo_representante: data.reId,
        cd_section: `${TabName.general}:informacoes-adicionais`,
      },
    })
  },
})

export { createGeneralEvents, type GeneralEvents }
