import { AccordionItem as FloraAccordionItem, AccordionPanel } from '@grupoboticario/flora-react'

import { useTranslation } from '@shared/i18n'
import { Header, HeaderContainer, StyledAccordionContent, Title } from './accordion-item.styles'
import { type AccordionItemProps } from './accordion-item.types'

const AccordionItem = ({
  title,
  titleExtra,
  content,
  onClick,
}: AccordionItemProps): JSX.Element => {
  const { t } = useTranslation('accordion')

  return (
    <FloraAccordionItem onClick={onClick} as="div" value={title}>
      <Header role="button">
        <HeaderContainer as="div" aria-label={t('expandItem', { desc: title })}>
          <Title as="p">{title}</Title>
          {titleExtra}
        </HeaderContainer>
      </Header>

      <AccordionPanel wrap={false}>
        <StyledAccordionContent as="div">
          <div>{content}</div>
        </StyledAccordionContent>
      </AccordionPanel>
    </FloraAccordionItem>
  )
}

export { AccordionItem }
