import React, { PropsWithChildren } from 'react'

import {
  Drawer,
  DrawerCloseButton,
  DrawerProps,
  FloraTypography as Typography,
} from '@grupoboticario/flora-react'

import { useTranslation } from '@shared/i18n'

import { StyledDrawerBody, StyledDrawerContent, StyledDrawerHeader } from './order-drawer.styles'

interface OrderDrawerProps extends PropsWithChildren, DrawerProps {}

const OrderDrawer: React.FC<OrderDrawerProps> = ({ children, ...props }) => {
  const { t } = useTranslation('orders', { keyPrefix: 'detail.orderDrawer' })

  return (
    <Drawer {...props}>
      <StyledDrawerContent>
        <DrawerCloseButton />

        <StyledDrawerHeader>
          <Typography fontSize="subtitle" fontWeight="medium">
            {t('orderDetail')}
          </Typography>
        </StyledDrawerHeader>

        <StyledDrawerBody>{children}</StyledDrawerBody>
      </StyledDrawerContent>
    </Drawer>
  )
}

export { OrderDrawer }
