import { useContext } from 'react'

import { DrawerContext, type DrawerData } from '@shared/providers/drawer'

interface UseDrawerReturn {
  open: () => void
  close: () => void
}

const useDrawer = (props: DrawerData): UseDrawerReturn => {
  const context = useContext(DrawerContext)

  const open = (): void => {
    context?.open(props)
  }

  const close = (): void => {
    context?.close()
  }

  return {
    open,
    close,
  }
}

export { useDrawer }
