import { styled, TabPanel as TabPanelFlora } from '@grupoboticario/flora-react'

import { scrollbar } from '@shared/components'

const Carousel = styled('ul', {
  display: 'flex',
  overflow: 'auto',
  flexFlow: 'row nowrap',
  width: '100%',
  scrollSnapType: 'x mandatory',
  scrollbarWidth: 'none',
  paddingLeft: 0,
  paddingBottom: '$4',
  margin: 0,
  transition: 'scrollbar-width 0.2s ease-out',

  '&:hover': {
    ...scrollbar,
  },
})

const CarouselItem = styled('li', {
  display: 'flex',
  maxWidth: '344px',
  flex: 'none',
  scrollSnapAlign: 'center',

  '&:first-of-type': {
    marginLeft: '$5',
  },

  '&:not(:last-of-type)': {
    marginRight: '$2',
  },

  '&:last-of-type': {
    marginRight: '$5',
  },
})

const TabPanel = styled(TabPanelFlora, {
  paddingLeft: 0,
  paddingRight: 0,
})

export { Carousel, CarouselItem, TabPanel }
