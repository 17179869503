const scrollbar = {
  // scrollbar CSS to Firefox
  scrollbarColor: '#264fec transparent',
  scrollbarWidth: 'thin',

  // scrollbar CSS to Webkit browsers
  '&::-webkit-scrollbar': {
    width: '$1',
    position: 'absolute',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '$actionableDefault',
  },
}

export { scrollbar }
