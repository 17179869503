import { useFeatureFlag } from 'configcat-react'

import { useEvents } from '@shared/events'
import { useTranslation } from '@shared/i18n'

import { useResellerData } from '../../../hooks'
import { ResellerDataTab } from '../../../reseller-data.types'

import { Tab, TabList } from './tab-items.styles'

const TabItems = (): JSX.Element => {
  const { t } = useTranslation('tabItems')
  const { isReleasedAttendance, error } = useResellerData()
  const { value: promotionsTab } = useFeatureFlag('promotionsTab', false)
  const { value: ordersTab } = useFeatureFlag('ordersTab', false)
  const events = useEvents()

  const generalVisionText = t('generalVision')
  const resellerProfileText = t('resellerProfile')
  const resellerFinancialText = t('financial')
  const promotionalText = t('promotional')
  const ordersText = t('orders')

  const disabled = !isReleasedAttendance || error

  return (
    <TabList>
      <Tab
        disabled={disabled}
        aria-label={generalVisionText}
        value={ResellerDataTab.GENERAL}
        onClick={() => events.clickTabData('visao-geral')}
      >
        {generalVisionText}
      </Tab>

      <Tab
        disabled={disabled}
        aria-label={resellerProfileText}
        value={ResellerDataTab.PROFILE}
        onClick={() => events.clickTabData('perfil-re')}
      >
        {resellerProfileText}
      </Tab>

      {ordersTab && (
        <Tab
          disabled={disabled}
          aria-label={ordersText}
          value={ResellerDataTab.ORDERS}
          onClick={() => events.clickTabData('pedidos')}
        >
          {ordersText}
        </Tab>
      )}

      <Tab
        disabled={disabled}
        aria-label={resellerFinancialText}
        value={ResellerDataTab.FINANCIAL}
        onClick={() => events.clickTabData('financeiro')}
      >
        {resellerFinancialText}
      </Tab>

      {promotionsTab && (
        <Tab
          disabled={disabled}
          aria-label={promotionalText}
          value={ResellerDataTab.PROMOTIONAL}
          onClick={() => events.clickTabData('promocoes')}
        >
          {promotionalText}
        </Tab>
      )}
    </TabList>
  )
}

export { TabItems }
