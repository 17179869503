import { styled, FloraTypography as Typography } from '@grupoboticario/flora-react'

const Title = styled(Typography, {
  display: 'flex',
  flexDirection: 'row',
  gap: '$2',
  alignItems: 'center',
  width: '100%',
  color: '$nonInteractiveAuxiliar',
  fontSize: '0.6875rem',
  fontWeight: '$medium',
  lineHeight: '$short',
  letterSpacing: '0.08rem',
  textTransform: 'uppercase',
  marginBottom: 0,

  '> div': {
    textTransform: 'none',
  },
})

export { Title }
