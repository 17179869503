import { useContext } from 'react'

import { ModalContext, type ModalData } from '@shared/providers/modal/modal-provider'

interface UseModalReturn {
  open: () => void
  close: () => void
}

const useModal = (props: ModalData): UseModalReturn => {
  const context = useContext(ModalContext)

  const open = (): void => {
    context?.open(props)
  }

  const close = (): void => {
    context?.close()
  }

  return {
    open,
    close,
  }
}

export { useModal }
