import { useContext } from 'react'
import { FidelityDataContext, FidelityDataContextProps } from '../providers/fidelity-data-provider'

const useFidelityData = (): FidelityDataContextProps => {
  const context = useContext(FidelityDataContext)
  if (!context) throw new Error('Fidelity data context not found')

  return context
}

export { useFidelityData }
