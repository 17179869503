import React from 'react'

import { DataSection } from '@shared/components'

import { useTranslation } from '@shared/i18n'
import { useOrderDetailContext } from '../order-detail.provider'
import { PaymentTabs } from './payment-tabs'

const Payments: React.FC = () => {
  const { t } = useTranslation('orders', { keyPrefix: 'orderHistory.detail.payments' })
  const { data, filled, loading, error, refetch } = useOrderDetailContext()

  return (
    <DataSection
      title={t('payments')}
      data={data}
      filled={filled}
      error={error}
      loading={loading}
      onRetry={refetch}
      fallbackHeight={data?.payments.length ? 434 : 128}
      render={(detail) => <PaymentTabs detail={detail} />}
    />
  )
}

export { Payments }
