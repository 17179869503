import { Dropdown, DropdownItem, Flex } from '@grupoboticario/flora-react'
import { ThreeLinesHorizontal2Icon } from '@grupoboticario/flora-react-icons'
import { useTranslation } from '@shared/i18n'
import { ChangeEvent } from 'react'
import { useFidelityData } from '../../../hooks/use-fidelity-data'
import { mapMovementTypeToString } from '../../helpers/movement-type-to-string'
import { FilterTypes } from '../../mcm-fidelity.types'

const MovementFilter: React.FC = () => {
  const {
    movements: { filter, loading },
  } = useFidelityData()

  const { t } = useTranslation('resellerFidelityMovements')

  const onFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    filter.onChange(event.target.value as FilterTypes)
  }

  return (
    <Flex>
      <Dropdown
        as="div"
        id="dropdown-movements-filter"
        labelText={t('filterByType')}
        leftIcon={<ThreeLinesHorizontal2Icon />}
        css={{ width: '100%', '@desktop': { width: '295px' } }}
        value={filter.type}
        onChange={onFilterChange}
        errorSpacing={false}
        disabled={loading}
      >
        {Object.keys(FilterTypes).map((item) => (
          <DropdownItem key={item} value={FilterTypes[item as keyof typeof FilterTypes]}>
            {t(mapMovementTypeToString(FilterTypes[item as keyof typeof FilterTypes]))}
          </DropdownItem>
        ))}
      </Dropdown>
    </Flex>
  )
}

export { MovementFilter }
