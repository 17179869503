import React from 'react'

import { FloraButton as Button, Flex, Tag } from '@grupoboticario/flora-react'
import { TrayArrowDownIcon } from '@grupoboticario/flora-react-icons'
import { toCurrency, toLocaleDate } from '@grupoboticario/vdi-mfe-utils'

import { Card, CardData, CardDivider, CardHeader, DataSection } from '@shared/components'
import { useEvents } from '@shared/events'
import { useTranslation } from '@shared/i18n'
import { downloadFile } from '@shared/utils'

import { useOrderStatusTag } from '../../orders.hooks'
import { useOrderDetailContext } from '../order-detail.provider'
import { OrderDetails } from '../order-detail.types'
import { ResumeContainer } from './resume.styles'

const Resume: React.FC = () => {
  const { data, filled, loading, error, refetch } = useOrderDetailContext()

  return (
    <DataSection
      data={data}
      filled={filled}
      loading={loading}
      error={error}
      fallbackHeight={165}
      onRetry={refetch}
      render={(detail) => (
        <ResumeContainer>
          <CardOrderResume detail={detail} />
          <CardOrderResumeButtons detail={detail} />
        </ResumeContainer>
      )}
    />
  )
}

interface CardResumeProps {
  detail: OrderDetails
}

const CardOrderResume: React.FC<CardResumeProps> = ({ detail }) => {
  const { t } = useTranslation('orders', { keyPrefix: 'detail.resume' })
  const tag = useOrderStatusTag(detail)

  return (
    <Card>
      <CardHeader
        fontSize="bodyLargeShort"
        fontWeight="medium"
        title={
          <Flex as="span" gap="$2" align="center">
            {t('orderNumber', { number: detail.number })}

            <Tag size="small" shape="ghost" variant={tag.variant}>
              {tag.label}
            </Tag>
          </Flex>
        }
      />

      <CardDivider />

      <CardData
        fontSize="bodySmallShort"
        fontWeight="medium"
        text={t('total')}
        value={toCurrency(detail.totalValue)}
      />

      <CardData
        fontSize="bodySmallShort"
        fontWeight="medium"
        text={t('date')}
        value={toLocaleDate(detail.date)}
      />

      <CardData
        fontSize="bodySmallShort"
        fontWeight="medium"
        text={t('gain')}
        value={toCurrency(detail.suggestedProfit)}
      />

      <CardData
        fontSize="bodySmallShort"
        fontWeight="medium"
        text={t('gainPercentage')}
        value={`${detail.suggestedProfitPercentage}%`}
      />
    </Card>
  )
}

const CardOrderResumeButtons: React.FC<CardResumeProps> = ({ detail }) => {
  const { t } = useTranslation('orders', { keyPrefix: 'detail.resume' })
  const events = useEvents()

  const onClickDownloadButton = (type: 'extract' | 'invoice'): void => {
    downloadFile(type === 'extract' ? detail.orderStatement.url : detail.invoice)
    events.clickOrderButton(`baixar-${type === 'extract' ? 'extrato' : 'notafiscal'}`)
  }

  return (
    <Card>
      <CardHeader fontSize="bodyLargeShort" fontWeight="medium" title={t('extract')} />

      <Button
        size="small"
        hierarchy="secondary"
        has="iconLeft"
        icon={<TrayArrowDownIcon />}
        onClick={() => onClickDownloadButton('extract')}
      >
        {t('downloadExtract')}
      </Button>

      <Button
        size="small"
        hierarchy="secondary"
        has="iconLeft"
        icon={<TrayArrowDownIcon />}
        onClick={() => onClickDownloadButton('invoice')}
      >
        {t('downloadNote')}
      </Button>
    </Card>
  )
}

export { Resume }
