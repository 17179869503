import { Toaster } from '@grupoboticario/flora-react'

import { ErrorTypes, useApi } from '@shared/api'
import { useEvents } from '@shared/events'
import { useModal } from '@shared/hooks'
import { useTranslation } from '@shared/i18n'

import { useCurrentAttendanceContext } from '../providers'
import { useAttendanceList } from './use-attendance-list'

type UseStartAttendance = (resellerId: string, name: string) => Promise<string>

const useStartAttendance = (): UseStartAttendance => {
  const { setCurrentAttendance } = useCurrentAttendanceContext()
  const { createAttendance } = useApi()
  const list = useAttendanceList()
  const events = useEvents()

  const { t } = useTranslation(['useStartAttendance', 'common'])

  const modalExcedeedAttendance = useModal({
    modalName: 'limite-de-atendimento-excedido',
    title: t('modalExcedeedAttendance.title'),
    body: t('modalExcedeedAttendance.body'),
    showCloseButton: true,
    closeButtonText: t('common:continue'),
    closeButtonHierarchy: 'secondary',
  })

  const startAttendance = async (resellerId: string, resellerName: string): Promise<string> => {
    setCurrentAttendance(null)
    const result = await createAttendance<{ id: string }>({ resellerId })
    let attendanceId = ''

    if (result.ok) {
      setCurrentAttendance({ resellerId, resellerName, attendanceId: result.value.id })
      attendanceId = result.value.id
    } else {
      handleStartAttendanceError(result.error.messageType, resellerId)
    }

    list.getAttendanceList()
    return attendanceId
  }

  const handleStartAttendanceError = (errorType: ErrorTypes, resellerId: string): void => {
    switch (errorType) {
      case ErrorTypes.EXCEEDED_ATTENDANCE:
        events.searchResellerError(ErrorTypes.EXCEEDED_ATTENDANCE, '')
        modalExcedeedAttendance.open()
        break
      case ErrorTypes.IN_ATTENDANCE_BY_YOURSELF:
        const attendance = list.attendances.find((att) => att.geraId === resellerId)
        if (attendance) {
          setCurrentAttendance({
            attendanceId: attendance.id,
            resellerName: attendance.name,
            resellerId,
          })
        }
        break
      case ErrorTypes.IN_ATTENDANCE_BY_OTHER_SUPERVISOR:
      case ErrorTypes.NOT_IN_STRUCTURE:
      case ErrorTypes.WITHOUT_STRUCTURE:
        setCurrentAttendance({ resellerId, attendanceId: '', resellerName: '' })
        break
      default:
        Toaster.notify({
          description: t('common:somethingWentWrong'),
          origin: 'right-bottom',
          kind: 'error',
          showIcon: true,
        })
    }
  }

  return startAttendance
}

export { useStartAttendance }
