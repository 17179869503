import {
  ActionType,
  CategoryType,
  EventName,
  InteractionDetail,
  type EventBaseData,
  type EventTracker,
} from './events.types'

interface AttendanceEvents {
  searchReseller: (success: boolean, search: string) => void
  searchResellerError: (message: string, search: string) => void
  clickSearchResult: (reId: string, attendanceId: string) => void
  clickAttendanceFinishIcon: (reId: string, attendanceId: string) => void
  clickAttendanceFinishButton: (reId?: string, attendanceId?: string) => void
  clickAttendanceTabulate: (params: Record<string, string | undefined>) => void
}

const createAttendanceEvents = (tracker: EventTracker, data: EventBaseData): AttendanceEvents => ({
  searchReseller: function (success: boolean, search: string) {
    const state = success ? 'sucesso' : 'erro'

    tracker.track({
      event: 'event',
      action: ActionType.callbackSearch,
      category: CategoryType.base,
      label: `${search}:${state}`,
    })
    tracker.track({
      event: 'analytics-event',
      event_parameters: {
        event_name: `${EventName.callbackSearchAttendance}_${state}`,
        cd_codigo_atendimento: search,
      },
    })
  },
  searchResellerError: function (message, search) {
    tracker.track({
      event: 'event',
      action: ActionType.callbackSearch,
      category: CategoryType.base,
      label: `erro:${message}`,
    })
    tracker.track({
      event: 'analytics-event',
      event_parameters: {
        event_name: `${EventName.callbackSearchAttendance}_erro`,
        cd_codigo_atendimento: search,
        cd_error_message: message,
      },
    })
  },
  clickSearchResult: function (reId, attendanceId) {
    tracker.track({
      event: 'event',
      action: ActionType.clickButton,
      category: CategoryType.base,
      label: `busca:${reId}`,
    })
    tracker.track({
      event: 'analytics-event',
      event_parameters: {
        event_name: EventName.interactionAttendance,
        cd_interaction_detail: `${InteractionDetail.click}:atendimento`,
        cd_codigo_atendimento: attendanceId,
        cd_codigo_representante: reId,
      },
    })
  },
  clickAttendanceFinishIcon: function () {
    tracker.track({
      event: 'event',
      action: ActionType.clickIcon,
      category: CategoryType.base,
      label: 'fechar-atendimento',
    })
    tracker.track({
      event: 'analytics-event',
      event_parameters: {
        event_name: EventName.interactionAttendance,
        cd_interaction_detail: `${InteractionDetail.click}:fechar-atendimento`,
        cd_codigo_atendimento: data.attendanceId,
        cd_codigo_representante: data.reId,
      },
    })
  },
  clickAttendanceFinishButton: function () {
    tracker.track({
      event: 'analytics-event',
      event_parameters: {
        event_name: EventName.interactionAttendance,
        cd_interaction_detail: `${InteractionDetail.click}:fechar-atendimento`,
        cd_codigo_atendimento: data.attendanceId,
        cd_codigo_representante: data.reId,
      },
    })
  },
  clickAttendanceTabulate: function (params: Record<string, string | undefined>) {
    tracker.track({
      event: 'event',
      action: ActionType.clickButton,
      category: CategoryType.tabulation,
      label: [
        params.reId,
        params.contactType,
        params.reason,
        params.subreason,
        params.contactEffectiveness,
      ].join('-'),
    })
  },
})

export { createAttendanceEvents, type AttendanceEvents }
