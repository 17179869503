const unnecessaryCapitalizeItems = ['de', 'da', 'dos', 'das', 'e']

const getCapitalizedText = (text?: string, capitalize?: boolean): string => {
  if (capitalize && text) {
    const lowerCaseText = text.toLowerCase()
    const arrayText = lowerCaseText.split(' ')

    for (let i = 0; i < arrayText.length; i++) {
      if (!unnecessaryCapitalizeItems.includes(arrayText[i])) {
        arrayText[i] = arrayText[i].charAt(0).toUpperCase() + arrayText[i].slice(1)
      }
    }

    return arrayText.join(' ')
  }

  // Nullish verifica apenas null e undefined
  // strings vazia passam, mas queremos o '---'
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  return text || '---'
}

export { getCapitalizedText }
