import { styled } from '@grupoboticario/flora-react'

const Collapse = styled('div', {
  overflow: 'hidden',
  transition: 'height 300ms',
  boxSizing: 'content-box',
  width: '100%',
  height: '100%',
})

const ButtonContainer = styled('div', {
  margin: '$6 auto $2 auto',
  width: 'fit-content',
})

export { Collapse, ButtonContainer }
