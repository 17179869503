import { styled } from '@grupoboticario/flora-react'

const Container = styled('button', {
  position: 'relative',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'transparent',
  borderRadius: '$small',
  borderWidth: 0,
  padding: 0,
  cursor: 'pointer',
  outlineWidth: '$thin',
  outlineStyle: 'dashed',
  outlineColor: 'transparent',
  transitionProperty: 'opacity, outline-color',
  transitionTimingFunction: 'ease-out',
  transitionDuration: '0.2s',

  '&:hover': {
    opacity: 0.8,
  },

  '&:focus-visible': {
    outlineColor: '$assorted9',
  },

  '> div': {
    width: '$6',
    height: '$6',
  },
})

export { Container }
