import { ResellerMLDLink } from '@reseller-data/providers'
import { useApiData } from '@shared/api'
import { DataBlock } from '@shared/components'
import { TabName, useEvents } from '@shared/events'
import { useTranslation } from '@shared/i18n'
import { useCallback } from 'react'

interface MLDLinkProps {
  resellerId: string
}

interface MLDDataBlockProps {
  text: string
  color: string
}

const MLDLink = ({ resellerId }: MLDLinkProps): JSX.Element => {
  const { t } = useTranslation(['mldLink'])
  const getApiParams = useCallback(() => ({ resellerId }), [resellerId])

  const { data, loading, filled } = useApiData<ResellerMLDLink>({
    dataSource: 'getMLDLink',
    pollingOnError: true,
    getApiParams,
  })

  const mldLink = data?.mldLink

  const events = useEvents()

  const mldLinkToCopy = `${mldLink}?utm_medium=VDI_tela_atendimento&utm_source=VDI`

  const defaultColor = '$nonInteractivePredominant'

  const renderMLDInfo = (): MLDDataBlockProps => {
    if (loading) {
      return { text: t('loading'), color: defaultColor }
    }

    if (!loading && filled && mldLink) {
      return { text: mldLink, color: defaultColor }
    }

    if (!loading && filled && !mldLink) {
      return { text: t('withoutRegistration'), color: '$statusAlertNonInteractiveEmphasis' }
    }

    return { text: '', color: defaultColor }
  }

  return (
    <DataBlock
      sectionName="gerais"
      tabName={TabName.general}
      title={t('title')}
      description={{
        text: renderMLDInfo().text,
        color: renderMLDInfo().color,
      }}
      textToCopy={mldLinkToCopy}
      canCopy={Boolean(mldLink && !loading)}
      onCopy={() => events.clickCopyLink()}
    />
  )
}

export { MLDLink }
