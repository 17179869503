import { Flex, styled } from '@grupoboticario/flora-react'

const Container = styled(Flex, {
  height: '100%',
  flexWrap: 'wrap',
  justifyContent: 'space-evenly',

  '@mobile': {
    justifyContent: 'initial',
  },
})

export { Container }
