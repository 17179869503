import { useState } from 'react'

import { FloraInput } from '@grupoboticario/flora-react'

import { useResellersSearch } from '@reseller-search/hooks'
import { ResultsList } from '@reseller-search/modules/results-list'
import { SubmitButton } from '@reseller-search/modules/submit-button'
import { useTranslation } from '@shared/i18n'

import { Box, SearchForm } from './reseller-search.styles'

const SEARCH_INPUT_CHARACTER_LIMIT = 80

const ResellerSearch = (): JSX.Element => {
  const [searchInput, setSearchInput] = useState<string>('')
  const { t } = useTranslation('resellerSearch')

  const {
    fetch,
    isFetching,
    resellers,
    fetchMore,
    isFetchingMore,
    hasMoreData,
    error,
    reset,
    submitted,
    showNotFound,
    disabled,
  } = useResellersSearch({})

  const handleSearchResellers = (event: Event): void => {
    event.preventDefault()

    fetch(searchInput.trim())
  }

  const handleHideResultsList = (): void => {
    reset()
  }

  const handleResultItemClicked = (): void => {
    reset()
    setSearchInput('')
  }

  const handleSearchInputChanged = (searchText: string): void => {
    setSearchInput(searchText)
  }

  const handleFetchNextPage = (): void => {
    fetchMore()
  }

  const renderResultList = submitted && (!error || showNotFound) && (
    <ResultsList
      resellers={resellers}
      isLoading={isFetching}
      noResultsFound={showNotFound}
      onHide={handleHideResultsList}
      onFetchMore={handleFetchNextPage}
      isFetchingMore={isFetchingMore}
      hasMoreData={hasMoreData}
      onResultItemClicked={handleResultItemClicked}
    />
  )

  return (
    <Box>
      <SearchForm as="form" onSubmit={handleSearchResellers}>
        <FloraInput
          id="searchResellerField"
          role="textbox"
          label={t('searchLabel')}
          placeholder={t('searchPlaceholder')}
          aria-labelledby={t('searchLabelledby')}
          rightIcon={<SubmitButton isLoading={isFetching} ariaLabel={t('submitButtonAriaLabel')} />}
          value={searchInput}
          onChange={(event) => {
            handleSearchInputChanged(event.target.value)
          }}
          status={error.length > 0 ? 'error' : 'neutral'}
          helperText={error}
          autoComplete="off"
          maxLength={SEARCH_INPUT_CHARACTER_LIMIT}
          disabled={disabled}
          aria-disabled={disabled}
        />
        {renderResultList}
      </SearchForm>
    </Box>
  )
}

export { ResellerSearch }
