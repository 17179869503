import { FloraButton, styled } from '@grupoboticario/flora-react'

const PaymentContainer = styled('div', {
  position: 'relative',
  width: '355px',
  borderRadius: '$medium',
  background: '$backgroundPrimary',
  padding: '22px 19px $4',
  gap: '$4',
})

const PaymentInfoContainer = styled('div', {
  marginBottom: '$4',

  '*': {
    margin: 0,
  },

  '& > p': {
    marginLeft: '$6',
  },
})

const PaymentInfoLabel = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$2',
  marginBottom: '$2',
})

const PaymentTagsContainer = styled('div', {
  position: 'absolute',
  top: '22px',
  right: '25px',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'right',
  gap: '$2',
})

const DownloadButton = styled(FloraButton, {
  display: 'flex',
  width: '272px !important',
  marginLeft: 'auto',
  marginRight: 'auto',

  '&[aria-disabled="true"]': {
    boxShadow: 'none',
  },
})

const Identifier = styled('p', {
  fontSize: '$bodySmall',
  fontWeight: '$medium',
  lineHeight: '$normal',
  marginBottom: '$3',
})

export {
  DownloadButton,
  Identifier,
  PaymentContainer,
  PaymentInfoContainer,
  PaymentInfoLabel,
  PaymentTagsContainer,
}
