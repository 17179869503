import React, { ReactNode } from 'react'

import {
  Flex,
  FloraTypographyProps,
  Tag,
  TagProps,
  FloraTypography as Typography,
} from '@grupoboticario/flora-react'
import { CardHeaderLeft } from './card-header.styles'

interface CardHeaderProps {
  title: ReactNode
  fontSize?: FloraTypographyProps['fontSize']
  fontWeight?: FloraTypographyProps['fontWeight']
  tags?: {
    variant: TagProps['variant']
    label: string
  }[]
  actions?: ReactNode
}

const CardHeader: React.FC<CardHeaderProps> = ({ title, fontSize, fontWeight, tags, actions }) => (
  <Flex gap="$2" align="center">
    <CardHeaderLeft>
      <Typography fontSize={fontSize ?? 'bodySmallShort'} fontWeight={fontWeight ?? 'medium'}>
        {title}
      </Typography>

      {tags?.map((tag, i) => (
        <Tag key={i} variant={tag.variant} shape="ghost" size="small">
          {tag.label}
        </Tag>
      ))}
    </CardHeaderLeft>

    {actions && <Flex gap="$1">{actions}</Flex>}
  </Flex>
)

export { CardHeader }
