import { ResellerDataTabsContext, ResellerDataTabsContextType } from '@reseller-data/providers'
import { useContext } from 'react'

const useResellerDataTabControl = (): ResellerDataTabsContextType => {
  const context = useContext(ResellerDataTabsContext)
  if (!context) {
    throw new Error('Reseller data tab context not found')
  }

  const { activeTab, setActiveTab } = context

  return {
    activeTab,
    setActiveTab,
  }
}

export { useResellerDataTabControl }
