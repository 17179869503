import { Flex } from '@grupoboticario/flora-react'

import { DataBlock, DataSection } from '@shared/components'
import { useTranslation } from '@shared/i18n'

import { useResellerDataView } from '../../../../hooks'
import { ResellerApp } from './reseller-app/reseller-app'

const ResellerDigitalTools = (): JSX.Element => {
  const { t } = useTranslation('resellerDigitalTools')
  const { error, filled, loading, model, refetch, notFound } = useResellerDataView()

  const renderDataBlockTitle = filled && !error ? t('sectionTitle') : ''

  const renderDataBlockTooltip =
    filled && !error
      ? {
          text: t('tooltip'),
        }
      : undefined

  return (
    <DataSection
      data-testid="profile-data-tools"
      title={t('mainTitle')}
      fallbackHeight={120}
      loading={loading}
      filled={filled}
      error={error}
      data={model}
      onRetry={refetch}
      notFound={notFound}
      render={(externalInfo) => (
        <DataBlock
          title={renderDataBlockTitle}
          tooltip={renderDataBlockTooltip}
          content={
            <Flex direction="column" gap="$4" style={{ width: '100%' }}>
              <ResellerApp
                description={t('resellerApp')}
                appLastAccess={externalInfo.appLastAccess}
                enabled={externalInfo.hasApp}
              />

              <ResellerApp
                description={t('myDigitalStore')}
                enabled={externalInfo.isMyDigitalStore}
              />
            </Flex>
          }
        />
      )}
    />
  )
}

export { ResellerDigitalTools }
