import { useFeatureFlag } from 'configcat-react'
import React, { Fragment, Suspense } from 'react'

import { LoadingState } from '@shared/components'

import { OrderHistory } from './order-history'
import { Divider } from './order.styles'

const OrderMfe = React.lazy(() => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - O Stargate precisa dessa função
  window.getRandomString = () => Math.random().toString(36).substring(7)
  window.history.replaceState(null, '', '/ativacao/atendimento/departamentos')
  return import('./order-mfe').catch(() => import('./order-mfe/order-mfe-error'))
})

interface OrdersProps {
  resellerId: string
}

const Orders: React.FC<OrdersProps> = ({ resellerId }) => {
  const { value: showOrderMfe } = useFeatureFlag('showOrderMfe', false)

  return (
    <Fragment>
      {showOrderMfe && (
        <Suspense fallback={<LoadingState />}>
          <OrderMfe />
        </Suspense>
      )}
      {showOrderMfe && <Divider />}
      <OrderHistory resellerId={resellerId} />
    </Fragment>
  )
}

export { Orders }
