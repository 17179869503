import { createContext, useContext, useEffect, useState } from 'react'

import { ModalContainer } from '@shared/components'

const ModalContext = createContext<ModalContextData | null>(null)

interface ModalProviderProps {
  children: JSX.Element
}

interface ModalData {
  onClose?: () => void
  title?: string
  body: JSX.Element | string
  closeButtonHierarchy?: 'primary' | 'secondary' | 'tertiary'
  closeButtonText?: string
  showCloseButton?: boolean
  size?: 'small' | 'standard' | 'large'
  callToAction?: JSX.Element
  modalName?: string
}

interface ModalContextData {
  data: ModalData | null
  isOpen: boolean
  open: (modal: ModalData) => void
  close: () => void
}

const ModalProvider = ({ children }: ModalProviderProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [modalData, setModalData] = useState<ModalData | null>(null)

  useEffect(() => {
    if (modalData) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [modalData])

  const open = (modal: ModalData): void => {
    setModalData(modal)
  }

  const close = (): void => {
    setModalData(null)
  }

  const providerValue = { open, isOpen, setIsOpen, data: modalData, close }

  return (
    <ModalContext.Provider value={providerValue}>
      {children}
      <ModalContainer />
    </ModalContext.Provider>
  )
}

const useModalContext = (): ModalContextData => {
  const context = useContext(ModalContext)
  if (!context) throw new Error('Model context not found')
  return context
}

export { ModalContext, ModalProvider, useModalContext, type ModalData }
