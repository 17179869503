import { Fragment } from 'react'

import { Flex, Grid } from '@grupoboticario/flora-react'
import { toCurrency } from '@grupoboticario/vdi-mfe-utils'

import { GridMobile, LabelColumn } from '@shared/components'
import { useIsMobile } from '@shared/hooks'
import { useTranslation } from '@shared/i18n'
import { getCapitalizedText } from '@shared/utils'

import { OrderProduct } from '../../../order-detail.types'

interface ProductItemProps {
  product: OrderProduct
}

const ProductItem = ({ product }: ProductItemProps): JSX.Element => {
  const { t } = useTranslation('orderDetailListItem')
  const isMobile = useIsMobile()

  const productTotalValue = product.totalValue > 0 ? toCurrency(product.totalValue) : 'Grátis'

  return !isMobile ? (
    <tr data-testid="order-detail-list-item">
      <td>
        {getCapitalizedText(product.name, true)}
        <LabelColumn display="block">{product.code}</LabelColumn>
      </td>

      <td>
        <LabelColumn paddingRight display="inline-block">
          {t('qtd')}
        </LabelColumn>
        {product.quantity}
      </td>

      <td>
        <Fragment>
          <LabelColumn paddingRight display="inline-block">
            {t('value')}
          </LabelColumn>
          {productTotalValue}
        </Fragment>
      </td>
    </tr>
  ) : (
    <tr data-testid="order-detail-list-item">
      <GridMobile
        templateColumns="4fr 4fr"
        mobileTemplateColumns="4fr"
        gap="$1"
        css={{ padding: '$4 $4' }}
      >
        <Flex direction="column">
          {product.name}
          <LabelColumn display="block">{product.code}</LabelColumn>
        </Flex>
        <Grid templateColumns="4fr 4fr" gap="$1">
          <Flex gap="$1">
            <LabelColumn paddingRight display="inline-block">
              {t('qtd')}
            </LabelColumn>
            {product.quantity}
          </Flex>
          <Flex gap="$1">
            <LabelColumn paddingRight display="inline-block">
              {t('value')}
            </LabelColumn>
            {productTotalValue}
          </Flex>
        </Grid>
      </GridMobile>
    </tr>
  )
}

export { ProductItem }
