import { useFeatureFlag } from 'configcat-react'
import { Fragment } from 'react'

import { Grid } from '@grupoboticario/flora-react'
import { toCPF, toLocaleDate } from '@grupoboticario/vdi-mfe-utils'

import { useResellerDataView } from '@reseller-data/hooks'
import { type ResellerGeneral } from '@reseller-data/providers'

import { DataBlock, DataSection, GridMobile, type DataBlockProps } from '@shared/components'
import { TabName } from '@shared/events'
import { useIsMobile } from '@shared/hooks'
import { useTranslation } from '@shared/i18n'
import { getUserInitials } from '@shared/utils'

import { Fidelity } from './fidelity'
import { Mission } from './mission'
import { MLDLink } from './mld'

interface GeneralInfoProps {
  general?: ResellerGeneral
}

const GeneralInfo = ({ general }: GeneralInfoProps): JSX.Element => {
  const { t } = useTranslation(['generalInfo', 'common'])

  const { value: showMission } = useFeatureFlag('mission', false)

  const { model } = useResellerDataView()
  const isMobile = useIsMobile()

  const getOverdueDescription = (overdue: boolean): DataBlockProps['description'] =>
    overdue
      ? { text: t('dataBlock.overdue.no'), status: 'error', capitalize: true }
      : { text: t('dataBlock.overdue.yes'), status: 'success', capitalize: true }

  const isMultiBrandActive = model?.isMultiBrandActive

  return (
    <DataSection
      filled
      data={general}
      render={(data) => (
        <Fragment>
          {!isMobile && (
            <DataBlock
              avatar={getUserInitials(data.name)}
              title={t('dataBlock.registerName')}
              tooltip={{ text: t('dataBlock.registerNameTooltip') }}
              description={{ text: data.name, capitalize: true }}
            />
          )}

          <Mission
            show={showMission && Boolean(model?.mission)}
            description={model?.mission ?? ''}
          />

          <Grid templateColumns="4fr 4fr" gap="$1">
            <DataBlock
              canCopy
              sectionName="gerais"
              tabName={TabName.general}
              title={t('dataBlock.code')}
              description={{ text: data.geraId }}
            />

            <DataBlock
              title={t('dataBlock.overdue.title')}
              description={getOverdueDescription(data.overdue)}
            />
          </Grid>

          <GridMobile templateColumns="4fr 4fr" mobileTemplateColumns="4fr" gap="$1">
            <Fidelity club={data.club} />
            <DataBlock
              title={t('dataBlock.activityCycle')}
              description={{ text: data.activeCycle }}
            />
          </GridMobile>

          <DataBlock
            title={t('dataBlock.CycleActivityOpening')}
            description={{ text: model?.qtCyclesInactive }}
          />

          <GridMobile templateColumns="4fr 4fr" mobileTemplateColumns="4fr" gap="$1">
            <DataBlock
              title={t('dataBlock.isMultiBrandActive')}
              description={{
                text: isMultiBrandActive ? t('common:yes') : t('common:no'),
                status: isMultiBrandActive ? 'success' : 'error',
              }}
            />
            <DataBlock
              title={t('dataBlock.birthDate')}
              description={{ text: toLocaleDate(data.birthday) }}
            />
          </GridMobile>

          <Grid templateColumns="4fr 4fr" gap="$1">
            <DataBlock title={t('dataBlock.documentCPF')} description={{ text: toCPF(data.cpf) }} />
            <DataBlock title={t('dataBlock.documentRG')} description={{ text: data.rg }} />
          </Grid>

          <MLDLink resellerId={data.geraId} />
        </Fragment>
      )}
    />
  )
}

export { GeneralInfo }
