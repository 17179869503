import { styled } from '@grupoboticario/flora-react'

import { SPACE_BETWEEN_SECTION } from '../../reseller-data.styles'

const InformationContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$2',
  padding: '0 0 $4 0',
})

const GeneralDataContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  gap: SPACE_BETWEEN_SECTION,
  flex: 1,

  variants: {
    loading: {
      true: {
        justifyContent: 'center',
      },
    },
  },
})

export { GeneralDataContainer, InformationContainer }
