import {
  Flex,
  FloraButton,
  styled,
  FloraTypography as Typography,
} from '@grupoboticario/flora-react'

const accessibleStyle = {
  outlineWidth: '2px',
  outlineStyle: 'solid',
  outlineColor: 'transparent',

  '&:focus-visible': {
    outlineColor: '$actionableDefault',
  },
}

const CloseButton = styled(FloraButton, {
  display: 'inline-block',
  width: '$4 !important',
  height: '$4 !important',
  borderWidth: 0,
  padding: '0 !important',
  transitionProperty: 'color, background-color, outline-color',
  transitionDuration: '0.2s',
  transitionTimingFunction: 'ease-out',
  ...accessibleStyle,

  '&:hover': {
    color: '$actionableDefault !important',
  },
})

const AttendanceListItemContainer = styled(Flex, {
  position: 'relative',
  width: '100%',
  padding: '$2 $2 $2 $3',
  cursor: 'pointer',
  backgroundColor: 'transparent',
  borderRadius: '$small',
  borderWidth: '1px',
  borderColor: '$nonInteractiveOutline',
  borderStyle: 'solid',
  transitionProperty: 'background-color, opacity, outline-color',
  transitionDuration: '0.2s',
  transitionTimingFunction: 'ease-out',
  ...accessibleStyle,

  '&:hover': {
    opacity: 0.8,
  },

  '&::before': {
    content: '',
    position: 'absolute',
    top: '-1px',
    left: '-1px',
    width: '4px',
    height: 'calc(100% + 2px)',
    borderTopLeftRadius: '50px',
    borderBottomLeftRadius: '50px',
    pointerEvents: 'none',
    transitionProperty: 'background-color',
    transitionDuration: '0.2s',
    transitionTimingFunction: 'ease-out',
  },

  variants: {
    active: {
      true: {
        backgroundColor: '$backgroundPrimary',
        borderColor: '$backgroundPrimary',

        '&::before': {
          backgroundColor: '$actionableDefault',
        },
      },

      false: {
        '&::before': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
})

const userInitialsSize = '35px'

const UserInitials = styled(Typography, {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: userInitialsSize,
  height: userInitialsSize,
  backgroundColor: '$nonInteractiveAltAuxiliar',
  borderRadius: '50%',
  color: '$backgroundPrimary',
  fontSize: '$bodySmall !important',
  fontWeight: '$medium !important',
  lineHeight: userInitialsSize,
})

export { AttendanceListItemContainer, CloseButton, UserInitials }
