import { styled } from '@grupoboticario/flora-react'

const CardContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$2',
  padding: '$4',
  backgroundColor: '$backgroundPrimary',
  borderRadius: '$medium',
})

const CardDivider = styled('hr', {
  backgroundColor: '$nonInteractiveOutline',
  height: 2,
  margin: 0,
  border: 0,
})

export { CardContainer, CardDivider }
