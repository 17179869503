import { Flex, styled, FloraTypography as Typography } from '@grupoboticario/flora-react'

const Container = styled(Flex, {
  position: 'relative',
  width: '100%',

  '> div': {
    marginRight: '$2',
  },

  '&:first-of-type': {
    marginTop: '$2',
  },

  variants: {
    justify: {
      spaceBetween: {
        justifyContent: 'space-between',
      },
      initial: {
        justifyContent: 'initial',
      },
    },
  },
})

const ContainerDescription = styled(Typography, {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  color: '$nonInteractivePredominant',
  fontSize: '$bodyLarge !important',
  fontWeight: '$medium !important',
  lineHeight: '$normal !important',
})

const LastAccess = styled(Typography, {
  color: '$nonInteractiveAuxiliar',
  fontSize: '0.6875rem !important',
  fontWeight: '$medium !important',
  lineHeight: '$short !important',
  maxWidth: '150px',
  textAlign: 'right',

  '@mobile': {
    lineHeight: '$2xl',
    maxWidth: 'initial',
  },
})

export { Container, ContainerDescription, LastAccess }
