import { Flex, styled } from '@grupoboticario/flora-react'

const ContainerText = styled(Flex, {
  width: '100%',
  paddingLeft: '$2',
  alignItems: 'normal',

  '@mobile': {
    alignItems: 'center',
  },

  '> p': {
    width: '100%',
    margin: 0,
  },
})

export { ContainerText }
