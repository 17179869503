import { useCallback, useEffect, useRef, useState } from 'react'

import { TagProps } from '@grupoboticario/flora-react'
import { QueryParams } from '@grupoboticario/vdi-mfe-utils'

import { AsyncState, MetaState, useApi, useAsyncState } from '@shared/api'

import { useDebounce } from '@shared/hooks'
import { Order, OrdersDTO, OrderStatusType } from './orders-history.types'

interface UseResellerOrders extends AsyncState {
  list: Order[]
  hasMore: boolean
  empty: boolean
  getOrders: () => void
  filter: (orderFilter: OrderFilters) => void
  filters: OrderFilters
}

type OrderFilters = QueryParams

const useResellersOrders = (resellerId: string): UseResellerOrders => {
  const [orders, setOrders] = useState(initialOrdersState)
  const [filters, setFilters] = useState<OrderFilters>({})
  const asyncState = useAsyncState(orders.status)
  const pagination = useRef({ page: 1, limit: 12 })
  const api = useApi()

  const getOrdersApi = useCallback(
    async (params: QueryParams) => {
      setOrders((s) => ({ ...s, status: MetaState.LOADING }))
      const result = await api.getResellerOrderHistory<OrdersDTO>({ ...params, resellerId })

      if (result.ok) {
        setOrders((s) => {
          const list = s.list.concat(result.value.orders)
          const hasMore = list.length > 0 && list.length < result.value.pagination.totalItems
          return { list, hasMore, status: MetaState.FILLED }
        })
      } else {
        setOrders((s) => ({ ...s, hasMore: true, status: MetaState.ERROR }))
      }
    },
    [api, resellerId],
  )

  const getOrdersApiDebounced = useDebounce(getOrdersApi, 700)

  const getOrders = () => {
    if (asyncState.filled) pagination.current.page++
    getOrdersApi({ ...pagination.current, ...filters })
  }

  const filter = (orderFilters: OrderFilters) => {
    pagination.current.page = 1
    const updated = { ...filters, ...orderFilters }
    setFilters(updated)
    setOrders(initialOrdersState)
    getOrdersApiDebounced({ ...pagination.current, ...updated })
  }

  useEffect(() => {
    getOrdersApi(pagination.current)
  }, [getOrdersApi])

  return {
    ...asyncState,
    empty: asyncState.filled && orders.list.length <= 0,
    list: orders.list,
    hasMore: orders.hasMore,
    filters: filters,
    getOrders,
    filter,
  }
}

interface OrdersState {
  list: Order[]
  status: MetaState
  hasMore: boolean
}

const initialOrdersState: OrdersState = {
  list: [],
  hasMore: false,
  status: MetaState.IDLE,
}

interface UseOrderStatusOutput {
  variant: TagProps['variant']
  label: string
}

type UseOrderStatusInput = {
  status: OrderStatusType
  statusDescription: string
}

const useOrderStatusTag = (orderStatus: UseOrderStatusInput): UseOrderStatusOutput => ({
  variant: status[orderStatus.status] ?? 'assorted9',
  label: orderStatus.statusDescription,
})

const status: Record<OrderStatusType, TagProps['variant']> = {
  [OrderStatusType.CREATED]: 'assorted9',
  [OrderStatusType.APPROVED]: 'assorted9',
  [OrderStatusType.DELIVERED]: 'success',
  [OrderStatusType.CANCELLED]: 'error',
  [OrderStatusType.TRANSPORT]: 'assorted9',
  [OrderStatusType.SEPARATION]: 'assorted9',
}

export { useOrderStatusTag, useResellersOrders }
