import { FloraTypography } from '@grupoboticario/flora-react'
import { useTranslation } from '@shared/i18n'
import { LastMovement, OperationType } from '../../mcm-fidelity.types'
import { PointPanel } from '../point-panel'
import { OperationTypography } from './redeemable-points.styles'

interface RedeemablePointsProps {
  amount: number
  lastMovement?: LastMovement
}

const RedeemablePoints: React.FC<RedeemablePointsProps> = ({ amount, lastMovement }) => {
  const { t } = useTranslation('resellerFidelity')

  if (lastMovement) {
    return (
      <PointPanel
        value={amount}
        title={t('redeemablePointsTitle')}
        description={
          <LastMovementDescription
            value={lastMovement.amount}
            operation={lastMovement.operationType}
          />
        }
      />
    )
  }

  return (
    <PointPanel
      value={amount}
      title={t('redeemablePointsTitle')}
      description={t('noLastMovement')}
    />
  )
}

const LastMovementDescription: React.FC<{ value: number; operation: OperationType }> = ({
  value,
  operation,
}) => {
  const { t } = useTranslation('resellerFidelity')

  return (
    <FloraTypography
      fontSize="exceptionsRestricted"
      fontWeight="medium"
      css={{
        color: '$nonInteractiveAuxiliar',
      }}
    >
      {t('lastMovement')}
      <OperationTypography
        fontSize="exceptionsRestricted"
        fontWeight="medium"
        operationType={operation}
      >
        {t('lastMovementPoints', {
          amount: `${operation == OperationType.Credit ? '+' : ''}${value}`,
        })}
      </OperationTypography>
    </FloraTypography>
  )
}
export { RedeemablePoints }
