import { CheckCircleIcon, CrossCircleIcon } from '@grupoboticario/flora-react-icons'

import { useTranslation } from '@shared/i18n'
import { Container } from './reseller-app-status.styles'

const ResellerAppStatus = ({ enabled }: { enabled?: boolean }): JSX.Element => {
  const { t } = useTranslation('resellerAppStatus')

  const crossCircleIcon = (
    <CrossCircleIcon
      data-testid="not-have-app-icon"
      size="20px"
      color="$statusErrorNonInteractiveEmphasis"
      aria-label={t('doesNotHave')}
    />
  )

  const checkCicleIcon = (
    <CheckCircleIcon
      data-testid="have-app-icon"
      size="20px"
      color="$statusSuccessNonInteractiveEmphasis"
      aria-label={t('itHas')}
    />
  )

  return <Container>{enabled ? checkCicleIcon : crossCircleIcon}</Container>
}

export { ResellerAppStatus }
