import { Flex, styled } from '@grupoboticario/flora-react'

const Alert = styled(Flex, {
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '$3',
  width: '100%',
  minHeight: '$14',
  backgroundColor: '$statusInfoBackgroundDefault',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '$statusInfoNonInteractiveEmphasis',
  borderRadius: '$medium',
  padding: '$3',
  marginTop: '$3',

  '> p': {
    color: '$statusInfoNonInteractiveEmphasis',
    fontSize: '$bodyLarge',
    fontWeight: '$medium',
    lineHeight: '$base',
  },
})

export { Alert }
