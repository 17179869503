import { styled } from '@grupoboticario/flora-react'

const CardListContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '$4',
  flex: 1,
})

const ListContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '$4 $2',
  width: '100%',

  '@tablet': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },

  '@desktop': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
})

const StateContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
})

export { CardListContainer, ListContainer, StateContainer }
