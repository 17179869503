import { Flex } from '@grupoboticario/flora-react'
import { LoadingState } from '@shared/components'
import { useTranslation } from '@shared/i18n'
import React, { ReactElement } from 'react'
import { MovementTable as Table } from './movements-table.styles'

interface MovementsTableProps<T> {
  loading: boolean
  data: T[]
  renderRow: (item: T) => React.ReactNode
  renderHeader: () => React.ReactNode
  scrollable?: boolean
  pageSize?: number
}

const MovementsTable = <T,>(props: MovementsTableProps<T>): ReactElement => {
  const { t } = useTranslation(['generalData'])

  const { renderHeader, pageSize = 5, data, loading, renderRow, scrollable } = props

  const renderDataRows = () => {
    return data.map((item) => renderRow(item))
  }

  const renderLoading = () => {
    return (
      <td colSpan={4} style={{ height: 53 * pageSize }}>
        <Flex align="center" justify="center" css={{ height: '100%' }}>
          <LoadingState
            title={t('generalData:loadingState.title')}
            description={t('generalData:loadingState.subtitle')}
          />
        </Flex>
      </td>
    )
  }

  return (
    <Table scrollable={scrollable}>
      {renderHeader()}

      {loading && renderLoading()}

      <tbody>
        {!loading && data && renderDataRows()}
        {!loading && renderEmptySpace(data.length, pageSize)}
      </tbody>
    </Table>
  )
}

const renderEmptySpace = (dataAmount: number, pageSize: number) => {
  const emptyRows = pageSize - dataAmount

  return <tr key="empty-row-spacing" style={{ height: 53 * emptyRows }} />
}

export { MovementsTable }
