import { Flex } from '@grupoboticario/flora-react-flex'
import { FloraTypography } from '@grupoboticario/flora-react-typography'
import { DataBlock } from '@shared/components'

interface PointPanelProps {
  value: number
  title: string
  description: string | React.ReactNode
  prefix?: string
}

const PointPanel: React.FC<PointPanelProps> = (props) => {
  const { value, title, description, prefix } = props

  return (
    <DataBlock
      renderContentOnly
      content={
        <Flex as="li" direction="column" aria-label={title}>
          <FloraTypography
            fontSize="subtitle"
            fontWeight="medium"
            css={{ color: '$nonInteractivePredominant' }}
          >
            {prefix}
            {value.toLocaleString(undefined)}
          </FloraTypography>
          <FloraTypography
            fontSize="exceptionsRestricted"
            fontWeight="medium"
            css={{
              color: '$nonInteractiveAuxiliar',
            }}
          >
            {title.toUpperCase()}
          </FloraTypography>
          <FloraTypography
            fontSize="exceptionsRestricted"
            fontWeight="medium"
            css={{
              color: '$nonInteractiveAuxiliar',
            }}
          >
            {description}
          </FloraTypography>
        </Flex>
      }
    />
  )
}

export { PointPanel, PointPanelProps }
