/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import type { FederationRuntimePlugin } from '@module-federation/enhanced/runtime'

const sharedDepsFromSystemJs = [
  { package: 'react', scope: 'default', version: '18.2.0' },
  { package: 'react-dom', scope: 'default', version: '18.2.0' },
]

export default function WmfPlugin(): FederationRuntimePlugin {
  return {
    name: 'share-systemjs-plugin',
    init: (args) => {
      sharedDepsFromSystemJs.forEach((dep) => {
        const scope = dep.scope
        const version = dep.version
        const currentScope = args.origin.shareScopeMap[scope] ?? {}

        args.origin.shareScopeMap[scope] = {
          ...currentScope,
          [dep.package]: {
            [version]: {
              from: 'vdi',
              loaded: 1,
              get: loadModuleFromSystemJs(dep.package),
            },
          },
        }
      })
      return args
    },
  }
}

const loadModuleFromSystemJs = (packageName) => async () => {
  const module = await System.import(packageName)
  return () => module
}
