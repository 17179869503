enum ResellerAttendanceStatus {
  ATTENDANCE_RELEASED = 'ATTENDANCE_RELEASED',
  NOT_IN_STRUCTURE = 'NOT_IN_STRUCTURE',
  WITHOUT_STRUCTURE = 'WITHOUT_STRUCTURE',
  IN_ATTENDANCE_BY_OTHER_SUPERVISOR = 'IN_ATTENDANCE_BY_OTHER_SUPERVISOR',
  IN_ATTENDANCE_BY_YOURSELF = 'IN_ATTENDANCE_BY_YOURSELF',
}

interface Reseller {
  name: string
  geraId: string
  cpf: string
  email: string
  attendanceId: string
  actionStatus: ResellerAttendanceStatus
}

export { type Reseller, ResellerAttendanceStatus }
