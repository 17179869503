import { Fragment } from 'react'

import { type ResellerGeneral } from '@reseller-data/providers'

import { Allocation } from '../allocation-info'
import { ResumedInfo } from '../resumed-info'

const UnreleasedInfo: React.FC<{ general: ResellerGeneral }> = ({ general }) => (
  <Fragment>
    <ResumedInfo general={general} />
    <Allocation cs={general.cs} />
  </Fragment>
)

export { UnreleasedInfo }
