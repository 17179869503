import { Flex, FloraTypography as Typography } from '@grupoboticario/flora-react'
import { CircleDashIcon, TagOutlineIcon } from '@grupoboticario/flora-react-icons'

import { AccordionItem } from '@shared/components'
import { useEvents } from '@shared/events'
import { useTranslation } from '@shared/i18n'

import { ContainerText } from './promotional-data-item.styles'

interface PromotionalDataItemProps {
  name: string
  promotions: string[]
}

const PromotionalDataItem = ({ name, promotions }: PromotionalDataItemProps): JSX.Element => {
  const { t } = useTranslation('promotionalData')
  const events = useEvents()

  return (
    <AccordionItem
      title={t(`campaignsNames.${name}`)}
      onClick={() => events.clickDropdownPromotion(name)}
      content={
        <Flex direction="row" align="center" justify="space-between" wrap="wrap" gap="$6">
          {!promotions.length && (
            <ContainerText align="center" gap="$2" justify="initial">
              <CircleDashIcon color="$statusAlertNonInteractiveEmphasis" size="16px" />
              <Typography
                fontSize="bodyLargeShort"
                fontWeight="medium"
                css={{ color: '$statusAlertNonInteractiveEmphasis' }}
              >
                {t('doNotHavePromotions')}
              </Typography>
            </ContainerText>
          )}

          {promotions.map((promotion: string, index: number) => (
            <ContainerText key={index} gap="$2" justify="initial">
              <TagOutlineIcon size="16px" color="$9" />
              <Typography fontSize="bodyLargeShort" fontWeight="medium">
                {promotion}
              </Typography>
            </ContainerText>
          ))}
        </Flex>
      }
    />
  )
}

export { PromotionalDataItem }
