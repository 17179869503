import React, { Fragment, useCallback } from 'react'

import {
  FloraButton as Button,
  Flex,
  FloraTypography as Typography,
} from '@grupoboticario/flora-react'

import { ArrowDownIcon, ArrowUpIcon } from '@grupoboticario/flora-react-icons'

import { useApiData } from '@shared/api'
import { DataBlock, DataSection } from '@shared/components'
import { useTranslation } from '@shared/i18n'

import { CCRMovementsDTO } from './ccr-movements.types'
import { CCRMovementsPagination } from './pagination'
import { CCRMovementTable } from './table'

export const CCR_MOVEMENTS_PAGE_SIZE = 10

interface CCRMovementsProps {
  resellerId: string
}

const CCRMovements: React.FC<CCRMovementsProps> = ({ resellerId }) => {
  const [showCCRMovements, setShowCCRMovements] = React.useState<boolean>(false)
  const [currentPage, setCurrentPage] = React.useState<number>(1)

  const { t } = useTranslation('resellerCCRMovements')

  const getApiParams = useCallback(() => ({ resellerId }), [resellerId])

  const { data, error, filled, loading, unavailable, refetch } = useApiData<CCRMovementsDTO>({
    dataSource: 'getResellerCCRMovements',
    getApiParams,
    pollingOnError: true,
  })

  const handleToggleMovements = () => {
    setShowCCRMovements(!showCCRMovements)
  }

  const dataPage = data?.slice(
    CCR_MOVEMENTS_PAGE_SIZE * (currentPage - 1),
    CCR_MOVEMENTS_PAGE_SIZE * currentPage,
  )

  return (
    <Fragment>
      {showCCRMovements && (
        <DataSection
          data-testid="ccr-movements-section"
          title={t('ccrMovements')}
          fallbackHeight={228}
          onRetry={refetch}
          unavailable={unavailable}
          filled={filled}
          loading={loading}
          error={error}
          data={data}
          render={() => (
            <DataBlock
              content={
                <Flex direction="column" justify="stretch" gap="$4">
                  <Flex direction="column">
                    <Flex direction="column" justify="center">
                      <Typography
                        fontSize="bodyLargeStandard"
                        fontWeight="medium"
                        css={{
                          color: '$nonInteractivePredominant',
                        }}
                      >
                        {loading ? t('loadingData') : t('movementsFound', { amount: data?.length })}
                      </Typography>
                    </Flex>
                  </Flex>
                  <CCRMovementTable loading={loading} data={dataPage || []} />
                  <CCRMovementsPagination
                    loading={loading}
                    totalItems={data?.length ?? 0}
                    page={currentPage}
                    pageSize={CCR_MOVEMENTS_PAGE_SIZE}
                    setPage={setCurrentPage}
                    amountItems={dataPage?.length ?? 0}
                  />
                </Flex>
              }
            />
          )}
        />
      )}
      <Flex direction="column" align="center">
        <Button
          has="iconLeft"
          icon={!showCCRMovements ? <ArrowDownIcon /> : <ArrowUpIcon />}
          hierarchy="secondary"
          size="small"
          onClick={handleToggleMovements}
          isLoading={loading}
          disabled={error || loading || unavailable || data?.length === 0 || !data}
        >
          {!showCCRMovements ? t('showCCRMovements') : t('hideCCRMovements')}
        </Button>
      </Flex>
    </Fragment>
  )
}

export { CCRMovements }
