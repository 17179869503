import { useCallback, useEffect, useState } from 'react'

import { Toaster } from '@grupoboticario/flora-react'

import { ErrorTypes, useApi } from '@shared/api'
import { useEvents } from '@shared/events'
import { useTranslation } from '@shared/i18n'
import { useAppContext } from '@shared/providers'
import { ResellerAttendanceStatus } from '@shared/types'
import { timeout } from '@shared/utils/timeout'

import { isNumber } from '@shared/utils'

interface UseResellersSearchReturn {
  resellers: Reseller[]
  fetch: (searchString: string) => void
  isFetching: boolean
  fetchMore: () => void
  isFetchingMore: boolean
  hasMoreData: boolean
  error: string
  reset: () => void
  submitted: boolean
  showNotFound: boolean
  disabled: boolean
}

interface UseResellerSearchProps {
  itemsPerPage?: number
}

type SearchType = { geraId: string } | { cpf: string } | { email: string }

type SearchError = keyof typeof errorMessage | ''

interface Reseller {
  name: string
  geraId: string
  cpf: string
  email: string
  attendanceId: string
  actionStatus: ResellerAttendanceStatus
}

const errorMessage = {
  [ErrorTypes.SUPERVISOR_WITHOUT_STRUCTURE]: '',
  [ErrorTypes.UNEXPECTED_ERROR]: 'common:unexpectedError',
  [ErrorTypes.INVALID_GERAID]: 'error.invalidValue',
  [ErrorTypes.INVALID_VALUE]: 'error.invalidValue',
  [ErrorTypes.INVALID_EMAIL]: 'error.invalidValue',
  [ErrorTypes.INVALID_CPF]: 'error.invalidValue',
  [ErrorTypes.NOT_FOUND]: '',
  EMPTY_SEARCH_INPUT: 'error.emptySearchMessage',
}

const useResellersSearch = ({
  itemsPerPage = 10,
}: UseResellerSearchProps): UseResellersSearchReturn => {
  const { t } = useTranslation(['useResellersSearch', 'common'])
  const { getResellers, getSectors } = useApi()
  const events = useEvents()

  const { appState, setAppState } = useAppContext()

  const [resellers, setResellers] = useState<Reseller[]>([])
  const [currentResellers, setCurrentResellers] = useState<Reseller[]>([])

  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [isFetchingMore, setIsFetchingMore] = useState<boolean>(false)

  const [submitted, setSubmitted] = useState<boolean>(false)
  const [error, setError] = useState<SearchError>('')
  const [page, setPage] = useState<number>(1)

  const getFirstResellerPage = useCallback(
    (totalResellers: Reseller[]): Reseller[] => totalResellers.slice(0, itemsPerPage - 1),
    [itemsPerPage],
  )

  const fetch = async (search: string): Promise<void> => {
    if (!search) {
      setError('EMPTY_SEARCH_INPUT')
      return
    }

    setIsFetching(true)
    setSubmitted(true)
    setError('')

    const searchResult = getSearchType(search)
    const [ressellersResult, sectorsResult] = await Promise.all([
      getResellers<Reseller[]>(searchResult),
      getSectors<{ data: [] }>({ withName: false }),
    ])

    if (sectorsResult.ok && sectorsResult.value.data.length <= 0) {
      setAppState({ withoutStructure: true })
      events.searchResellerError(ErrorTypes.SUPERVISOR_WITHOUT_STRUCTURE, search)
      setIsFetching(false)
      const activeElement = document.activeElement as HTMLElement
      activeElement?.blur()
      return
    } else if (!sectorsResult.ok) {
      handleError(sectorsResult.error.messageType as SearchError, search, sectorsResult.code!)
    }

    if (ressellersResult.ok) {
      events.searchReseller(true, search)
      setResellers(ressellersResult.value)
      const resultResellers = ressellersResult.value
      resultResellers.forEach((reseller) => verifyActionStatus(reseller.actionStatus, search))
      setPage(1)
    } else {
      events.searchReseller(false, search)
      handleError(ressellersResult.error.messageType as SearchError, search)
    }

    setIsFetching(false)
  }

  const verifyActionStatus = (status: ResellerAttendanceStatus, search: string): boolean => {
    switch (status) {
      case ResellerAttendanceStatus.NOT_IN_STRUCTURE:
        events.searchResellerError(ResellerAttendanceStatus.NOT_IN_STRUCTURE, search)
        return true
      case ResellerAttendanceStatus.WITHOUT_STRUCTURE:
        events.searchResellerError(ResellerAttendanceStatus.WITHOUT_STRUCTURE, search)
        return true
      case ResellerAttendanceStatus.IN_ATTENDANCE_BY_YOURSELF:
        events.searchResellerError(ResellerAttendanceStatus.IN_ATTENDANCE_BY_YOURSELF, search)
        return true
      default:
        return false
    }
  }

  const handleError = (searchErr: SearchError, search: string, code?: number): void => {
    const isExpectedError = searchErr in errorMessage
    setError(isExpectedError ? searchErr : ErrorTypes.UNEXPECTED_ERROR)

    switch (searchErr) {
      case ErrorTypes.NOT_FOUND:
        events.searchResellerError(ErrorTypes.NOT_FOUND, search)
        break
      case ErrorTypes.INVALID_VALUE:
        events.searchResellerError(ErrorTypes.INVALID_VALUE, search)
        break
    }

    if (!isExpectedError) {
      if (code && code === 403) {
        Toaster.notify({
          description: t('common:permissionDenied'),
          origin: 'right-bottom',
          kind: 'error',
          showIcon: true,
        })
        return
      }
      Toaster.notify({
        description: t('common:somethingWentWrong'),
        origin: 'right-bottom',
        kind: 'error',
        showIcon: true,
      })

      events.searchResellerError(ErrorTypes.UNEXPECTED_ERROR, search)
    }
  }

  const reset = (): void => {
    setIsFetching(false)
    setIsFetchingMore(false)
    setCurrentResellers([])
    setResellers([])
    setError('')
    setSubmitted(false)
    setPage(1)
  }

  useEffect(() => {
    setCurrentResellers(getFirstResellerPage(resellers))
  }, [getFirstResellerPage, resellers])

  const pageCount = Math.ceil(resellers.length / itemsPerPage)

  const hasMoreData = page < pageCount

  const fetchMore = async (): Promise<void> => {
    setIsFetchingMore(true)
    await timeout(150)
    const nextPage = page + 1
    const nextPageContent = resellers.slice(page * itemsPerPage, nextPage * itemsPerPage)
    setIsFetchingMore(false)
    setCurrentResellers(currentResellers.concat(nextPageContent))
    setPage(nextPage)
  }

  return {
    resellers: currentResellers,
    fetch,
    isFetching,
    fetchMore,
    isFetchingMore,
    hasMoreData,
    showNotFound: error === ErrorTypes.NOT_FOUND,
    error: error ? t(errorMessage[error]) : error,
    reset,
    submitted,
    disabled: appState.withoutStructure,
  }
}

const getSearchType = (search: string): SearchType => {
  const searchWithoutSymbols = search.replace(/\.|-/g, '')

  const isCpf = isNumber(searchWithoutSymbols) && searchWithoutSymbols.length === 11
  const isGeraId = isNumber(searchWithoutSymbols) && searchWithoutSymbols.length !== 11

  if (isCpf) {
    return { cpf: searchWithoutSymbols }
  }

  if (isGeraId) {
    return { geraId: searchWithoutSymbols }
  }

  return { email: search }
}

export { useResellersSearch, type UseResellersSearchReturn as useResellersSearchReturn }
