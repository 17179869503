import { OperationType } from '../../../mcm-fidelity.types'
import { Container, OperationTypeBadge } from './movement-amount.styles'

interface MovementAmountProps {
  amount: number
  operationType: OperationType
}

const MovementAmount: React.FC<MovementAmountProps> = ({ amount, operationType }) => {
  const renderAmount = () => {
    if (operationType === OperationType.Credit) {
      return `+${amount}`
    }
    return amount
  }
  return (
    <Container>
      <OperationTypeBadge operationType={operationType} />
      {renderAmount()}
    </Container>
  )
}

export { MovementAmount }
