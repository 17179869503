import { OperationType } from '../ccr-movements.types'
import { Container, OperationTypeBadge } from './ccr-movement-amount.styles'

interface CCRMovementAmountProps {
  amount: number
  operationType: OperationType
}

const CCRMovementAmount: React.FC<CCRMovementAmountProps> = ({ amount, operationType }) => {
  const renderAmount = () => {
    const formattedAmount = amount.toFixed(2).replace('.', ',')
    if (operationType === OperationType.Credit) {
      return `+${formattedAmount}`
    } else {
      return `-${formattedAmount}`
    }
  }
  return (
    <Container>
      <OperationTypeBadge operationType={operationType} />
      {renderAmount()}
    </Container>
  )
}

export { CCRMovementAmount }
