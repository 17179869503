import { styled } from '@grupoboticario/flora-react'

const Divider = styled('div', {
  width: '100%',
  height: '1px !important',
  background: '$nonInteractiveOutline',
  margin: '$6 0',
})

export { Divider }
