import { env } from '@shared/env'
import { type GetLabelTab, type TabulationHistoryProps } from '@vdi/tabulation'

const getLabelTab: GetLabelTab = (tab) => ({
  reason: { name: 'Motivo', value: tab.reason },
  subreason: { name: 'Submotivo', value: tab.subreason },
  note: { name: 'Observação', value: tab.note },
  contactType: {
    name: 'Quem iniciou o contato',
    value: tabValues.contactType[tab.contactType],
  },
  contactChannel: {
    name: 'Canal do contato',
    value: tabValues.contactChannel[tab.contactChannel],
  },
  contactEffectiveness: {
    name: 'Revendedor respondeu o contato',
    value: tabValues.contactEffectiveness[tab.contactEffectiveness],
  },
  contactPurpose: {
    name: 'Finalidade de contato',
    value: tabValues.contactPurpose[tab.contactPurpose],
  },
})

const getTabulationHistoryConfig = (reId = '', attendanceId = ''): TabulationHistoryProps => ({
  getLabelTab,
  apiConfig: {
    baseUrl: env.API_BASE_URL,
    historyEndpoint: `/attendances/${reId}/history`,
  },
  reId,
  attendanceId,
})

const tabValues: Record<string, Record<string, string>> = {
  contactType: {
    ativo: 'Atendente entrou em contato',
    receptivo: 'A RE entrou em contato',
  },
  contactChannel: {
    whatsApp: 'WhatsApp',
    whatsapp: 'WhatsApp',
    telefone: 'Telefone',
  },
  contactEffectiveness: {
    com_sucesso: 'Sim, houve troca de mensagens',
    'Com Sucesso': 'Sim, houve troca de mensagens',
    sem_sucesso: 'Não, a RE não respondeu',
    'Sem Sucesso': 'Não, a RE não respondeu',
  },
  contactPurpose: {
    personalizado: 'Atendimento',
    atendimento: 'Atendimento',
    consulta: 'Consulta sem atendimento',
  },
}

export { getTabulationHistoryConfig }
