import { useCallback } from 'react'

const useParamResellerId = (): (() => string) => {
  const replacePath = useCallback(() => {
    window.history.replaceState({}, '', window.location.pathname)
  }, [])

  const getResellerIdParam = useCallback(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const resellerId = urlParams.get('resellerId')
    const resellerIdEncoded = encodeURIComponent(resellerId ?? '')
    replacePath()
    return resellerIdEncoded
  }, [replacePath])

  return getResellerIdParam
}

export { useParamResellerId }
