import { Box, FloraButton as Button, Flex } from '@grupoboticario/flora-react'
import { styled } from '@stitches/react'

const Content = styled(Flex, {
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const ClickableOverlay = styled(Box, {
  cursor: 'pointer',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
})

const FidelityTabButton = styled(Button, {
  width: '$3',
  height: '$3 !important',
  padding: '0 !important',
  margin: 0,
})

export { ClickableOverlay, Content, FidelityTabButton }
