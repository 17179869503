import { Box as BoxFlora, styled } from '@grupoboticario/flora-react'

const Box = styled(BoxFlora, {
  position: 'relative',
  width: '100%',
  padding: '$4 $2',
})

const SearchForm = styled(BoxFlora, {
  position: 'relative',

  '& [data-flora="input-wrapper"]': {
    minHeight: 'unset',
  },

  '& #searchResellerField-error-msg': {
    height: 'unset',
  },
})

export { Box, SearchForm }
