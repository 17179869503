import { Fragment, useEffect } from 'react'

import { Container } from './alert.styles'

interface AlertProps {
  description: string
  duration?: number
  icon?: JSX.Element
  type: 'success' | 'error'
  show: boolean
  onClose?: () => void
}

const Alert = ({
  description,
  duration = 2000,
  icon,
  type,
  show,
  onClose,
}: AlertProps): JSX.Element => {
  const hasIcon = icon ?? icon

  useEffect(() => {
    const timer = setTimeout(() => {
      if (show && onClose) {
        onClose()
      }
    }, duration)

    return () => {
      clearTimeout(timer)
    }
  }, [duration, onClose, show])

  if (!show) {
    return <Fragment />
  }

  return (
    <Container role="dialog" type={type}>
      {hasIcon}
      {description}
    </Container>
  )
}

export { Alert }
export type { AlertProps }
