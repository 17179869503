import { useEffect, useRef } from 'react'
import { useKeyPressEvent } from 'react-use'

import { Flex, Spacer, Tag, FloraTypography as Typography } from '@grupoboticario/flora-react'
import { CrossIcon } from '@grupoboticario/flora-react-icons'

import { useEvents } from '@shared/events'
import { useIsMobile, useTabulation } from '@shared/hooks'
import { useTranslation } from '@shared/i18n'
import { getCapitalizedText, getUserInitials } from '@shared/utils'

import { type AttendanceItem } from '../attendance-list.types'
import {
  AttendanceListItemContainer,
  CloseButton,
  UserInitials,
} from './attendance-list-item.styles'

interface AttendanceListItemProps {
  active?: boolean
  onClick: (attendance: AttendanceItem) => void
  attendance: AttendanceItem
  selected: boolean
}

const AttendanceListItem = ({
  active,
  attendance,
  onClick,
  selected,
}: AttendanceListItemProps): JSX.Element => {
  const { t } = useTranslation(['attendanceListItem'])
  const events = useEvents()
  const ref = useRef<HTMLInputElement | null>(null)
  const isMobile = useIsMobile()

  const { name, geraId, id } = attendance

  const tabulation = useTabulation({
    reName: name,
    reId: geraId,
    attendanceId: id,
  })

  const handleClick = (): void => {
    onClick(attendance)
  }

  const handleCloseAttendanceClicked = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation()
    events.clickAttendanceFinishIcon(geraId, id)
    tabulation.open()
  }

  useEffect(() => {
    if (selected) {
      ref.current?.focus()
      ref.current?.scrollIntoView?.()
    }
  }, [selected])

  useKeyPressEvent('Enter', () => {
    if (document.activeElement === ref.current) {
      handleClick()
    }
  })

  const renderCapitalizedName = getCapitalizedText(name, true)

  return (
    <AttendanceListItemContainer
      ref={ref}
      active={active}
      gap="$4"
      onClick={handleClick}
      role="option"
      aria-checked={active}
      aria-label={t('attendanceItemLabel', { name })}
      tabIndex="0"
    >
      <Flex align="center" justify="center">
        <UserInitials>{getUserInitials(name)}</UserInitials>
      </Flex>

      <Flex direction="column" align="flex-start" gap="$2">
        <Typography fontSize="bodyLargeStandard" fontWeight="medium">
          {renderCapitalizedName}
        </Typography>

        <Tag shape={active ? 'solid' : 'ghost'} size="medium" variant="assorted9">
          {geraId}
        </Tag>
      </Flex>

      <Spacer />

      {!isMobile && (
        <Flex direction="row" justify="flex-start">
          <CloseButton
            hierarchy="tertiary"
            aria-label={t('closeAttendance')}
            role="button"
            size="small"
            onClick={handleCloseAttendanceClicked}
            has="iconOnly"
            css={{ padding: 0 }}
            icon={<CrossIcon size="16" />}
          />

          <Spacer />
        </Flex>
      )}
    </AttendanceListItemContainer>
  )
}

export { AttendanceListItem, type AttendanceListItemProps }
