import { Information } from '@shared/components'
import { useTranslation } from '@shared/i18n'
import { ResellerAttendanceStatus } from '@shared/types'
import { Fragment } from 'react'
import { Container } from './situations.styles'

interface SituationProps {
  blocked: boolean
  harvest?: number
  status: ResellerAttendanceStatus
  canAttend: boolean
}

const message = {
  [ResellerAttendanceStatus.NOT_IN_STRUCTURE]: 'notInStructure',
  [ResellerAttendanceStatus.WITHOUT_STRUCTURE]: 'withoutStructure',
  [ResellerAttendanceStatus.IN_ATTENDANCE_BY_OTHER_SUPERVISOR]: 'inAttendance',
  [ResellerAttendanceStatus.ATTENDANCE_RELEASED]: null,
  [ResellerAttendanceStatus.IN_ATTENDANCE_BY_YOURSELF]: null,
}

const Situations = ({ status, harvest, blocked, canAttend }: SituationProps): JSX.Element => {
  const { t } = useTranslation('situations')
  const messageStatus = message[status]

  const isBlockedAttendance = canAttend && blocked
  const isHarvestAttendance = canAttend && harvest

  const shouldRenderContainer = messageStatus ?? isBlockedAttendance ?? isHarvestAttendance

  if (!shouldRenderContainer) {
    return <Fragment />
  }

  return (
    <Container>
      {messageStatus && <Information status="warning" message={t(messageStatus)} />}
      {isBlockedAttendance && <Information status="error" message={t('blocked')} />}
      {isHarvestAttendance && <Information status="success" message={t('harvest', { harvest })} />}
    </Container>
  )
}

export { Situations }
