import React from 'react'

import { Flex, Tab, TabList, Tabs } from '@grupoboticario/flora-react'
import { CalendarIcon, CheckCircleIcon, CrossCircleIcon } from '@grupoboticario/flora-react-icons'

import { EmptyState } from '@shared/components'
import { useTranslation } from '@shared/i18n'

import { OrderDetails } from '../../order-detail.types'
import { Payment } from '../payment'
import { useOrderPayments } from '../payments.hooks'
import { Carousel, CarouselItem, TabPanel } from './payment-tabs.styles'

interface PaymentTabsProps {
  detail: OrderDetails
}

const PaymentTabs: React.FC<PaymentTabsProps> = ({ detail }) => {
  const { t } = useTranslation('orders', { keyPrefix: 'orderHistory.detail.payments' })
  const payments = useOrderPayments(detail.payments)

  const getDefaultTab = (): string | undefined => {
    if (payments.hasPending) {
      return 'pending'
    }

    if (payments.hasPaidout) {
      return 'done'
    }

    if (payments.hasCanceled) {
      return 'canceled'
    }

    return 'empty'
  }

  return (
    <Tabs defaultValue={getDefaultTab()} orientation="horizontal">
      <TabList>
        <Tab disabled={!payments.hasPending} icon={<CalendarIcon />} value="pending">
          {t('pending')}
        </Tab>

        <Tab disabled={!payments.hasPaidout} icon={<CheckCircleIcon />} value="done">
          {t('done')}
        </Tab>

        <Tab disabled={!payments.hasCanceled} icon={<CrossCircleIcon />} value="canceled">
          {t('canceled')}
        </Tab>
      </TabList>

      <TabPanel value="pending">
        <Carousel>
          {payments.pendings.map((p) => (
            <CarouselItem key={p.identifier}>
              <Payment payment={p} installments={p.installments} />
            </CarouselItem>
          ))}
        </Carousel>
      </TabPanel>

      <TabPanel value="done">
        <Carousel>
          {payments.paidouts.map((p) => (
            <CarouselItem key={p.identifier}>
              <Payment payment={p} installments={p.installments} />
            </CarouselItem>
          ))}
        </Carousel>
      </TabPanel>

      <TabPanel value="canceled">
        <Carousel>
          {payments.canceled.map((p) => (
            <CarouselItem key={p.identifier}>
              <Payment payment={p} installments={p.installments} />
            </CarouselItem>
          ))}
        </Carousel>
      </TabPanel>

      <TabPanel value="empty">
        <Flex justify="center">
          <EmptyState
            size="md"
            title={t('emptyState.title')}
            description={t('emptyState.subtitle')}
          />
        </Flex>
      </TabPanel>
    </Tabs>
  )
}

export { PaymentTabs }
