import React, { useCallback } from 'react'

import { FloraButton as Button } from '@grupoboticario/flora-react'
import { TruckIcon } from '@grupoboticario/flora-react-icons'

import { useApiData } from '@shared/api'
import { useEvents } from '@shared/events'
import { useTranslation } from '@shared/i18n'

import { OrderCustomField } from '../../order-detail.types'

interface TrackingButtonProps {
  resellerId: string
  orderNumber: string
}

const TrackingButton: React.FC<TrackingButtonProps> = ({ resellerId, orderNumber }) => {
  const { t } = useTranslation('orders', { keyPrefix: 'orderHistory.detail.historical' })
  const getApiParams = useCallback(() => ({ resellerId, orderNumber }), [resellerId, orderNumber])
  const events = useEvents()

  const customFields = useApiData<{ customField: OrderCustomField[] }>({
    dataSource: 'getOrderCustomFields',
    fetchOnMount: true,
    getApiParams,
  })

  const fields = customFields.data?.customField
  const field = fields?.find((c) => c.fieldName === 'DC_LINK_RASTREIO')
  const hasLink = field?.fieldValue

  const onClickTrackOrder = (): void => {
    if (hasLink) {
      events.clickOrderButton('link_rastreio')
      window.open(field.fieldValue, '_blank', 'noopener,noreferrer')
    }
  }

  return (
    <Button
      hierarchy="secondary"
      size="small"
      has="iconLeft"
      icon={<TruckIcon />}
      onClick={onClickTrackOrder}
      isLoading={customFields.loading}
      disabled={!hasLink}
    >
      {t('orderTrack')}
    </Button>
  )
}

export { TrackingButton }
