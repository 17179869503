import { Flex, type FlexProps } from '@grupoboticario/flora-react'
import { useIsMobile } from '@shared/hooks'

type GridColumnProps = FlexProps & { showOnMobile: boolean }

const GridColumn: React.FC<GridColumnProps> = ({ showOnMobile, ...props }: GridColumnProps) => {
  const isMobile = useIsMobile()

  if (!showOnMobile && isMobile) {
    return null
  }

  return <Flex {...props}>{props.children}</Flex>
}

export { GridColumn }
