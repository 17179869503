import { Flex } from '@grupoboticario/flora-react'
import React from 'react'
import { Dashboard } from './dashboard'
import { Movements } from './movements'

const MCMFidelity: React.FC = () => {
  return (
    <Flex direction="column" align="stretch" gap="$6">
      <Dashboard />
      <Movements />
    </Flex>
  )
}

export { MCMFidelity }
