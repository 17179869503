import { Flex, Grid, styled, FloraTypography as Typography } from '@grupoboticario/flora-react'

const ItemContainer = styled(Grid, {
  gap: '$4',

  gridTemplateColumns: '1fr',
  gridTemplateAreas: `
    "emissionDate"
    "expirationDate"
    "orderId"
    "titleValue"
    "paymentType"
    "paymentPlan"
    "downloadButton"
  `,

  '> div': {
    display: 'flex',
    alignItems: 'center',
    gap: '$2',

    '> button': {
      width: '100%',

      '@mobile': {
        width: 'initial',
      },
    },
  },

  '@desktop': {
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: '1fr 1fr',
    gridTemplateAreas: `
      "expirationDate titleValue downloadButton"
      "paymentType paymentPlan downloadButton"
    `,
  },
})

const ContainerTags = styled(Flex, {
  minWidth: '160px',
  flexDirection: 'row',
  alignItems: 'center',
  alignSelf: 'flex-start',
  justifyContent: 'left',
  gap: '$4',
})

const HeaderExtra = styled(Flex, {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridRowGap: '$4',

  '@desktop': {
    gridTemplateColumns: '220px calc(100% - 220px - 32px)',
    gridColumnGap: '$4',
  },
})

const HeaderText = styled(Typography, {
  color: '$nonInteractivePredominant',
  fontSize: '$bodyLarge',
  fontWeight: '$medium',
  lineHeight: '$base',
  marginBottom: 0,
})

const InfoContentExtra = styled(Flex, {
  display: 'none !important',

  '@desktop': {
    display: 'grid !important',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '$4',
    width: '100%',
  },

  '> p': {
    justifySelf: 'flex-end',
  },
})

export { ContainerTags, HeaderExtra, HeaderText, InfoContentExtra, ItemContainer }
