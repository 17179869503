import { useEffect, useRef, type UIEvent } from 'react'
import { useClickAway, useKeyPress, useKeyPressEvent } from 'react-use'

import { Flex, Spinner } from '@grupoboticario/flora-react'

import { EmptyState, LoadingState } from '@shared/components'
import { useTranslation } from '@shared/i18n'
import { type Reseller } from '@shared/types/reseller'

import { ResultsListItem } from './results-list-item'
import { CardContainer } from './results-list.styles'

interface ResultsListProps {
  onHide: () => void
  resellers?: Reseller[]
  noResultsFound: boolean
  isLoading: boolean
  onFetchMore: () => void
  isFetchingMore: boolean
  hasMoreData: boolean
  onResultItemClicked: () => void
}

const ResultsList = ({
  resellers,
  isLoading,
  onHide,
  noResultsFound,
  onFetchMore,
  isFetchingMore,
  hasMoreData,
  onResultItemClicked,
}: ResultsListProps): JSX.Element => {
  const isPressed = useKeyPress('Escape')
  const selectedIndex = useRef<number>(-1)

  const ref = useRef<HTMLElement | null>(null)
  const { t } = useTranslation('resultsList')

  useClickAway(ref, () => {
    onHide()
  })

  const resultList = ref.current

  const incrementSelectedItemIndex = (): void => {
    if (resultList?.contains(document.activeElement) && resellers?.length) {
      if (selectedIndex.current >= resellers?.length - 1) {
        selectedIndex.current = 0
      } else {
        selectedIndex.current++
      }
    }
  }

  useKeyPressEvent('ArrowDown', incrementSelectedItemIndex)

  const decrementSelectedItemIndex = (): void => {
    if (resultList?.contains(document.activeElement) && resellers?.length) {
      if (selectedIndex.current - 1 < 0) {
        selectedIndex.current = resellers?.length - 1
      } else {
        selectedIndex.current--
      }
    }
  }

  useKeyPressEvent('ArrowUp', decrementSelectedItemIndex)

  useEffect(() => {
    const [isEscPressed] = isPressed

    if (isEscPressed) {
      onHide()
    }
  }, [isPressed, onHide])

  const showLoading = isLoading && <LoadingState containerHeight="200px" />

  const showIsFetchingMore = isFetchingMore && (
    <Flex align="center" justify="center" css={{ padding: '$4' }}>
      <Spinner role="status" aria-label="fetching more" />
    </Flex>
  )

  const showEmptyState = !isLoading && noResultsFound && (
    <Flex align="center" justify="center" css={{ minHeight: 200 }}>
      <EmptyState title={t('emptyState.title')} description={t('emptyState.subtitle')} />
    </Flex>
  )

  const showResultsList =
    !isLoading &&
    resellers &&
    resellers?.map((reseller, index) => (
      <ResultsListItem
        key={reseller.geraId}
        index={index}
        reseller={reseller}
        selected={selectedIndex.current === index}
        onClick={onResultItemClicked}
      />
    ))

  const handleResultsListScrolled = (event: UIEvent<HTMLDivElement>): void => {
    const didScrollToBottom =
      event.currentTarget.scrollHeight - event.currentTarget.scrollTop ===
      event.currentTarget.clientHeight
    if (didScrollToBottom && hasMoreData && !isFetchingMore) {
      onFetchMore()
    }
  }

  return (
    <CardContainer
      ref={ref}
      role="listbox"
      aria-label="results list"
      variant="active"
      onScroll={handleResultsListScrolled}
      padded="false"
    >
      {showLoading}

      {showEmptyState}

      {showResultsList}

      {showIsFetchingMore}
    </CardContainer>
  )
}

export { ResultsList, type ResultsListProps }
