import { styled } from '@grupoboticario/flora-react'

const ContainerTable = styled('div', {
  borderRadius: '10px 10px 0 0',
  marginBottom: '$3',
})

const StyledTable = styled('table', {
  width: '100%',
  borderCollapse: 'collapse',
  borderSpacing: '0',
  borderStyle: 'none',

  thead: {
    height: '$15',
    backgroundColor: '$backgroundPrimary',

    th: {
      minWidth: '190px',
      color: '$nonInteractiveAuxiliar',
      fontSize: '$auxiliar',
      fontWeight: '$medium',
      lineHeight: '60px',
      textTransform: 'uppercase',
      paddingLeft: '$4',
      paddingRight: '$4',
      verticalAlign: 'middle',
    },

    '@mobile': {
      height: '$15',

      th: {
        paddingLeft: '$8',
        paddingRight: '$8',
      },
    },
  },

  tbody: {
    tr: {
      height: '$19',

      '&:nth-child(odd)': {
        backgroundColor: '$backgroundSecondary',
      },

      '&:nth-child(even)': {
        backgroundColor: '$backgroundPrimary',
      },

      td: {
        color: '$nonInteractivePredominant',
        fontSize: '$bodyLarge',
        fontWeight: '$medium',
        lineHeight: '$short',
        paddingTop: '$3',
        paddingBottom: '$3',

        '&:first-of-type': {
          paddingLeft: '$8',
          paddingRight: '$8',
        },

        '&:not(:first-of-type)': {
          paddingLeft: '$3',
          paddingRight: '$3',
        },
      },
    },
  },
})

const LabelColumn = styled('span', {
  color: '$nonInteractiveAuxiliar',
  fontSize: '$auxiliar',
  fontWeight: '$medium',
  lineHeight: '$short',

  variants: {
    display: {
      block: {
        display: 'block',
      },
      'inline-block': {
        display: 'inline-block',
      },
    },
    paddingRight: {
      true: {
        paddingRight: '$1',
      },
    },
  },
})

export { ContainerTable, LabelColumn, StyledTable }
