import { Fragment, useEffect, useRef } from 'react'
import { useKeyPressEvent } from 'react-use'

import { FloraButton, Modal } from '@grupoboticario/flora-react'

import { useTranslation } from '@shared/i18n'

import { useEvents } from '@shared/events'
import { useModalContext } from '@shared/providers'
import {
  ModalTitle,
  StyledModalBody,
  StyledModalContent,
  StyledModalFooter,
  StyledModalHeader,
} from './modal-container.styles'

const ModalContainer = (): JSX.Element => {
  const events = useEvents()
  const context = useModalContext()
  const { t } = useTranslation('modal')

  const ref = useRef<HTMLDivElement | null>(null)

  useKeyPressEvent('Escape', () => {
    context?.close()
  })

  const isOpen = context.isOpen

  useEffect(() => {
    if (isOpen) {
      ref.current?.focus()
    }
  }, [isOpen])

  const handleCloseButtonClicked = (): void => {
    events.interactionModal(
      context.data?.modalName ?? 'modal-sem-nome',
      context.data?.closeButtonText ?? t('closeModalButtonLabel'),
    )

    context.close()
  }

  const renderCloseButton = context.data?.showCloseButton && (
    <FloraButton
      hierarchy={context.data?.closeButtonHierarchy ?? 'secondary'}
      onClick={handleCloseButtonClicked}
      aria-label={t('closeModal')}
    >
      {context.data?.closeButtonText ?? t('closeModalButtonLabel')}
    </FloraButton>
  )

  const renderCallToAction = context.data?.callToAction ?? null

  const renderBody = (content: string | JSX.Element): JSX.Element => {
    if (typeof content === 'string') {
      return <p>{content}</p>
    }

    return <Fragment>{content}</Fragment>
  }

  const body = context.data?.body ?? ''

  return (
    <Modal open={isOpen}>
      <StyledModalContent
        size={context.data?.size ?? 'small'}
        ref={ref}
        tabIndex={0}
        role="alertdialog"
        aria-modal="true"
        aria-labelledby="vdi-modal-title"
        aria-describedby="vdi-modal-body"
      >
        <StyledModalHeader>
          <ModalTitle level="6" id="vdi-modal-title">
            {context.data?.title}
          </ModalTitle>
        </StyledModalHeader>

        <StyledModalBody id="vdi-modal-body">{renderBody(body)}</StyledModalBody>

        <StyledModalFooter>
          {renderCloseButton}
          {renderCallToAction}
        </StyledModalFooter>
      </StyledModalContent>
    </Modal>
  )
}

export { ModalContainer }
