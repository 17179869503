import { PaginationData } from '@shared/types'

interface OrdersDTO {
  orders: Order[]
  pagination: PaginationData
}

interface Order {
  number: string
  date: string
  status: OrderStatusType
  statusDescription: string
  totalValue: number
  cycle: string
}

enum OrderStatusType {
  DELIVERED = 'ENTREGUE',
  CANCELLED = 'CANCELADO',
  SEPARATION = 'SEPARACAO',
  TRANSPORT = 'ENTREGANDO_PRODUTOS',
  CREATED = 'CAPTACAO',
  APPROVED = 'PROCESSANDO_PEDIDO',
}

export { Order, OrdersDTO, OrderStatusType }
