import { Box, Flex, FloraTypography as Typography } from '@grupoboticario/flora-react'
import { toCurrency } from '@grupoboticario/vdi-mfe-utils'

import { useTranslation } from '@shared/i18n'

import { DataBlock } from '@shared/components'

interface NextTierProgressProps {
  remainingValue: number
  totalValue: number
  name: string
}

const NextTierProgress: React.FC<NextTierProgressProps> = (props) => {
  const { t } = useTranslation('resellerFidelity')

  const { remainingValue, name, totalValue } = props

  const remainingPercentage = Math.floor(((totalValue - remainingValue) / totalValue) * 100)

  return (
    <DataBlock
      renderContentOnly
      content={
        <Flex direction="column" as="li" gap="$1" aria-label={t('nextTierProgressTitle')}>
          <Box
            css={{
              height: '$2',
              minHeight: '$2',
              width: '100%',
              borderRadius: '40px',
              overflow: 'hidden',
              background: '$disabledBackground !important',
            }}
          >
            <Box
              as="progress"
              aria-label={t('nextTierProgressLabel', { progress: remainingPercentage, tier: name })}
              css={{
                display: 'block',
                height: '100%',
                width: `${remainingPercentage}%`,
                background: '$nonPrimaryButtonContent',
              }}
            />
          </Box>

          <Flex direction="column">
            <Typography
              fontSize="exceptionsRestricted"
              fontWeight="medium"
              css={{ color: '$nonInteractiveAuxiliar' }}
            >
              {t('nextTierRemaining')}{' '}
              <strong style={{ fontWeight: '700' }}>
                {t('nextTierRemainingValue', { tier: name })}
              </strong>
            </Typography>

            <Typography
              fontSize="bodyLargeStandard"
              fontWeight="medium"
              css={{ color: '$nonInteractivePredominant' }}
            >
              {toCurrency(remainingValue)}
            </Typography>
          </Flex>
        </Flex>
      }
    />
  )
}

export { NextTierProgress, NextTierProgressProps }
