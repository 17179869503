import { Flex, Pagination, FloraTypography as Typography } from '@grupoboticario/flora-react'
import { GridMobile } from '@shared/components'
import { useTranslation } from '@shared/i18n'
import { useFidelityData } from '../../../hooks'

const MovementPagination: React.FC = () => {
  const {
    movements: { data, pagination, loading },
  } = useFidelityData()
  const { t } = useTranslation('resellerFidelityMovements')

  if (!data) {
    return null
  }

  return (
    <GridMobile templateColumns="1fr 1fr 1fr" mobileTemplateColumns="1fr" gap="$4">
      <Flex
        align="center"
        css={{ justifyContent: 'center', '@mobile': { justifyContent: 'start' } }}
      >
        <Typography
          fontSize="bodySmallShort"
          fontWeight="medium"
          css={{
            color: '$nonInteractiveAuxiliar',
          }}
        >
          {loading
            ? t('loadingData')
            : t('showingOfResults', {
                pageSize: data.items.length,
                totalItems: data.pagination.totalItems,
              })}
        </Typography>
      </Flex>

      <Flex justify="center" align="center">
        <Pagination
          page={pagination.currentPage - 1}
          totalRecords={data.pagination.totalItems}
          itemsPerPage={data.pagination.pageSize}
          onChange={(p) => pagination.setPage(p + 1)}
          css={{
            button: {
              marginRight: '$1',
              '&:last-child': {
                marginRight: 0,
              },
            },
          }}
        />
      </Flex>
    </GridMobile>
  )
}

export { MovementPagination }
