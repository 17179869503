import React, { PropsWithChildren } from 'react'

import { CardContainer } from './card.styles'

interface CardProps extends PropsWithChildren {}

const Card: React.FC<CardProps> = ({ children }) => {
  return <CardContainer>{children}</CardContainer>
}

export { Card }
