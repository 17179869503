import { ChevronUpIcon } from '@grupoboticario/flora-react-icons'
import { TabPanelProps as FloraTabPanelProps } from '@grupoboticario/flora-react-tabs'
import { useIsMobile } from '@shared/hooks'
import React from 'react'
import { ScrollToTopButton, StyledTabPanel } from './tab-panels.styles'

type TabPanelProps = {
  children: React.ReactNode
}

const TabPanel: React.FC<FloraTabPanelProps & TabPanelProps> = (props) => {
  const [isScrolled, setIsScrolled] = React.useState(false)

  const panel = React.createRef<HTMLElement>()
  const isMobile = useIsMobile()

  const scrollToTop = () => {
    panel.current?.scrollTo(0, 0)
  }

  const handlePanelScrolled = (event: React.UIEvent<HTMLElement>): void => {
    if (event.currentTarget.scrollTop === 0) {
      window.postMessage({ name: 'reseller-data-scrolled', scrolledToTop: true })
      setIsScrolled(false)
    } else {
      window.postMessage({ name: 'reseller-data-scrolled', scrolledToTop: false })
      setIsScrolled(true)
    }
  }

  return (
    <StyledTabPanel {...props} onScroll={handlePanelScrolled} ref={panel}>
      {props.children}
      {isScrolled && isMobile && (
        <ScrollToTopButton onClick={scrollToTop} size="medium">
          <ChevronUpIcon />
        </ScrollToTopButton>
      )}
    </StyledTabPanel>
  )
}

export { TabPanel }
