import { Fragment } from 'react'

import { type ResellerAddresses } from '@reseller-data/providers'
import { useTranslation } from '@shared/i18n'

import { AddressType } from './address-type'

interface AddressesInfoProps {
  address?: ResellerAddresses
}

const AddressesInfo: React.FC<AddressesInfoProps> = ({ address }) => {
  const { t } = useTranslation('addressesInfo')

  const isDeliverySameResidential = address?.isSame

  return (
    <Fragment>
      <AddressType
        address={address?.residential}
        title={t('title')}
        heading={t('residential')}
        checked={isDeliverySameResidential}
        checkbox
      />

      {!isDeliverySameResidential && (
        <AddressType address={address?.delivery} heading={t('delivery')} />
      )}
    </Fragment>
  )
}

export { AddressesInfo }
