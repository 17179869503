import { Fragment } from 'react'

import { RessellerDataModel } from '@reseller-data/hooks'

import { TabulationHistory } from '@vdi/tabulation'

import { getTabulationHistoryConfig } from '@shared/config'

import { AdditionalInfo } from '../additional-info'
import { AddressesInfo } from '../addresses-info'
import { Allocation } from '../allocation-info'
import { ContactInfo } from '../contact-info'
import { GeneralInfo } from '../general-info'

interface ReleasedInfoProps {
  model: RessellerDataModel
  onUpdateAdditionalInfo: () => void
  attendanceId?: string
}

const ReleasedInfo: React.FC<ReleasedInfoProps> = ({
  onUpdateAdditionalInfo,
  attendanceId,
  model,
}) => (
  <Fragment>
    <GeneralInfo general={model.general} />
    <Allocation cs={model.general.cs} />
    <ContactInfo contact={model.contact} />
    <TabulationHistory {...getTabulationHistoryConfig(model.general.geraId, attendanceId)} />
    <AdditionalInfo
      additionalInformation={model.additionalInformation}
      onUpdated={onUpdateAdditionalInfo}
    />
    <AddressesInfo address={model.address} />
  </Fragment>
)

export { ReleasedInfo }
