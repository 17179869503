import { styled } from '@grupoboticario/flora'
import { Flex } from '@grupoboticario/flora-react'

const Header = styled(Flex, {
  alighItems: 'center',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  gap: '$4',

  '@tablet': {
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
})

export { Header }
