import { Fragment } from 'react'

import { useResellerData, useResellerDataView } from '../../hooks'
import { Situations } from './situations'

const SituationData = (): JSX.Element => {
  const { model: view } = useResellerDataView()
  const { model: data, isReleasedAttendance } = useResellerData()

  return (
    <Fragment>
      {data && (
        <Situations
          harvest={view?.harvest}
          blocked={data.statuses.isBlocked}
          status={data.statuses.actionStatus}
          canAttend={isReleasedAttendance}
        />
      )}
    </Fragment>
  )
}

export { SituationData }
