import { useEffect, useRef } from 'react'
import { useKeyPressEvent } from 'react-use'

import { Flex, Tag, FloraTypography as Typography } from '@grupoboticario/flora-react'
import { toCPF } from '@grupoboticario/vdi-mfe-utils'

import { useStartAttendance } from '@register-attendance/hooks'
import { useEvents } from '@shared/events'
import { useTranslation } from '@shared/i18n'
import { ResellerAttendanceStatus, type Reseller } from '@shared/types/reseller'
import { getCapitalizedText } from '@shared/utils'
import { getUserInitials } from '@shared/utils/get-user-initials'

import { ListItem, UserInitials } from './results-list-item.styles'
interface ResultsListItemProps {
  index?: number
  reseller: Reseller
  selected: boolean
  onClick: () => void
}

const ResultsListItem = ({
  index,
  reseller,
  selected,
  onClick,
}: ResultsListItemProps): JSX.Element => {
  const { t } = useTranslation('resultsListItem')
  const ref = useRef<HTMLInputElement | null>(null)
  const { cpf, name, geraId, actionStatus } = reseller
  const warningMessage = attendanceWarningMessage[actionStatus]

  const events = useEvents()
  const startAttendance = useStartAttendance()

  const handleItemClicked = async (): Promise<void> => {
    onClick()
    const attendanceId = await startAttendance(geraId, name)
    events.clickSearchResult(geraId, attendanceId)
  }

  useKeyPressEvent('Enter', () => {
    if (document.activeElement === ref.current) {
      void handleItemClicked()
    }
  })

  useEffect(() => {
    if (index === 0) {
      ref.current?.focus()
    }
  }, [index])

  useEffect(() => {
    if (selected) {
      ref.current?.focus()
      ref.current?.scrollIntoView?.()
    }
  }, [selected])

  const canNotAttend = warningMessage && (
    <Typography
      fontSize="exceptionsAuxiliar"
      fontWeight="medium"
      css={{ zIndex: '$docked', color: '$statusAlertNonInteractiveEmphasis' }}
    >
      {t(warningMessage)}
    </Typography>
  )

  return (
    <ListItem tabIndex={0} data-testid={geraId} ref={ref} onClick={handleItemClicked}>
      <UserInitials size="auxiliarSmall">{getUserInitials(name)}</UserInitials>
      <Flex wrap="no-wrap" direction="column" gap="$2">
        <Typography
          as="h4"
          fontSize="bodySmallShort"
          fontWeight="medium"
          css={{ color: '$nonInteractivePredominant' }}
        >
          {getCapitalizedText(name, true)}
        </Typography>

        <Flex gap="6px" align="center">
          <Tag alt="false" shape="ghost" size="medium" variant="assorted9">
            {geraId}
          </Tag>

          <Typography
            fontSize="exceptionsAuxiliar"
            fontWeight="medium"
            css={{ color: '$nonInteractiveAuxiliar' }}
          >
            {t('documentCPF', { documentCPF: toCPF(cpf) })}
          </Typography>
        </Flex>

        {canNotAttend}
      </Flex>
    </ListItem>
  )
}

const attendanceWarningMessage = {
  [ResellerAttendanceStatus.ATTENDANCE_RELEASED]: null,
  [ResellerAttendanceStatus.NOT_IN_STRUCTURE]: 'notInStructure',
  [ResellerAttendanceStatus.WITHOUT_STRUCTURE]: 'withoutStructure',
  [ResellerAttendanceStatus.IN_ATTENDANCE_BY_YOURSELF]: null,
  [ResellerAttendanceStatus.IN_ATTENDANCE_BY_OTHER_SUPERVISOR]: 'inAttendance',
}

export { ResultsListItem, type ResultsListItemProps }
