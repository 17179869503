import { DrawerBody, DrawerContent, DrawerHeader, styled } from '@grupoboticario/flora-react'

const StyledDrawerHeader = styled(DrawerHeader, {
  borderBottom: '1px solid $nonInteractiveOutline',
})

const StyledDrawerBody = styled(DrawerBody, {
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
})

const StyledDrawerContent = styled(DrawerContent, {
  backgroundColor: '$backgroundSecondary !important',

  '@mobile': {
    width: '600px !important',
  },

  '@tablet': {
    width: '800px !important',
  },
})

export { StyledDrawerBody, StyledDrawerContent, StyledDrawerHeader }
