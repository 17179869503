import { createContext, useEffect, useState, type ReactNode } from 'react'

import { DrawerContainer } from '@shared/components'

const DrawerContext = createContext<DrawerContextData | null>(null)

interface DrawerProviderProps {
  children: ReactNode
}

interface DrawerData {
  title?: string
  content: JSX.Element
}

interface DrawerContextData {
  data?: DrawerData
  isOpen: boolean
  open: (Drawer: DrawerData) => void
  close: () => void
}

let drawerTrigger: null | Element = null

const DrawerProvider = ({ children }: DrawerProviderProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [drawerData, setDrawerData] = useState<DrawerData | undefined>(undefined)

  useEffect(() => {
    if (drawerData) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [drawerData])

  const open = (drawer: DrawerData): void => {
    setDrawerData(drawer)
    drawerTrigger = document.activeElement
  }

  const close = (): void => {
    setDrawerData(undefined)

    if (drawerTrigger !== null) {
      const triggerElement = drawerTrigger as HTMLElement

      setTimeout(() => {
        triggerElement.focus()
      }, 0)
    }
  }

  const providerValue = { open, isOpen, data: drawerData, close }

  return (
    <DrawerContext.Provider value={providerValue}>
      {children}
      <DrawerContainer />
    </DrawerContext.Provider>
  )
}

export { DrawerContext, DrawerProvider, type DrawerData }
