import {
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  Drawer as DrawerFlora,
  DrawerHeader,
  styled,
  FloraTypography as Typography,
} from '@grupoboticario/flora-react'

import { scrollbar } from '../scrollbar'

const Body = styled(DrawerBody, {
  display: 'flex',
  flex: 1,
  padding: '$4 $6',
  ...scrollbar,
})

const Content = styled(DrawerContent, {
  backgroundColor: '$backgroundSecondary',
  width: '100vw !important',
  '@mobile': {
    width: 'auto !important',
  },
})

const Drawer = styled(DrawerFlora, {
  width: '100vw !important',
  '@mobile': {
    width: 'auto !important',
  },
})

const ButtonClose = styled(DrawerCloseButton, {
  'svg > path': {
    fill: '$assorted9',
  },
})

const Header = styled(DrawerHeader, {
  borderBottomWidth: '$hairline',
  borderBottomStyle: 'solid',
  borderBottomColor: '$nonInteractiveOutline',
  padding: '$6',

  backgroundColor: 'white',

  '@mobile': {
    backgroundColor: 'initial',
  },
})

const Title = styled(Typography, {
  color: '$nonInteractivePredominant',
  fontSize: '$subtitleDesk',
  fontWeight: '$medium',
  lineHeight: '$short',
})

export { Body, ButtonClose, Content, Drawer, Header, Title }
