import { type IMonitoringClient } from '@grupoboticario/vdi-mfe-utils'
import i18next, { type i18n, type InitOptions } from 'i18next'
import { initReactI18next } from 'react-i18next'

import { resources } from './locales'
import { Language } from './use-translation'

const initI18n = (monitor: IMonitoringClient): i18n => {
  i18next
    .use(initReactI18next)
    .init(i18nConfig)
    .catch((err) => monitor.error('InitI18nError', err))

  return i18next
}

const i18nConfig: InitOptions = {
  resources,
  lng: Language.ptBR,
  fallbackLng: Language.ptBR,
  interpolation: {
    escapeValue: false,
  },
}

export { initI18n }
