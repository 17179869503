import React from 'react'

import { theme } from '@grupoboticario/flora-react'

import { DataBlock, DataSection } from '@shared/components'
import { useTranslation } from '@shared/i18n'

import { useResellerDataView } from '../../../../hooks'

const OrderChannels: React.FC = () => {
  const { model, getData, error, filled, unavailable, loading, notFound } = useResellerDataView()
  const { t } = useTranslation('orderChannels')

  return (
    <DataSection
      unavailable={unavailable}
      error={error}
      filled={filled}
      loading={loading}
      data={model}
      onRetry={getData}
      title={t('channel')}
      fallbackHeight={100}
      notFound={notFound}
      render={({ orderChannelsReseller }) => (
        <DataBlock
          title={t('whereHasPurchased')}
          description={{
            text: orderChannelsReseller ?? t('hasNotPurchaseOnCycle'),
            color: orderChannelsReseller
              ? theme.colors.nonInteractivePredominant.value
              : theme.colors.statusAlertNonInteractiveEmphasis.value,
          }}
        />
      )}
    />
  )
}

export { OrderChannels }
