import React, { createContext, PropsWithChildren, useCallback, useContext } from 'react'

import { useApiData, UseApiDataOutput } from '@shared/api'

import { OrderHistorical } from './historical/historical.types'
import { OrderDetails } from './order-detail.types'

interface OrderDetailProviderProps extends PropsWithChildren {
  resellerId: string
  orderNumber: string
}

const OrderDetailProvider: React.FC<OrderDetailProviderProps> = ({
  children,
  resellerId,
  orderNumber,
}) => {
  const getApiParams = useCallback(() => ({ resellerId, orderNumber }), [resellerId, orderNumber])

  const detail = useApiData<OrderDetails>({
    dataSource: 'getResellerOrderDetail',
    fetchOnMount: true,
    getApiParams,
  })

  const historical = useApiData<OrderHistorical[]>({
    dataSource: 'getOrderHistorical',
    fetchOnMount: true,
    getApiParams,
  })

  return (
    <OrderDetailContext.Provider value={detail}>
      <OrderDetailHistoricalContext.Provider value={historical}>
        {children}
      </OrderDetailHistoricalContext.Provider>
    </OrderDetailContext.Provider>
  )
}

const OrderDetailContext = createContext<UseApiDataOutput<OrderDetails> | null>(null)
const OrderDetailHistoricalContext = createContext<UseApiDataOutput<OrderHistorical[]> | null>(null)

const useOrderDetailContext = (): UseApiDataOutput<OrderDetails> => {
  const context = useContext(OrderDetailContext)
  if (!context) throw new Error('Order detail provider not found')
  return context
}

const useOrderDetailHistoricalContext = (): UseApiDataOutput<OrderHistorical[]> => {
  const context = useContext(OrderDetailHistoricalContext)
  if (!context) throw new Error('Order detail provider not found')
  return context
}

export { OrderDetailProvider, useOrderDetailContext, useOrderDetailHistoricalContext }
