import { Fragment } from 'react'

import { FloraTypography as Typography } from '@grupoboticario/flora-react'
import { useResellerDataView } from '@reseller-data/hooks'

import { DataBlock, DataSection, List } from '@shared/components'
import { useTranslation } from '@shared/i18n'

import { PromotionalDataItem } from './promotional-data-item'

const PromotionalData = (): JSX.Element => {
  const { t } = useTranslation('promotionalData')
  const { model, filled, error, loading, refetch } = useResellerDataView()

  const campaignsFilled = model?.campaigns?.length
    ? model?.campaigns.some((campaign) => campaign.promotions.length > 0)
    : false

  const noCampaigns = !campaignsFilled && (
    <Typography
      data-testid="empty-promotions"
      fontSize="bodyLargeShort"
      fontWeight="medium"
      css={{ color: '$statusAlertNonInteractiveEmphasis' }}
    >
      {t('emptyPromotions')}
    </Typography>
  )

  return (
    <DataSection
      data-testid="profile-promotion-list"
      title={t('title')}
      fallbackHeight={campaignsFilled ? 400 : 'auto'}
      filled={filled}
      error={error}
      loading={loading}
      onRetry={refetch}
      data={model}
      render={(data) => (
        <DataBlock
          data-testid="profile-data-orders"
          title={t('activePromotionsText')}
          content={
            <Fragment>
              {noCampaigns}

              {campaignsFilled && (
                <List
                  type="accordion"
                  data={data.campaigns}
                  renderItem={(campaign, index) => (
                    <PromotionalDataItem
                      key={index}
                      name={campaign.name}
                      promotions={campaign.promotions}
                    />
                  )}
                />
              )}
            </Fragment>
          }
        />
      )}
    />
  )
}

export { PromotionalData }
