import { FloraButton, styled } from '@grupoboticario/flora-react'

const Button = styled(FloraButton, {
  minWidth: '152px',

  '@desktop': {
    gridRowStart: '2',
    gridColumn: '3 / -1',
    justifySelf: 'flex-end',
  },
})

export { Button }
