import { styled } from '@grupoboticario/flora'
import { FloraTypography } from '@grupoboticario/flora-react'
import { OperationType } from '../../mcm-fidelity.types'

const OperationTypography = styled(FloraTypography, {
  display: 'inline',
  variants: {
    operationType: {
      [OperationType.Credit]: {
        color: '$statusSuccessNonInteractiveEmphasis',
      },
      [OperationType.Debit]: {
        color: '$statusErrorNonInteractiveEmphasis',
      },
    },
  },
})

export { OperationTypography }
