import { Flex, FloraTypography as Typography } from '@grupoboticario/flora-react'
import { DataBlock } from '@shared/components'

interface PointPanelProps {
  value: number
  title: string
  description: string | React.ReactNode
  prefix?: string
}

const PointPanel: React.FC<PointPanelProps> = (props) => {
  const { value, title, description, prefix } = props

  return (
    <DataBlock
      renderContentOnly
      content={
        <Flex as="li" direction="column" aria-label={title}>
          <Typography
            fontSize="subtitle"
            fontWeight="medium"
            css={{ color: '$nonInteractivePredominant' }}
          >
            {prefix}
            {value.toLocaleString('pt-BR')}
          </Typography>

          <Typography
            fontSize="exceptionsRestricted"
            fontWeight="medium"
            css={{ color: '$nonInteractiveAuxiliar' }}
          >
            {title.toUpperCase()}
          </Typography>

          <Typography
            fontSize="exceptionsRestricted"
            fontWeight="medium"
            css={{ color: '$nonInteractiveAuxiliar' }}
          >
            {description}
          </Typography>
        </Flex>
      }
    />
  )
}

export { PointPanel, PointPanelProps }
