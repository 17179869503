import { Flex, styled, FloraTypography as Typography } from '@grupoboticario/flora-react'

const Container = styled(Flex, {
  alignSelf: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  gap: '$8',
  padding: '$5 0',

  svg: {
    width: 56,
    height: 56,
    color: '$error-standard',
  },
})

const Title = styled(Typography, {
  color: '$nonInteractivePredominant',
  fontSize: '$bodyLarge',
  fontWeight: '$medium',
  lineHeight: '$shorter',
})

export { Container, Title }
