import { useCallback, useState } from 'react'

import { useEvents } from '@shared/events'

import { useResellersOrders } from './order-history.hooks'

import { OrderDetail } from './order-detail'
import { OrderDrawer } from './order-drawer'
import { OrderHistoryContainer } from './order-history.styles'
import { OrderList } from './order-list'
import { OrdersHeader } from './orders-header'

interface OrderHistoryProps {
  resellerId: string
}

const OrderHistory: React.FC<OrderHistoryProps> = ({ resellerId }) => {
  const [orderNumber, setOrderNumber] = useState('')
  const orders = useResellersOrders(resellerId)
  const events = useEvents()

  const onOrderClick = useCallback((number: string) => {
    setOrderNumber(number)
  }, [])

  const onOpenChange = () => setOrderNumber('')

  const clickOnShowMore = (): void => {
    orders.getOrders()
    events.clickOrderButton('detalhe-pedido')
  }

  const onFilterChanged = (status: string): void => {
    orders.filter({ status })
  }

  return (
    <OrderHistoryContainer>
      <OrdersHeader
        onStatusChange={onFilterChanged}
        orderStatus={orders.filters.status as string}
      />

      <OrderList
        list={orders.list}
        error={orders.error}
        loading={orders.loading}
        empty={orders.empty}
        onShowMore={clickOnShowMore}
        onOrderClick={onOrderClick}
        hasMore={orders.hasMore}
      />

      <OrderDrawer open={Boolean(orderNumber)} onOpenChange={onOpenChange}>
        <OrderDetail resellerId={resellerId} orderNumber={orderNumber} />
      </OrderDrawer>
    </OrderHistoryContainer>
  )
}

export { OrderHistory }
