import { Box as BoxFlora, styled } from '@grupoboticario/flora-react'

const Box = styled(BoxFlora, {
  position: 'relative',
  height: '100%',

  '&:focus': {
    outline: 'none',
  },

  '&:focus-visible': {
    outlineWidth: 0,
  },
})

const SPACE_BETWEEN_SECTION = '$6'

export { Box, SPACE_BETWEEN_SECTION }
