import { useFidelityData } from './hooks/use-fidelity-data'
import { MCBFidelity } from './mcb-fidelity/mcb-fidelity'
import { MCMFidelity } from './mcm-fidelity'
import { FidelityDataProvider } from './providers/fidelity-data-provider'

interface ResellerFidelityProps {
  resellerId: string
}

const ResellerFidelityWithoutProvider = ({ resellerId }: ResellerFidelityProps): JSX.Element => {
  const { fidelity } = useFidelityData()

  if (fidelity.data?.isMcm) {
    return <MCMFidelity />
  }

  return <MCBFidelity resellerId={resellerId} />
}

const ResellerFidelity = ({ resellerId }: ResellerFidelityProps): JSX.Element => {
  return (
    <FidelityDataProvider resellerId={resellerId}>
      <ResellerFidelityWithoutProvider resellerId={resellerId} />
    </FidelityDataProvider>
  )
}

export { ResellerFidelity }
