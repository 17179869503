import { Tooltip } from '@grupoboticario/flora-react'
import { QuestionMarkCircleIcon } from '@grupoboticario/flora-react-icons'

import { useTranslation } from '@shared/i18n'

import { Title } from './data-block-title.styles'
import { DataBlockTitleProps } from './data-block-title.types'

const DataBlockTitle = ({ tooltip, title }: DataBlockTitleProps): JSX.Element => {
  const { t } = useTranslation('dataBlockTitle')

  let renderTitle: string | JSX.Element = title
  let renderTooltip: null | JSX.Element = null

  if (tooltip) {
    renderTitle = <span>{title}</span>

    renderTooltip = (
      <Tooltip
        text={tooltip.text}
        icon={tooltip.icon ?? <QuestionMarkCircleIcon />}
        iconLabel={tooltip.iconLabel ?? t('toolTipBalloonDefault')}
        direction={tooltip.direction ?? 'bottom'}
        showArrow
      >
        <QuestionMarkCircleIcon size="14px" />
      </Tooltip>
    )
  }

  return (
    <Title as="h4">
      {renderTitle}
      {renderTooltip}
    </Title>
  )
}

export { DataBlockTitle }
