import { Box, styled } from '@grupoboticario/flora-react'

const CardContainer = styled(Box, {
  position: 'absolute',
  top: '$15',
  width: '100%',
  maxHeight: '300px',
  backgroundColor: '$background',
  borderRadius: '$medium',
  boxShadow: '$downMid',
  border: '1px solid $nonInteractiveOutline',
  marginTop: '$2',
  overflowY: 'auto',
  zIndex: '$docked',
})

export { CardContainer }
