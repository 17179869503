import { Flex, useMaxWidth } from '@grupoboticario/flora-react'
import { LoadingState } from '@shared/components'
import { useTranslation } from '@shared/i18n'
import { useFidelityData } from '../../../hooks'
import { mapMovementTypeToString } from '../../helpers/movement-type-to-string'
import { Movement } from '../../mcm-fidelity.types'
import { MovementAmount } from './movement-amount/movement-amount'
import { Table } from './movement-table.styles'

const MovementTable: React.FC = () => {
  const { t } = useTranslation(['resellerFidelityMovements', 'generalData'])

  const {
    movements: { loading, data },
  } = useFidelityData()

  const isSmallScreen = useMaxWidth('1420px')

  const renderDataRows = (movementData: Movement[]) => {
    return movementData.map((item) => {
      const { date, expirationDate, movementType, amount, operationType } = item
      const formattedDate = new Date(date).toLocaleDateString()
      const formattedExpirationDate = expirationDate
        ? new Date(expirationDate).toLocaleDateString()
        : '---'
      return (
        <tr key={item.amount.toString().concat(date)}>
          <td>{formattedDate}</td>
          <td>
            <MovementAmount amount={amount} operationType={operationType} />
          </td>
          <td>{t(mapMovementTypeToString(movementType.toString()))}</td>
          <td>{formattedExpirationDate}</td>
        </tr>
      )
    })
  }

  const renderEmptySpace = () => {
    const emptyRows = 5 - (data?.items?.length ?? 0)

    return <tr key="empty-row-spacing" style={{ height: 61 * emptyRows }} />
  }

  const renderLoading = () => {
    return (
      <td colSpan={4} style={{ height: 61 * 5 }}>
        <Flex align="center" justify="center" css={{ height: '100%' }}>
          <LoadingState
            title={t('generalData:loadingState.title')}
            description={t('generalData:loadingState.subtitle')}
          />
        </Flex>
      </td>
    )
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>{isSmallScreen ? t('date') : t('movementDate')}</th>
          <th scope="col">{t('points')}</th>
          <th scope="col">{t('type')}</th>
          <th scope="col">{t('expirationDate')}</th>
        </tr>
      </thead>

      {loading && renderLoading()}

      <tbody>
        {!loading && data && renderDataRows(data.items)}
        {!loading && renderEmptySpace()}
      </tbody>
    </Table>
  )
}

export { MovementTable }
