import { Fragment, useEffect, useRef } from 'react'
import { useKeyPressEvent } from 'react-use'

import { ChatBubbleOffIcon } from '@grupoboticario/flora-react-icons'

import { useCurrentAttendanceContext } from '@register-attendance/providers'
import { EmptyState, ErrorState, LoadingState } from '@shared/components'
import { useTranslation } from '@shared/i18n'
import { useAppContext } from '@shared/providers'

import { useAttendanceList, useParamResellerId, useStartAttendance } from '../../hooks'
import { AttendanceListItem } from './attendance-list-item'
import {
  AttendanceListTitle,
  AttendanceListWrapper,
  AttendanceStatesWrapper,
} from './attendance-list.styles'
import { type AttendanceItem } from './attendance-list.types'

const AttendanceList = (): JSX.Element => {
  const { getAttendanceList, attendances, loading, error, filled, notFound } = useAttendanceList()

  const getParamResellerId = useParamResellerId()

  const startAttendance = useStartAttendance()

  const { appState } = useAppContext()
  const { setCurrentAttendance, currentAttendance } = useCurrentAttendanceContext()

  const selectedIndex = useRef<number>(-1)
  const ref = useRef<HTMLInputElement | null>(null)
  const showItems = filled && !notFound
  const showEmptyState = notFound

  useEffect(() => {
    const reId = getParamResellerId()

    if (reId) {
      void startAttendance(reId, '')
    } else {
      getAttendanceList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAttendanceList, getParamResellerId])

  const { t } = useTranslation(['attendanceList', 'common'])

  const handleAttendanceClicked = (attendance: AttendanceItem): void => {
    setCurrentAttendance({
      attendanceId: attendance.id,
      resellerId: attendance.geraId,
      resellerName: attendance.name,
    })
  }

  const incrementSelectedItemIndex = (): void => {
    if (ref.current?.contains(document.activeElement) && attendances?.length) {
      if (selectedIndex.current + 1 >= attendances?.length - 1) {
        selectedIndex.current = attendances?.length - 1
      } else {
        selectedIndex.current++
      }
    }
  }

  useKeyPressEvent('ArrowDown', incrementSelectedItemIndex)

  const decrementSelectedItemIndex = (): void => {
    if (ref.current?.contains(document.activeElement) && attendances?.length) {
      if (selectedIndex.current - 1 < 0) {
        selectedIndex.current = 0
      } else {
        selectedIndex.current--
      }
    }
  }

  useKeyPressEvent('ArrowUp', decrementSelectedItemIndex)

  if (appState.withoutStructure) {
    return <Fragment />
  }

  const renderEmptyState = showEmptyState && (
    <AttendanceStatesWrapper>
      <EmptyState
        title={t('emptyState.title')}
        description={t('emptyState.subtitle')}
        icon={<ChatBubbleOffIcon />}
        size="md"
      />
    </AttendanceStatesWrapper>
  )

  const renderLoading = loading && (
    <AttendanceStatesWrapper>
      <LoadingState />
    </AttendanceStatesWrapper>
  )

  const renderError = error && (
    <AttendanceStatesWrapper>
      <ErrorState
        title={t('errorState.title')}
        description={t('errorState.subtitle')}
        buttonText={t('common:tryAgain')}
        onButtonClick={getAttendanceList}
      />
    </AttendanceStatesWrapper>
  )

  const renderItems = showItems && (
    <Fragment>
      <AttendanceListTitle as="h3">{t('activeAttendance')}</AttendanceListTitle>

      <AttendanceListWrapper direction="column" gap="$2" ref={ref}>
        {attendances?.map((item, index) => (
          <AttendanceListItem
            key={item.geraId}
            active={currentAttendance?.attendanceId === item.id}
            selected={selectedIndex.current === index}
            attendance={item}
            onClick={handleAttendanceClicked}
          />
        ))}
      </AttendanceListWrapper>
    </Fragment>
  )

  return (
    <Fragment>
      {renderEmptyState}
      {renderLoading}
      {renderError}
      {renderItems}
    </Fragment>
  )
}

export { AttendanceList }
