import { styled } from '@grupoboticario/flora-react'

const HistoricalContainer = styled('div', {
  display: 'flex',
  gap: '$2',
  backgroundColor: '$backgroundPrimary',
  borderRadius: '$medium',
  padding: '$6',
  flexWrap: 'wrap',
})

export { HistoricalContainer }
