import React from 'react'

import { DataSection } from '@shared/components'
import { useTranslation } from '@shared/i18n'

import { Flex } from '@grupoboticario/flora-react'

import { useOrderDetailHistoricalContext } from '../order-detail.provider'
import { HistoricalStep } from './historical-step'
import { HistoricalContainer } from './historical.styles'
import { TrackingButton } from './tracking-button'

interface HistoricalProps {
  resellerId: string
  orderNumber: string
}

const Historical: React.FC<HistoricalProps> = ({ resellerId, orderNumber }) => {
  const { t } = useTranslation('orders', { keyPrefix: 'detail.historical' })
  const { data, filled, loading, error, refetch } = useOrderDetailHistoricalContext()

  return (
    <DataSection
      data={data}
      error={error}
      filled={filled}
      loading={loading}
      onRetry={refetch}
      fallbackHeight={145}
      title={
        <Flex as="span" align="center" justify="space-between">
          {t('orderFollow')}
          <TrackingButton resellerId={resellerId} orderNumber={orderNumber} />
        </Flex>
      }
      render={(historical) => (
        <HistoricalContainer>
          {historical.map((h, i) => (
            <HistoricalStep key={i} {...h} />
          ))}
        </HistoricalContainer>
      )}
    />
  )
}

export { Historical }
