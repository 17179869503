import { Fragment } from 'react'

import { FloraButton, FloraButtonProps } from '@grupoboticario/flora-react'

import { useCurrentAttendanceContext } from '@register-attendance/providers'

import { useEvents } from '@shared/events'
import { useTabulation } from '@shared/hooks'
import { useTranslation } from '@shared/i18n'

import { useResellerData } from '../../hooks'

const EndAttendanceButton: React.FC<FloraButtonProps> = (props) => {
  const { t } = useTranslation('endAttendanceButton')
  const { isReleasedAttendance, model, loading, error } = useResellerData()
  const { currentAttendance, setCurrentAttendance } = useCurrentAttendanceContext()
  const events = useEvents()

  const setIsReleased = isReleasedAttendance ? 'isReleased' : 'isNotReleased'

  const tabulation = useTabulation({
    attendanceId: currentAttendance?.attendanceId,
    reId: model?.general.geraId,
    reName: model?.general.name,
  })

  if (!currentAttendance || loading || error) {
    return <Fragment />
  }

  const config = {
    baseOnClick: () => events.clickAttendanceFinishButton(),
    isReleased: {
      variant: 'standard',
      text: t('endAttendance'),
      onclick: (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        tabulation.open()
        config.baseOnClick()
      },
    },
    isNotReleased: {
      variant: 'ghost',
      text: t('endConsultation'),

      onclick: (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setCurrentAttendance(null)
        config.baseOnClick()
      },
    },
    css: {
      minWidth: '160px',
    },
  }

  return (
    <FloraButton
      size="small"
      variant={config[setIsReleased].variant}
      onClick={config[setIsReleased].onclick}
      css={config.css}
      {...props}
    >
      {config[setIsReleased].text}
    </FloraButton>
  )
}

export { EndAttendanceButton }
