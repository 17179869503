import { EventName, InteractionDetail, type EventTracker } from './events.types'

interface MLDEvents {
  clickCopyLink: () => void
}

const createMLDEvents = (tracker: EventTracker): MLDEvents => ({
  clickCopyLink: function () {
    tracker.track({
      event: 'analytics-event',
      event_parameters: {
        event_name: EventName.interactionAttendance,
        cd_interaction_detail: `${InteractionDetail.click}:copiar_mld`,
      },
    })
  },
})

export { createMLDEvents, type MLDEvents }
