const getUserInitials = (userName?: string): string => {
  if (!userName) {
    return '--'
  }

  const nameArray = userName.trim().split(' ')

  if (nameArray.length <= 1) {
    return userName.substring(0, 2).toUpperCase()
  }

  const firstLetter = nameArray[0].charAt(0)
  const secondLetter = nameArray[nameArray.length - 1].charAt(0)

  return firstLetter.concat(secondLetter).toUpperCase()
}

export { getUserInitials }
