import { PaginationData } from '@shared/types'

enum MovementType {
  Compra = 'SHOPPING',
  Bonificacao = 'BONUS',
  AjusteManual = 'MANUAL_ADJUSTMENT',
  Expiracao = 'EXPIRATION_POINTS',
  Resgate = 'REDEEM_POINTS',
  Estorno = 'REVERSAL_POINTS',
  Cancelado = 'PURCHASE_CANCELLED',
  CampanhaAniversario = 'BIRTHDAY_CAMPAIGN',
  UsoDeBeneficio = 'BENEFIT_USE',
  MissaoCumprida = 'MISSION_ACCOMPLISHED',
}

enum OperationType {
  Credit = 'CREDIT',
  Debit = 'DEBIT',
}

interface Movement {
  date: string
  amount: number
  movementType: MovementType
  operationType: OperationType
  expirationDate?: string
}

interface MovementMCMDTO {
  items: Movement[]
  pagination: PaginationData
}

type LastMovement = {
  amount: number
  operationType: OperationType
} | null

enum FilterTypes {
  All = 'ALL',
  Compra = 'SHOPPING',
  Bonificacao = 'BONUS',
  AjusteManual = 'MANUAL_ADJUSTMENT',
  Expiracao = 'EXPIRATION_POINTS',
  Resgate = 'REDEEM_POINTS',
  Estorno = 'REVERSAL_POINTS',
  Cancelado = 'PURCHASE_CANCELLED',
  CampanhaAniversario = 'BIRTHDAY_CAMPAIGN',
  UsoDeBeneficio = 'BENEFIT_USE',
  MissaoCumprida = 'MISSION_ACCOMPLISHED',
}

export { FilterTypes, LastMovement, Movement, MovementMCMDTO, MovementType, OperationType }
