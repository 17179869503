import { Toaster } from '@grupoboticario/flora-react'
import { useModalTabulation, type UseModalTabulation } from '@vdi/tabulation'

import { useAttendanceList } from '@register-attendance/hooks'
import { useCurrentAttendanceContext } from '@register-attendance/providers'
import { env } from '@shared/env'
import { useEvents } from '@shared/events'
import { useTranslation } from '@shared/i18n'
import { getCapitalizedText } from '@shared/utils'

interface UseTabulationInput {
  reName?: string
  reId?: string
  attendanceId?: string
}

const useTabulation = ({ reId, reName, attendanceId }: UseTabulationInput): UseModalTabulation => {
  const { t } = useTranslation('modalTabulation')
  const events = useEvents()
  const list = useAttendanceList()
  const { setCurrentAttendance } = useCurrentAttendanceContext()

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useModalTabulation({
    header: {
      title: t('title'),
    },
    footer: {
      cancelButtonLabel: t('footer.cancelButton'),
      confirmButtonLabel: t('footer.confirmButton'),
    },
    status: {
      closeOnSuccess: true,
      loadingTitle: t('status.loadingTitle'),
      loadingDescription: t('status.loadingDescription'),
      errorDescription: t('status.errorDescription'),
      onTabulateSuccess: ({ tabulation, reason, subreason }) => {
        const date = new Date()
        list.getAttendanceList()
        setCurrentAttendance(null)

        Toaster.notify({
          description: t('status.successDescription', {
            date: date.toLocaleDateString(),
            time: date.toLocaleTimeString(),
          }),
          origin: 'right-top',
          kind: 'success',
        })

        events.clickAttendanceTabulate({
          ...tabulation,
          reName,
          reId,
          reason,
          subreason,
        })
      },
    },
    form: {
      reCodeLabel: t('form.reCodeLabel'),
      reNameLabel: t('form.reNameLabel'),
      reCode: reId,
      reName: getCapitalizedText(reName, true),
      requiredMessage: t('form.requiredMessage'),
    },
    api: {
      baseUrl: env.API_BASE_URL,
      formFieldsEndpoint: '/attendances/fields',
      timeout: 15000,
      reasonsEndpoint: '/attendances/reasons',
      sendTabulationEndpoint: `/attendances/${attendanceId}/close`,
    },
  })
}

export { useTabulation }
