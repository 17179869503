import { Fragment } from 'react'

import { toLocaleDate } from '@grupoboticario/vdi-mfe-utils'

import { DataBlock, DataSection, GridMobile } from '@shared/components'
import { useTranslation } from '@shared/i18n'

import { useResellerData, useResellerDataView } from '../../../../hooks'
import { TopPurchasedProducts } from './top-purchased-products'

interface ShoppingHistoryProps {
  resellerId: string
}

const ShoppingHistory: React.FC<ShoppingHistoryProps> = ({ resellerId }) => {
  const { t } = useTranslation(['shoppingHistory', 'common'])

  const {
    error: resellerDataError,
    filled: resellerDataFilled,
    loading: resellerDataLoading,
    model: resellerDataModel,
    refetch: resellerDataRefetch,
  } = useResellerData()

  const {
    error: additionalDataError,
    filled: additionalDataFilled,
    loading: additionalDataLoading,
    model: additionalDataModel,
    refetch: additionalDataRefetch,
  } = useResellerDataView()

  const onRetry = (): void => {
    resellerDataRefetch()
    additionalDataRefetch()
  }

  return (
    <DataSection
      data-testid="profile-data-shopping"
      title={t('title')}
      loading={resellerDataLoading || additionalDataLoading}
      filled={resellerDataFilled || additionalDataFilled}
      error={resellerDataError && additionalDataError}
      data={{ ...resellerDataModel, ...additionalDataModel }}
      onRetry={onRetry}
      render={(data) => (
        <Fragment>
          <DataBlock
            title={t('isMultiBrandActive')}
            description={{
              text: data.isMultiBrandActive ? t('common:yes') : t('common:no'),
              status: data.isMultiBrandActive ? 'success' : 'error',
            }}
          />

          <GridMobile templateColumns="4fr 4fr" mobileTemplateColumns="4fr" gap="$1">
            <DataBlock
              title={t('firstOrderCycle')}
              description={{
                text: data.general.firstOrderCycle
                  ? t('cycle', { cycle: data.general.firstOrderCycle })
                  : '',
              }}
            />

            <DataBlock
              title={t('firstOrderDate')}
              description={{
                text: data.firstValideOrderDate ? toLocaleDate(data.firstValideOrderDate) : '',
              }}
            />
          </GridMobile>

          <TopPurchasedProducts resellerId={resellerId} />
        </Fragment>
      )}
    />
  )
}

export { ShoppingHistory }
