import pt from './pt-br.json'
import en from './en-us.json'
import es from './es.json'

export enum Language {
  ptBR = 'pt',
  enUS = 'en',
  es = 'es',
}
export const resources = {
  [Language.ptBR]: pt,
  [Language.enUS]: en,
  [Language.es]: es,
}

export { pt, en, es }
