import { Fragment, useContext, useEffect, useRef } from 'react'

import { DrawerContext } from '@shared/providers/drawer'

import { Body, ButtonClose, Content, Drawer, Header, Title } from './drawer-container.styles'

const DrawerContainer = (): JSX.Element => {
  const context = useContext(DrawerContext)
  const ref = useRef<HTMLDivElement | null>(null)

  const isOpen = context?.isOpen ?? false

  useEffect(() => {
    if (isOpen) {
      ref.current?.focus()
    }
  }, [isOpen])

  if (!context) {
    return <Fragment />
  }

  const { data, close } = context

  return (
    <Drawer open={isOpen} onOpenChange={close}>
      <Content side="right">
        <Header>
          <Title as="h5">{data?.title}</Title>
          <ButtonClose />
        </Header>

        <Body>{data?.content}</Body>
      </Content>
    </Drawer>
  )
}

export { DrawerContainer }
