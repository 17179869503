import { useCurrentAttendanceContext } from '@register-attendance/providers'
import { GtmTracker } from '@vdi/event-tracker'

import { AttendanceEvents, createAttendanceEvents } from './events.attendance'
import { BaseEvents, createBaseEvents } from './events.base'
import { createFinancialEvents, FinacialEvents } from './events.financial'
import { createGeneralEvents, GeneralEvents } from './events.general'
import { createMLDEvents, MLDEvents } from './events.mld'
import { createOrderEvents, OrderEvents } from './events.order'
import { createProfileEvents, ProfileEvents } from './events.profile'
import { createPromotionEvents, PromotionEvents } from './events.promotions'
import { EventTracker } from './events.types'

type UseEvents = BaseEvents &
  AttendanceEvents &
  FinacialEvents &
  ProfileEvents &
  GeneralEvents &
  MLDEvents &
  PromotionEvents &
  OrderEvents

const useEvents = (): UseEvents => {
  const { currentAttendance } = useCurrentAttendanceContext()
  const attendance = {
    attendanceId: currentAttendance?.attendanceId ?? '',
    reId: currentAttendance?.resellerId ?? '',
  }

  const tracker = GtmTracker as unknown as EventTracker

  return {
    ...createBaseEvents(tracker, attendance),
    ...createAttendanceEvents(tracker, attendance),
    ...createProfileEvents(tracker, attendance),
    ...createFinancialEvents(tracker, attendance),
    ...createGeneralEvents(tracker, attendance),
    ...createPromotionEvents(tracker, attendance),
    ...createMLDEvents(tracker),
    ...createOrderEvents(tracker),
  }
}

export { useEvents, type UseEvents }
