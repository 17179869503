import { MyLocationIcon } from '@grupoboticario/flora-react-icons'

import { useTranslation } from '@shared/i18n'

import { Container, Content, Description, Title } from './misson.styles'

interface MissionProps {
  description: string
  show: boolean
}

const Mission = ({ show, description }: MissionProps): JSX.Element | null => {
  const { t } = useTranslation('mission')

  if (!show) {
    return null
  }

  return (
    <Container>
      <MyLocationIcon size="24px" color="$nonInteractiveEmphasis" />

      <Content>
        <Title as="h4">{t('title')}</Title>
        <Description as="p">{description}</Description>
      </Content>
    </Container>
  )
}

export { Mission, MissionProps }
