import { keyframes, styled } from '@grupoboticario/flora-react'

const shimmer = keyframes({
  '100%': {
    transform: 'translate3d(100%, 0, 0)',
  },
})

const linearImage =
  'linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255,255,255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0))'

const StyledSkeleton = styled('span', {
  position: 'relative',
  display: 'inline-block',
  height: '$3',
  overflow: 'hidden',
  backgroundColor: '#dddbdd',

  '&::before': {
    content: '',
    position: 'absolute',
    inset: 0,
    backgroundColor: 'blue',
    background: linearImage,
    transform: 'translate3d(-100%, 0, 0)',
    animation: `${shimmer} 2s infinite`,
  },

  variants: {
    size: {
      small: {
        width: '65px',
        backgroundSize: '40% 65px',
      },
      medium: {
        width: '150px',
        backgroundSize: '40% 150px',
      },
      large: {
        width: '210px',
        backgroundSize: '40% 500px',
      },
      full: {
        width: '100%',
        backgroundSize: '40% 500px',
      },
    },
  },
})

export { StyledSkeleton }
