import { Flex } from '@grupoboticario/flora-react'

import { SPACE_BETWEEN_SECTION } from '../../reseller-data.styles'

import {
  OrderChannels,
  ResellerDigitalTools,
  ResellerFidelity,
  ResellerSpace,
  ShoppingHistory,
} from './components'

interface PropsDataProps {
  resellerId: string
}

const ProfileData = ({ resellerId }: PropsDataProps): JSX.Element => (
  <Flex direction="column" gap={SPACE_BETWEEN_SECTION}>
    <ResellerFidelity resellerId={resellerId} />
    <ResellerSpace />
    <OrderChannels />
    <ResellerDigitalTools />
    <ShoppingHistory resellerId={resellerId} />
  </Flex>
)

export { ProfileData }
