import React, { type PropsWithChildren } from 'react'

import { ContainerTable, StyledTable } from './table.styles'

const Table: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <ContainerTable>
      <StyledTable>{children}</StyledTable>
    </ContainerTable>
  )
}

export { Table }
