import { OrderStatusType } from '../orders.types'

interface OrderDetails {
  cycle: string
  date: string
  items: OrderProduct[]
  payments: OrderPayment[]
  number: string
  status: OrderStatusType
  statusDescription: string
  totalValue: number
  suggestedProfit: number
  suggestedProfitPercentage: number
  invoice: string
  orderStatement: OrderStatementDetails
  acquisitionMethod: string
}

interface OrderStatementDetails {
  url: string
}

interface OrderProduct {
  code: string
  name: string
  quantity: number
  totalValue: number
}

interface OrderPayment {
  type: string
  value: number
  dueDate: string
  fileLink: string
  identifier: string
  installments: number
  titles: Array<{
    paymentStatus: OrderDetailPaymentStatus
    titleStatus: OrderDetailTitleStatus
    titleStatusDescription: string
    paymentPlan: { name: string }
  }>
}

interface OrderCustomField {
  fieldDescription: string
  fieldName: string
  fieldValue: string
}

enum OrderDetailPaymentStatus {
  PENDENTE = 'PENDENTE',
  CANCELADO = 'CANCELADO',
  REALIZADO = 'REALIZADO',
}

enum OrderDetailTitleStatus {
  VENCIDO = 'VENCIDO',
  NAO_VENCIDO = 'NAO_VENCIDO',
}

export {
  OrderCustomField,
  OrderDetailPaymentStatus,
  OrderDetailTitleStatus,
  OrderProduct,
  type OrderDetails,
  type OrderPayment,
}
