import { useMaxWidth } from '@grupoboticario/flora-react'
import { MovementsTable } from '@shared/components/movements-table/movements-table'
import { useTranslation } from '@shared/i18n'
import { useFidelityData } from '../../../hooks'
import { mapMovementTypeToString } from '../../helpers/movement-type-to-string'
import { MCM_MOVEMENTS_PAGE_SIZE as PAGE_SIZE } from '../movements'
import { MovementAmount } from './movement-amount/movement-amount'

const MovementTable: React.FC = () => {
  const { t } = useTranslation(['resellerFidelityMovements', 'generalData'])

  const {
    movements: { loading, data },
  } = useFidelityData()

  const isSmallScreen = useMaxWidth('1420px')

  return (
    <MovementsTable
      data={data?.items || []}
      loading={loading}
      renderHeader={() => {
        return (
          <thead>
            <tr>
              <th scope="col">{isSmallScreen ? t('date') : t('movementDate')}</th>
              <th scope="col">{t('points')}</th>
              <th scope="col">{t('type')}</th>
              <th scope="col">{t('expirationDate')}</th>
            </tr>
          </thead>
        )
      }}
      renderRow={(item) => {
        const { date, expirationDate, movementType, amount, operationType } = item
        const formattedDate = new Date(date).toLocaleDateString()
        const formattedExpirationDate = expirationDate
          ? new Date(expirationDate).toLocaleDateString()
          : '---'
        return (
          <tr key={item.amount.toString().concat(date)}>
            <td>{formattedDate}</td>
            <td>
              <MovementAmount amount={amount} operationType={operationType} />
            </td>
            <td>{t(mapMovementTypeToString(movementType.toString()))}</td>
            <td>{formattedExpirationDate}</td>
          </tr>
        )
      }}
      pageSize={PAGE_SIZE}
    />
  )
}

export { MovementTable }
