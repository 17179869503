import { Tab as FloraTab, TabList as FloraTabList, styled } from '@grupoboticario/flora-react'

const TabList = styled(FloraTabList, {
  minWidth: '100%',

  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },

  '@mobile': {
    width: 'fit-content',

    scrollbarWidth: 'initial',
    '&::-webkit-scrollbar': {
      display: 'initial',
    },
  },
})

const Tab = styled(FloraTab, {
  '&:focus-visible': {
    outlineOffset: '-8px',
  },
})

export { Tab, TabList }
