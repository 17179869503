import React from 'react'

import { AdditionalInfo } from './additional-info'
import { Historical } from './historical'
import { OrderDetailProvider } from './order-detail.provider'
import { Payments } from './payments'
import { Gifts, Products } from './products'
import { Resume } from './resume'

interface OrderDetailProps {
  resellerId: string
  orderNumber: string
}

const OrderDetail: React.FC<OrderDetailProps> = ({ resellerId, orderNumber }) => (
  <OrderDetailProvider resellerId={resellerId} orderNumber={orderNumber}>
    <Resume />
    <Historical orderNumber={orderNumber} resellerId={resellerId} />
    <Payments />
    <AdditionalInfo />
    <Products />
    <Gifts orderNumber={orderNumber} resellerId={resellerId} />
  </OrderDetailProvider>
)

export { OrderDetail }
