import { FloraButton as Button, Flex } from '@grupoboticario/flora-react'
import { ArrowDownIcon, ArrowUpIcon } from '@grupoboticario/flora-react-icons'
import { DataBlock, DataSection } from '@shared/components'
import { useTranslation } from '@shared/i18n'
import React from 'react'
import { useFidelityData } from '../../hooks/use-fidelity-data'
import { MovementFilter } from './movement-filter'
import { MovementHeading } from './movement-heading'
import { MovementPagination } from './movement-pagination'
import { MovementTable } from './movement-table'

const Movements: React.FC = () => {
  const { t } = useTranslation('resellerFidelityMovements')
  const [isMovementListOpen, setIsMovementListOpen] = React.useState(false)

  const { movements } = useFidelityData()

  const { loading, data, error, unavailable, refetch } = movements

  const showMovementList = isMovementListOpen && Boolean(movements.data?.items)

  console.log(movements)

  return (
    <>
      {showMovementList && (
        <DataSection
          data-testid="profile-data-fidelity-movements"
          title={t('pointMovements')}
          fallbackHeight={228}
          onRetry={refetch}
          unavailable={unavailable}
          filled={true}
          loading={false}
          error={error}
          data={data}
          render={() => (
            <DataBlock
              content={
                <Flex direction="column" justify="stretch" gap="$4">
                  <Flex
                    direction="column"
                    justify="space-between"
                    css={{ '@desktop': { flexDirection: 'row' } }}
                    gap="$4"
                  >
                    <MovementHeading />
                    <MovementFilter />
                  </Flex>
                  <Flex css={{ minHeight: 370 }}>
                    <MovementTable />
                  </Flex>
                  <MovementPagination />
                </Flex>
              }
            />
          )}
        />
      )}
      <Flex justify="center">
        <Button
          icon={isMovementListOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
          hierarchy="secondary"
          size="small"
          has="iconLeft"
          disabled={error || loading || unavailable || data?.items.length === 0}
          onClick={() => {
            setIsMovementListOpen(!isMovementListOpen)
          }}
          isLoading={loading}
        >
          {isMovementListOpen ? t('hideMovements') : t('showMovements')}
        </Button>
      </Flex>
    </>
  )
}

export { Movements }
