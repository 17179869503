import React from 'react'

import {
  Flex,
  FloraTypographyProps,
  FloraTypography as Typography,
} from '@grupoboticario/flora-react'

interface CardDataProps {
  text: string
  value: string
  fontSize?: FloraTypographyProps['fontSize']
  fontWeight?: FloraTypographyProps['fontWeight']
}

const CardData: React.FC<CardDataProps> = ({ value, text, fontSize, fontWeight }) => {
  const size = fontSize ?? 'exceptionsAuxiliar'
  const weight = fontWeight ?? 'medium'

  return (
    <Flex gap="$2">
      <Typography
        fontSize={size}
        fontWeight={weight}
        css={{ flex: 1, color: '$nonInteractiveAuxiliar' }}
      >
        {text}
      </Typography>

      <Typography fontSize={size} fontWeight="medium" css={{ color: '$nonInteractiveAuxiliar' }}>
        {value}
      </Typography>
    </Flex>
  )
}

export { CardData }
