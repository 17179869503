import { useContext } from 'react'

import {
  ResellerDataViewContext,
  type ResellerDataViewContextProps,
  type ResellerDataViewDTO,
} from '@reseller-data/providers'

interface UseResellerDataView extends Omit<ResellerDataViewContextProps, 'data'> {
  model?: ResellerDataViewDTO
}

const useResellerDataView = (): UseResellerDataView => {
  const context = useContext(ResellerDataViewContext)
  if (!context) throw new Error('Reseller data view context not found')

  const { data, ...state } = context

  return {
    model: data,
    ...state,
  }
}

export { useResellerDataView }
