import React from 'react'

import { Flex } from '@grupoboticario/flora-react'
import { ExclamationMarkTriangleOutlineIcon } from '@grupoboticario/flora-react-icons'

import { ErrorState } from '@shared/components'
import { useTranslation } from '@shared/i18n'

import { RetryTimer, RetryTimerProps } from './retry-timer'

const UnavailableState: React.FC<RetryTimerProps> = ({ retryDelayMs }) => {
  const { t } = useTranslation('unavailableState')

  return (
    <ErrorState
      title={t('title')}
      description={t('description')}
      icon={<ExclamationMarkTriangleOutlineIcon color="$error-standard" />}
    >
      <Flex
        data-testid="unavailable-state"
        direction="column"
        justify="center"
        align="center"
        gap="$2"
      >
        <RetryTimer retryDelayMs={retryDelayMs} />
      </Flex>
    </ErrorState>
  )
}

export { UnavailableState }
