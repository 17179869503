type CCRMovementsDTO = CCRMovement[]

interface CCRMovement {
  date: string
  amount: number
  situation: SituationType
  operationType: OperationType
  origin: string
  originDescription?: string
  useDate?: string
  useDescription?: string
}

enum SituationType {
  Cancelled = 'CANCELADO',
  Active = 'ATIVO',
  Inactive = 'INATIVO',
}

enum OperationType {
  Credit = 'Crédito',
  Debit = 'Débito',
}

export { CCRMovement, CCRMovementsDTO, OperationType, SituationType }
