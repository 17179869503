import { toCurrency } from '@grupoboticario/vdi-mfe-utils'
import { DataBlock, DataSection, GridMobile } from '@shared/components'
import { useTranslation } from '@shared/i18n'

import { Fragment } from 'react/jsx-runtime'
import { useFidelityData } from '../hooks/use-fidelity-data'

const MCBFidelity: React.FC<{ resellerId: string }> = () => {
  const { t } = useTranslation('resellerFidelity')

  const { fidelity } = useFidelityData()

  const { data, loading, error, filled, unavailable, refetch } = fidelity

  const getTierLabel = (label: string, tier: string): string => `${label} - ${tier}`

  const getTierValue = (nextTierValue: number): string => {
    if (nextTierValue <= 0) {
      return t('nextTierExceeded')
    }
    return toCurrency(nextTierValue)
  }

  return (
    <DataSection
      data-testid="profile-data-fidelity"
      title={t('title')}
      fallbackHeight={228}
      onRetry={refetch}
      unavailable={unavailable}
      loading={loading}
      filled={filled}
      error={error}
      data={data}
      render={({ ...fidelity }) => (
        <Fragment>
          <GridMobile gap="$1" templateColumns="4fr 4fr" mobileTemplateColumns="4fr">
            <DataBlock
              title={t('currentTier')}
              tooltip={{ text: t('tierChangingTooltip') }}
              description={{
                text: getTierLabel(fidelity.currentTier.label, fidelity.currentTier.value),
              }}
            />
            <DataBlock
              title={t('nextTier')}
              description={{
                text: getTierLabel(fidelity.nextTier.label, fidelity.nextTier.value),
              }}
            />
          </GridMobile>
          <DataBlock
            title={t('accumulatedValue')}
            description={{ text: toCurrency(fidelity.accumulatedValue) }}
          />
          <DataBlock
            title={t('valueToNextTier')}
            description={{ text: getTierValue(fidelity.valueNextTier) }}
          />
        </Fragment>
      )}
    />
  )
}

export { MCBFidelity }
