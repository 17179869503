import {
  Heading,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  styled,
} from '@grupoboticario/flora-react'

const StyledModalContent = styled(ModalContent, {
  padding: '$6 $4',
  variants: {
    size: {
      small: {
        minWidth: '462px',
        maxWidth: '462px',
      },
      standard: {},
      large: {},
    },
  },
})

const ModalTitle = styled(Heading, {
  fontSize: ' $subtitleDesk',
  lineHeight: '$short',
  fontWeight: '$medium',
})

const StyledModalHeader = styled(ModalHeader, {
  position: 'relative',
  padding: '0 0 $6',

  '&::after': {
    content: '',
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    height: '1px',
    backgroundColor: '$nonInteractivePredominant',
  },
})

const StyledModalBody = styled(ModalBody, {
  minHeight: '100px',
  padding: '$4 0',
})

const StyledModalFooter = styled(ModalFooter, {
  position: 'relative',
  padding: '$4 0 0',

  '&::before': {
    content: '',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '1px',
    backgroundColor: '$nonInteractivePredominant',
  },

  'button, a': {
    width: '50%',

    '&:only-child': {
      width: '100%',
    },
  },
})

export { ModalTitle, StyledModalBody, StyledModalContent, StyledModalFooter, StyledModalHeader }
