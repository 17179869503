import { Flex, FloraTypography as Typography } from '@grupoboticario/flora-react'
import { useTranslation } from '@shared/i18n'
import { useFidelityData } from '../../../hooks'

const MovementHeading: React.FC = () => {
  const { t } = useTranslation('resellerFidelityMovements')

  const {
    movements: { data, loading },
    fidelity,
  } = useFidelityData()

  const lastUpdateDate = fidelity.data?.mcm?.updatedAt ?? ''
  const amount = data?.pagination.totalItems

  return (
    <Flex direction="column" justify="center">
      <Typography
        fontSize="bodyLargeStandard"
        fontWeight="medium"
        css={{
          color: '$nonInteractivePredominant',
        }}
      >
        {loading ? t('loadingData') : t('movementsFound', { amount })}
      </Typography>
      <Typography
        fontSize="exceptionsAuxiliar"
        fontWeight="medium"
        css={{
          color: '$nonInteractiveAuxiliar',
          display: 'none',
          '@mobile': {
            display: 'block',
          },
        }}
      >
        {t('lastUpdateAt', {
          date: new Date(lastUpdateDate).toLocaleDateString(),
          time: new Date(lastUpdateDate).toLocaleTimeString(),
        })}
      </Typography>
    </Flex>
  )
}

export { MovementHeading }
