import { styled } from '@grupoboticario/flora'
import { Flex } from '@grupoboticario/flora-react'
import { OperationType } from '../../../mcm-fidelity.types'

const OperationTypeBadge = styled('div', {
  backgroundColor: '$backgroundSecondary',
  display: 'inline-block',
  width: '5px',
  height: '$5',
  borderRadius: '$medium',

  variants: {
    operationType: {
      [OperationType.Credit]: {
        backgroundColor: '$statusSuccessNonInteractiveEmphasis',
      },
      [OperationType.Debit]: {
        backgroundColor: '$statusErrorNonInteractiveEmphasis',
      },
    },
  },
})

const Container = styled(Flex, {
  alignItems: 'center',
  gap: '$1',
  '@mobile': {
    gap: '$3',
  },
})

export { Container, OperationTypeBadge }
