import { Flex, Image } from '@grupoboticario/flora-react'
import { SquareArrowRightUpIcon } from '@grupoboticario/flora-react-icons'

import { useResellerData, useResellerDataTabControl } from '@reseller-data/hooks'
import { type ResellerClub } from '@reseller-data/providers'
import { ResellerDataTab } from '@reseller-data/reseller-data.types'

import { LoadingDataBlock } from '@shared/components'
import { Description } from '@shared/components/data-block'
import { useIsMobile } from '@shared/hooks'
import { useTranslation } from '@shared/i18n'
import { ClickableOverlay, Content, FidelityTabButton } from './fidelity.styles'

interface FidelityProps {
  club?: ResellerClub
}

const Fidelity = ({ club }: FidelityProps): JSX.Element => {
  const { t } = useTranslation('fidelity')
  const isMobile = useIsMobile()
  const { setActiveTab } = useResellerDataTabControl()

  const {
    fidelity,
    fidelity: { data: fidelityData },
  } = useResellerData()

  const renderFidelityValue = club?.value ? club.value : t('withoutSegmentation')

  const onClickGoToFidelityButton = (): void => {
    setActiveTab(ResellerDataTab.PROFILE)
  }

  const isMcm = fidelityData?.mcm

  return (
    <LoadingDataBlock
      isLoading={fidelity.loading}
      error={false}
      notFound=""
      title={isMcm ? t('titleMcm') : t('title')}
      content={
        <Content>
          {isMcm ? (
            <>
              <Flex align="center" gap="$4">
                <Description>{fidelityData.mcm?.tier?.name}</Description>

                <FidelityTabButton
                  has="iconOnly"
                  icon={<SquareArrowRightUpIcon />}
                  hierarchy="tertiary"
                  size="small"
                  onClick={onClickGoToFidelityButton}
                  aria-label={t('redirectToFidelityButton')}
                />
              </Flex>
              {fidelityData.mcm?.tier.iconUrl && (
                <Image
                  src={fidelityData.mcm?.tier.iconUrl}
                  alt={t('fidelityIconAlt', { tier: fidelityData.mcm?.tier.name })}
                  size={40}
                  css={{ position: 'absolute', right: 0, top: '-16px' }}
                />
              )}
              {isMobile && (
                <ClickableOverlay
                  onClick={onClickGoToFidelityButton}
                  role="button"
                  aria-label={t('redirectOverlayLabel')}
                />
              )}
            </>
          ) : (
            <>
              <Flex align="center" gap="$4">
                <Description>{renderFidelityValue}</Description>
                <FidelityTabButton
                  has="iconOnly"
                  icon={<SquareArrowRightUpIcon />}
                  hierarchy="tertiary"
                  size="small"
                  onClick={onClickGoToFidelityButton}
                  aria-label={t('redirectToFidelityButton')}
                />
              </Flex>
              {club?.imageUrl && (
                <Image
                  src={club?.imageUrl}
                  alt={t('fidelityIconAlt', { tier: club?.value })}
                  size={40}
                  css={{ position: 'absolute', right: 0, top: '-16px' }}
                />
              )}
            </>
          )}
        </Content>
      }
    />
  )
}

export { Fidelity, type FidelityProps }
