import { Fragment } from 'react'

import { FloraButton as Button, FloraTypography as Typography } from '@grupoboticario/flora-react'

import { useTranslation } from '@shared/i18n'

import { DataBlock, type DataBlockProps } from '../data-block'
import { Skeleton } from '../loading-skeleton'
import { Container } from './loading-data-block.styles'

type LoadingDataBlockProps = DataBlockProps & {
  isLoading: boolean
  error: boolean
  notFound: string
  onRetry?: () => void
}

const LoadingDataBlock = ({
  isLoading,
  error,
  notFound,
  onRetry,
  ...props
}: LoadingDataBlockProps): JSX.Element => {
  const { t } = useTranslation(['loadingDataBlock', 'common'])

  const loadingState = (
    <Container
      direction="column"
      align="space-between"
      justify="center"
      gap="$2"
      role="progressbar"
    >
      <Skeleton size="small" />
      <Skeleton size="medium" />
    </Container>
  )

  const errorState = (
    <Container justify="space-evenly" align="center" gap="$2">
      <Typography>{t('fetchFailed')}</Typography>

      <Button size="small" onClick={onRetry} hierarchy="secondary">
        {t('common:tryAgain')}
      </Button>
    </Container>
  )

  const notFoundState = <Typography>{notFound}</Typography>

  const renderContent = (): JSX.Element => {
    if (isLoading) {
      return loadingState
    }

    if (error) {
      return errorState
    }

    if (notFound) {
      return notFoundState
    }

    return <Fragment />
  }

  const dataBlockProps = {
    ...props,
    renderContentOnly: isLoading || error,
    content: renderContent(),
  }

  return <DataBlock {...dataBlockProps} />
}

export { LoadingDataBlock }
