import { Fragment } from 'react'

import { toPhoneNumber } from '@grupoboticario/vdi-mfe-utils'

import { type ResellerContact } from '@reseller-data/providers'
import { DataBlock, DataSection, GridMobile } from '@shared/components'
import { TabName } from '@shared/events'
import { useTranslation } from '@shared/i18n'

interface ContactInfoProps {
  contact?: ResellerContact
}

const ContactInfo = ({ contact }: ContactInfoProps): JSX.Element => {
  const { t } = useTranslation('contactInfo')

  return (
    <DataSection
      filled
      title={t('title')}
      data={contact}
      render={(data) => (
        <Fragment>
          <GridMobile templateColumns="4fr 4fr" mobileTemplateColumns="4fr" gap="$1">
            <DataBlock
              title={t('dataBlock.cellphone')}
              description={{ text: toPhoneNumber(data.cellPhone) }}
              sectionName={t('title')}
              tabName={TabName.general}
              canCopy
            />

            <DataBlock
              title={t('dataBlock.residentialPhone')}
              description={{ text: toPhoneNumber(data.phone) }}
              sectionName={t('title')}
              tabName={TabName.general}
              canCopy
            />
          </GridMobile>

          <DataBlock
            title={t('dataBlock.notesPhone')}
            description={{ text: toPhoneNumber(data.messagePhone), capitalize: true }}
            sectionName={t('title')}
            tabName={TabName.general}
            canCopy
          />

          <DataBlock
            title={t('dataBlock.email')}
            description={{ text: data.email }}
            sectionName={t('title')}
            tabName={TabName.general}
            canCopy
          />
        </Fragment>
      )}
    />
  )
}

export { ContactInfo }
