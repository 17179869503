import { useCallback } from 'react'

const useDebounce = <T extends (...args: unknown[]) => void>(fn: T, delay: number): Fn<T> =>
  useCallback(debounce(fn, delay), [])

const debounce = <T extends (...args: unknown[]) => void>(fn: T, delay: number): Fn<T> => {
  let timer: NodeJS.Timeout

  return function (...args: unknown[]) {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => fn(...args), delay)
  } as Fn<T>
}

type Fn<T = unknown> = T extends (...p: Array<infer P>) => infer R ? (...args: P[]) => R : never

export { useDebounce }
