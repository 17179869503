import { FloraButton, TabPanel as FloraTabPanel, styled } from '@grupoboticario/flora-react'

const StyledTabPanel = styled(FloraTabPanel, {
  flex: 1,
  padding: '$4',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',

  '> div:first-child': {
    padding: '0',
  },

  '@mobile': {
    padding: '$4 0 $8',
    overflowY: 'initial',

    '> div:first-child': {
      padding: '$2 0',
    },
  },
})

const ScrollToTopButton = styled(FloraButton, {
  position: 'fixed',
  right: '$4',
  bottom: '$4',
})

export { ScrollToTopButton, StyledTabPanel }
