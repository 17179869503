import { styled } from '@grupoboticario/flora-react'

const Container = styled('div', {
  width: '$5',
  height: '$5',

  span: {
    position: 'inherit',
    overflow: 'hidden',
  },

  svg: {
    width: '$5',
    height: '$5',
  },
})

export { Container }
