import React, { useMemo } from 'react'

import { FloraButton as Button } from '@grupoboticario/flora-react'
import { ChevronRightIcon } from '@grupoboticario/flora-react-icons'
import { toCurrency, toLocaleDate } from '@grupoboticario/vdi-mfe-utils'

import {
  Card,
  CardData,
  CardDivider,
  CardHeader,
  CardList,
  CardListProps,
} from '@shared/components'

import { useEvents } from '@shared/events'
import { useTranslation } from '@shared/i18n'

import { useOrderStatusTag } from '../order-history.hooks'
import { Order } from '../orders-history.types'

interface OrderListProps extends Omit<CardListProps, 'shorMoreLabel'> {
  onOrderClick: (orderNumber: string) => void
  list: Order[]
}

const OrderList: React.FC<OrderListProps> = ({ list, onOrderClick, empty, ...cardListProps }) => {
  const { t } = useTranslation('orders', { keyPrefix: 'orderHistory.list' })

  const cardOrders = useMemo(
    () => list.map((o) => <OrderItem key={o.number} order={o} onOrderClick={onOrderClick} />),
    [list, onOrderClick],
  )

  return (
    <CardList
      {...cardListProps}
      empty={empty}
      shorMoreLabel={t('showMore')}
      emptyTitle={t('emptyTitle')}
      emptyDescription={t('emptyDescription')}
    >
      {cardOrders}
    </CardList>
  )
}

interface OrderItemProps {
  onOrderClick: (orderNumber: string) => void
  order: Order
}

const OrderItem: React.FC<OrderItemProps> = ({ order, onOrderClick }) => {
  const { t } = useTranslation('orders', { keyPrefix: 'orderHistory.list' })
  const tag = useOrderStatusTag(order)
  const event = useEvents()

  const cycle = String(order.cycle).slice(4)

  const onClickDetails = (): void => {
    event.clickOrderButton('detalhe-pedido')
    onOrderClick(order.number)
  }

  return (
    <Card key={order.number}>
      <CardHeader
        title={t('orderNumber', { number: order.number })}
        tags={[tag]}
        actions={
          <Button
            size="small"
            hierarchy="secondary"
            has="iconOnly"
            icon={<ChevronRightIcon color="$actionableDefault" />}
            onClick={onClickDetails}
          />
        }
      />

      <CardDivider />
      <CardData text={t('totalValue')} value={toCurrency(order.totalValue)} />
      <CardData text={t('madeIn')} value={t('cycle', { date: toLocaleDate(order.date), cycle })} />
    </Card>
  )
}

export { OrderList }
