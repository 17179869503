import { styled } from '@grupoboticario/flora-react'

const Container = styled('span', {
  position: 'absolute',
  top: '$5',
  left: '30px',
  display: 'inline-flex',
  flexWrap: 'nowrap',
  width: 'auto',
  height: '$12',
  borderRadius: '$small',
  color: '$nonInteractiveAltPredominant',
  fontSize: '$auxiliar',
  lineHeight: '$short',
  padding: '$4 $4 $4 $10',
  whiteSpace: 'nowrap',
  zIndex: '$docked',

  variants: {
    type: {
      success: {
        backgroundColor: '$statusSuccessBackgroundAlt',
      },
      error: {
        backgroundColor: '$statusErrorBackgroundAlt',
      },
    },

    show: {
      true: {
        opacity: 1,
        pointerEvents: 'auto',
      },
      false: {
        opacity: 0,
      },
    },
  },

  '> span': {
    position: 'absolute',
    top: '$4',
    left: '$4',
    width: '$4',
    height: '$4',
  },
})

export { Container }
