import { createContext, useContext, type ReactNode } from 'react'
import { type API } from './api.types'

const Context = createContext<API | null>(null)

interface ApiProviderProps {
  api: API
  children: ReactNode
}

const ApiProvider = ({ children, api }: ApiProviderProps): JSX.Element => (
  <Context.Provider value={api}>{children}</Context.Provider>
)

const useApi = (): API => {
  const context = useContext(Context)
  if (!context) throw new Error('Api provider not found')
  return context
}

export { ApiProvider, useApi }
