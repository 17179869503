import { styled } from '@grupoboticario/flora-react'

const Section = styled('section', {
  width: '100%',

  '> h3:first-child': {
    fontWeight: '$medium',
    padding: '0 0 $3',
    margin: 0,

    '@mobile': {
      padding: '0 $4 $4 $4',
    },
  },
})

const DataContainer = styled('div', {
  display: 'grid',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'stretch',
  gap: '$1',

  variants: {
    state: {
      true: {
        background: '$backgroundPrimary',
        borderRadius: '$medium',
        alignItems: 'center',
      },
    },
  },

  minHeight: '$$height',
})

export { DataContainer, Section }
