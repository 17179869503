import { Flex } from '@grupoboticario/flora-react'
import { PencilIcon } from '@grupoboticario/flora-react-icons'

import { DataBlock, DataSection } from '@shared/components'
import { useEvents } from '@shared/events'
import { useDrawer, useIsMobile } from '@shared/hooks'
import { useTranslation } from '@shared/i18n'

import { AdditionalInfoDrawer } from './additional-info-drawer'
import { AdditionalInfoContainer, EditButton } from './additional-info.styles'

interface AdditionalInfoProps {
  additionalInformation?: string
  onUpdated?: () => void
}

const AdditionalInfo: React.FC<AdditionalInfoProps> = ({ additionalInformation, onUpdated }) => {
  const { t } = useTranslation('additionalInfo')
  const events = useEvents()
  const additionalInfoText = t('title')
  const isMobile = useIsMobile()

  const handleCloseDrawer = (): void => {
    close()
  }

  const { open, close } = useDrawer({
    title: t(additionalInfoText),
    content: (
      <AdditionalInfoDrawer
        defaultAdditionalInformation={additionalInformation}
        onClose={handleCloseDrawer}
        onUpdated={onUpdated ?? (() => {})}
      />
    ),
  })

  const handleEditButtonClicked = (): void => {
    open()
    events.clickEditAdditionalInfo()
  }

  return (
    <DataSection
      filled
      data={additionalInformation}
      render={(text) => (
        <AdditionalInfoContainer>
          <Flex direction="row-reverse">
            <EditButton
              hierarchy="secondary"
              has="iconLeft"
              icon={<PencilIcon size="18px" />}
              size={isMobile ? 'medium' : 'small'}
              onClick={handleEditButtonClicked}
            >
              {isMobile ? t('edit') : t('editAdditionalInfo')}
            </EditButton>
          </Flex>

          <DataBlock title={t('dataBlock')} description={{ text }} />
        </AdditionalInfoContainer>
      )}
    />
  )
}

export { AdditionalInfo }
