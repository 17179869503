import { styled } from '@grupoboticario/flora'
import { Flex } from '@grupoboticario/flora-react'
import { useTranslation } from '@shared/i18n'
import { SituationType } from '../ccr-movements.types'

interface SituationProps {
  situation: SituationType
}

const Situation: React.FC<SituationProps> = ({ situation }) => {
  const { t } = useTranslation('resellerCCRMovements')
  return (
    <Flex gap="$1" css={{ alignItems: 'center', '@mobile': { gap: '$3' } }}>
      <SituationTypeBadge situationType={situation} />
      {t(SituationTypeToText(situation))}
    </Flex>
  )
}

export const SituationTypeToText = (situation: SituationType): string => {
  switch (situation) {
    case SituationType.Active:
      return 'active'
    case SituationType.Cancelled:
      return 'cancelled'
    case SituationType.Inactive:
      return 'inactive'
    default:
      return '---'
  }
}

const SituationTypeBadge = styled('div', {
  backgroundColor: '$backgroundSecondary',
  display: 'inline-block',
  width: '5px',
  height: '$5',
  minWidth: '5px',
  borderRadius: '$medium',

  variants: {
    situationType: {
      [SituationType.Active]: {
        backgroundColor: '$statusSuccessNonInteractiveEmphasis',
      },
      [SituationType.Cancelled]: {
        backgroundColor: '$statusErrorNonInteractiveEmphasis',
      },
      [SituationType.Inactive]: {
        backgroundColor: '$statusErrorNonInteractiveEmphasis',
      },
    },
  },
})

export { Situation }
