import { useEffect, useRef, useState } from 'react'

import { Flex, FloraTextarea, Toaster } from '@grupoboticario/flora-react'

import { useApi } from '@shared/api'
import { useTranslation } from '@shared/i18n'

import { useCurrentAttendanceContext } from '@register-attendance/providers'
import { Button, Form, FormActions } from './additional-info.styles'

interface AdditionalInfoDrawerProps {
  onClose: () => void
  defaultAdditionalInformation?: string
  onUpdated: () => void
}

const CHARACTER_LIMIT = 180

const AdditionalInfoDrawer: React.FC<AdditionalInfoDrawerProps> = ({
  defaultAdditionalInformation,
  onClose,
  onUpdated,
}) => {
  const { currentAttendance } = useCurrentAttendanceContext()
  const { t } = useTranslation('additionalInfoDrawer')

  const inputElement = useRef<(HTMLInputElement & HTMLTextAreaElement) | null>(null)

  const [inputField, setInputField] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { updateResellerAddInfo } = useApi()

  useEffect(() => {
    inputElement.current?.focus()
    setInputField(defaultAdditionalInformation ?? '')
  }, [])

  const handleSubmitForm = async (event: Event): Promise<void> => {
    event.preventDefault()

    setIsLoading(true)
    const response = await updateResellerAddInfo({
      resellerId: currentAttendance?.resellerId ?? '',
      info: inputField,
    })
    setIsLoading(false)

    if (response.ok) {
      Toaster.notify({
        description: t('saveAdditionalInformationSucceeded'),
        origin: 'right-top',
        kind: 'success',
        closeWhenClick: true,
      })

      onUpdated()
      onClose()
    } else {
      Toaster.notify({
        description: t('saveAdditionalInformationFailed'),
        origin: 'right-top',
        kind: 'error',
        closeWhenClick: true,
      })
    }
  }

  const handleChangeTextInput = (event: React.FormEvent<HTMLTextAreaElement>): void => {
    setInputField(event?.currentTarget?.value)
  }

  const hasReachedCharacterLimit = inputField.length >= CHARACTER_LIMIT
  const helperText = hasReachedCharacterLimit ? t('reachedCharacterLimit') : t('inputOptionalText')

  return (
    <Form as="form" onSubmit={handleSubmitForm}>
      <Flex direction="column" gap="$2">
        <FloraTextarea
          id="additional-info-input"
          as="textarea"
          label={t('inputLabelText')}
          helperText={helperText}
          aria-placeholder={t('inputOptionalText')}
          css={{
            height: '200px',
            resize: 'none',
          }}
          value={inputField}
          onChange={handleChangeTextInput}
          disabled={isLoading}
          maxLength={CHARACTER_LIMIT}
          ref={inputElement}
        />

        <FormActions gap="$4">
          <Button
            hierarchy="secondary"
            onClick={onClose}
            disabled={isLoading}
            css={{ width: '100%' }}
          >
            {t('cancel')}
          </Button>

          <Button type="submit" isLoading={isLoading} disabled={isLoading} css={{ width: '100%' }}>
            {t('saveChanges')}
          </Button>
        </FormActions>
      </Flex>
    </Form>
  )
}

export { AdditionalInfoDrawer }
