import { slugify } from '@shared/utils'
import {
  ActionType,
  CategoryType,
  EventName,
  InteractionDetail,
  type EventBaseData,
  type EventTracker,
} from './events.types'

interface BaseEvents {
  interactionModal: (modalName: string, buttonName: string) => void
  clickCopyData: (dataName: string, sectionName: string, tabName: string) => void
  clickTabData: (section: string) => void
  clickRetry: (section: string, errorMessage?: string) => void
}

const createBaseEvents = (tracker: EventTracker, data: EventBaseData): BaseEvents => ({
  clickRetry(section, errorMessage) {
    tracker.track({
      event: 'analytics-event',
      event_parameters: {
        event_name: EventName.interactionAttendance,
        cd_interaction_detail: `${InteractionDetail.click}:tentar-novamente`,
        cd_codigo_atendimento: data.attendanceId,
        cd_codigo_representante: data.reId,
        cd_error_message: errorMessage,
        cd_section: section,
      },
    })
  },
  interactionModal: function (modalName: string, buttonName: string) {
    tracker.track({
      event: 'event',
      action: ActionType.interactionModal.replace('{{name}}', modalName),
      category: CategoryType.base,
      label: buttonName,
    })

    const buttonNameSlugfied = slugify(buttonName)

    tracker.track({
      event: 'analytics-event',
      event_parameters: {
        event_name: EventName.interactionAttendance,
        cd_interaction_detail: `${InteractionDetail.click}:${buttonNameSlugfied}`,
        cd_codigo_atendimento: data.attendanceId,
        cd_codigo_representante: data.reId,
        cd_section: modalName,
      },
    })
  },
  clickCopyData: function (dataName: string, sectionName: string, tabName: string) {
    tracker.track({
      event: 'event',
      action: ActionType.clickIcon,
      category: CategoryType.base,
      label: `copiar:${dataName}`,
    })

    const dataNameSlugfied = slugify(dataName)
    const sectionNameSlugfied = slugify(sectionName)
    const tabNameSlugfied = slugify(tabName)

    tracker.track({
      event: 'analytics-event',
      event_parameters: {
        event_name: EventName.interactionAttendance,
        cd_interaction_detail: `${InteractionDetail.click}:copiar-${dataNameSlugfied}`,
        cd_codigo_atendimento: data.attendanceId,
        cd_codigo_representante: data.reId,
        cd_section: `${tabNameSlugfied}:${sectionNameSlugfied}`,
      },
    })
  },
  clickTabData(section) {
    tracker.track({
      event: 'analytics-event',
      event_parameters: {
        event_name: EventName.interactionAttendance,
        cd_interaction_detail: `${InteractionDetail.view}:${section}`,
        cd_codigo_atendimento: data.attendanceId,
        cd_codigo_representante: data.reId,
      },
    })
  },
})

export { createBaseEvents, type BaseEvents }
