import { Fragment, useCallback } from 'react'

import { Spinner } from '@grupoboticario/flora-react'
import { TrayArrowDownIcon } from '@grupoboticario/flora-react-icons'

import { useApiData } from '@shared/api'
import { useEvents } from '@shared/events'
import { useTranslation } from '@shared/i18n'
import { downloadFile } from '@shared/utils'

import { Button } from './reseller-bills-download-button.styles'

import {
  type ResellerBillsDownloadButtonProps,
  type ResellerPaymentTitleDTO,
} from './reseller-bills-download-button.types'

const ResellerBillsDownloadButton = ({
  paymentType,
  titleId,
}: ResellerBillsDownloadButtonProps): JSX.Element => {
  const { t } = useTranslation('resellerBillsDownloadButton')
  const events = useEvents()

  const getApiParams = useCallback(() => ({ titleId }), [titleId])

  const { getData, loading } = useApiData<ResellerPaymentTitleDTO>({
    dataSource: 'getResellerTitleLink',
    fetchOnMount: false,
    getApiParams,
  })

  const onButtonClick = async (): Promise<void> => {
    const result = await getData()
    events.clickBilletDownload(titleId)

    if (result.ok && result.value) {
      const fileLink = result.value.fileLink
      downloadFile(fileLink)
    }
  }

  if (paymentType.toLowerCase() !== 'boleto') {
    return <Fragment />
  }

  const iconDownload = <TrayArrowDownIcon size="16" />

  const spinner = <Spinner role="status" aria-label={t('ariaLabelDownloadFile')} />

  const isloading = loading ? spinner : t('downloadFile')

  return (
    <Button
      hierarchy="secondary"
      has="iconLeft"
      icon={loading ? null : iconDownload}
      size="small"
      onClick={onButtonClick}
      data-testid="reseller-bills-download-button"
    >
      {isloading}
    </Button>
  )
}

export { ResellerBillsDownloadButton }
