import { FloraButton, styled } from '@grupoboticario/flora-react'

const Button = styled(FloraButton, {
  borderRadius: '$sharp $small $small $sharp',
  outlineColor: 'transparent',
  transitionProperty: 'outline-color, background-color',
  transitionDuration: '0.2s',
  transitionTimingFunction: 'ease-in-out',

  '&:focus-visible': {
    borderRadius: '$medium',
    outlineOffset: '-4px',
  },
})

export { Button }
