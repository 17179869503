import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
  type PropsWithChildren,
} from 'react'

import { useApiData } from '@shared/api'

import { FilterTypes, LastMovement, MovementMCMDTO } from '../../mcm-fidelity/mcm-fidelity.types'
import { FidelityDataContextProps } from './fidelity-data-provider.types'

const FidelityDataContext = createContext<FidelityDataContextProps | null>(null)

interface FidelityDataProviderProps extends PropsWithChildren {
  resellerId: string
}

const ITEMS_PER_PAGE = 5

const FidelityDataProvider: React.FC<FidelityDataProviderProps> = ({ resellerId, children }) => {
  const [movementFilter, setMovementFilter] = useState<FilterTypes>(FilterTypes.All)
  const [movementsCurrentPage, setMovementsCurrentPage] = useState<number>(1)
  const [lastMovement, setLastMovement] = useState<LastMovement>(null)

  useEffect(() => {
    setMovementsCurrentPage(1)
  }, [movementFilter])

  const getFidelityMovementApiParams = useCallback(
    () => ({
      resellerId,
      filter: movementFilter === FilterTypes.All ? '' : movementFilter,
      page: movementsCurrentPage,
      limit: ITEMS_PER_PAGE,
    }),
    [resellerId, movementFilter, movementsCurrentPage],
  )

  const movementData = useApiData<MovementMCMDTO>({
    dataSource: 'getResellerFidelityMovement',
    getApiParams: getFidelityMovementApiParams,
    pollingOnError: true,
  })

  useEffect(() => {
    if (!lastMovement) {
      if (movementsCurrentPage === 1 && movementFilter === FilterTypes.All) {
        if (movementData.data?.items[0]) {
          setLastMovement({
            amount: movementData.data?.items[0]?.amount,
            operationType: movementData.data?.items[0]?.operationType,
          })
        }
      }
    }
  }, [movementData])

  const value = React.useMemo(
    () => ({
      movements: {
        ...movementData,
        filter: {
          type: movementFilter,
          onChange: setMovementFilter,
        },
        pagination: {
          currentPage: movementsCurrentPage,
          setPage: setMovementsCurrentPage,
          pageSize: ITEMS_PER_PAGE,
        },
      },
      lastMovement: lastMovement,
    }),
    [movementsCurrentPage, movementFilter, setMovementFilter, lastMovement, movementData],
  )

  return <FidelityDataContext.Provider value={value}>{children}</FidelityDataContext.Provider>
}

export { FidelityDataContext, FidelityDataProvider, type FidelityDataContextProps }
