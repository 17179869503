import { ArrowRightIcon } from '@grupoboticario/flora-react-icons'

import { Button } from './submit-button.styles'

interface SubmitButtonProps {
  isLoading: boolean
  ariaLabel: string
}

const SubmitButton = ({ isLoading, ariaLabel }: SubmitButtonProps): JSX.Element => (
  <Button
    hierarchy="tertiary"
    type="submit"
    aria-label={ariaLabel}
    role="button"
    has="iconOnly"
    icon={<ArrowRightIcon size="25" />}
    disabled={isLoading}
  />
)

export { SubmitButton }
