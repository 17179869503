import { Grid, type GridProps } from '@grupoboticario/flora-react'
import { useIsMobile } from '@shared/hooks'

type GridMobileProps = GridProps & { mobileTemplateColumns: GridProps['templateColumns'] }

const GridMobile = (props: GridMobileProps): JSX.Element => {
  const isMobile = useIsMobile()

  const { templateColumns, mobileTemplateColumns, ...restProps } = props

  const template = isMobile ? mobileTemplateColumns : templateColumns

  return (
    <Grid templateColumns={template} {...restProps}>
      {props.children}
    </Grid>
  )
}

export { GridMobile }
