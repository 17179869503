import {
  ActionType,
  CategoryType,
  EventName,
  InteractionDetail,
  TabName,
  type EventBaseData,
  type EventTracker,
} from './events.types'

interface FinacialEvents {
  clickFinancialTab: () => void
  clickBilletDownload: (billetId: string) => void
  clickBilletAccordion: (billetId: string) => void
}

const createFinancialEvents = (tracker: EventTracker, data: EventBaseData): FinacialEvents => ({
  clickFinancialTab: function () {
    tracker.track({
      event: 'event',
      action: ActionType.clickButton,
      category: CategoryType.finanancial,
      label: 'menu_financeiro',
    })
  },
  clickBilletDownload: function (billetId: string) {
    tracker.track({
      event: 'event',
      action: ActionType.clickButton,
      category: CategoryType.finanancial,
      label: `baixar-arquivo:${billetId}`,
    })
    tracker.track({
      event: 'analytics-event',
      event_parameters: {
        event_name: EventName.interactionAttendance,
        cd_interaction_detail: `${InteractionDetail.click}:baixar-arquivo`,
        cd_codigo_atendimento: data.attendanceId,
        cd_codigo_representante: data.reId,
        cd_section: `${TabName.financial}:historico-de-titulos`,
        transaction_id: billetId,
      },
    })
  },
  clickBilletAccordion: function (billetId: string) {
    tracker.track({
      event: 'event',
      action: ActionType.clickAccordion,
      category: CategoryType.finanancial,
      label: `ver-detalhes:${billetId}`,
    })
    tracker.track({
      event: 'analytics-event',
      event_parameters: {
        event_name: EventName.interactionAttendance,
        cd_interaction_detail: `${InteractionDetail.click}:ver-detalhes`,
        cd_codigo_atendimento: data.attendanceId,
        cd_codigo_representante: data.reId,
        cd_section: `${TabName.financial}:historico-de-titulos`,
        transaction_id: billetId,
      },
    })
  },
})

export { createFinancialEvents, type FinacialEvents }
