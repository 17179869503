import { Flex, styled, FloraTypography as Typography } from '@grupoboticario/flora-react'

const Container = styled(Flex, {
  variants: {
    size: {
      sm: {
        maxInlineSize: '280px',
      },
      md: {
        maxInlineSize: '280px',
        paddingTop: '$6',
      },
      lg: {
        paddingTop: '$8',
        maxInlineSize: '460px',
      },
    },
  },
})

const Description = styled(Typography, {
  color: '$nonInteractiveAuxiliar',
  fontWeight: '$regular',
  lineHeight: '$short',
  textAlign: 'center',

  variants: {
    size: {
      sm: {
        fontSize: '$auxiliar',
      },
      md: {
        fontSize: '$bodySmall',
      },
      lg: {
        fontSize: '$bodySmall',
      },
    },
  },
})

const IconWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'auto',
  height: 'auto',

  svg: {
    path: {
      fill: '$nonInteractiveAltAuxiliar !important',
    },
  },

  variants: {
    size: {
      sm: {
        marginBottom: '$6',

        svg: {
          width: '$16',
          height: '$16',
        },
      },
      md: {
        marginBottom: '$6',

        svg: {
          width: '$16',
          height: '$16',
        },
      },
      lg: {
        marginBottom: '$8',

        svg: {
          width: '128px',
          height: '128px',
        },
      },
    },
  },
})

const Title = styled(Typography, {
  color: '$nonInteractiveAuxiliar',
  fontWeight: '$medium',

  variants: {
    size: {
      sm: {
        fontSize: '$lg',
        lineHeight: '$base',
        marginBottom: '$2',
      },
      md: {
        fontSize: '$lg',
        lineHeight: '$base',
        marginBottom: '$2',
      },
      lg: {
        fontSize: '$2xl',
        lineHeight: '$short',
        marginBottom: '$4',
      },
    },
  },
})

export { Container, Description, IconWrapper, Title }
