const downloadFile = (fileLink: string): void => {
  const link = document.createElement('a')
  document.body.appendChild(link)
  link.href = fileLink
  link.target = '_blank'
  link.dispatchEvent(new MouseEvent('click'))
  document.body.removeChild(link)
}

export { downloadFile }
