import { Accordion, Pagination } from '@grupoboticario/flora-react'

import { ListCollapse } from './list-collapse'
import { ListContainer, StyledList } from './list.styles'
import { type ListProps } from './list.types'

const List = <T,>(props: ListProps<T>): JSX.Element => {
  const { data, renderItem, type, pagination } = props

  const listItems = data.map((item, index) => <li key={index}>{renderItem(item, index)}</li>)
  let listComponent = <StyledList>{listItems}</StyledList>

  if (type === 'accordion') {
    listComponent = (
      <StyledList as={Accordion} type="multiple" variant="standard">
        {listItems}
      </StyledList>
    )
  }

  if (type === 'collapse') {
    listComponent = <ListCollapse {...props} />
  }

  return (
    <ListContainer>
      {listComponent}

      {pagination && (
        <Pagination
          page={pagination.page - 1}
          totalRecords={pagination.total}
          itemsPerPage={pagination.itemsPerPage}
          onChange={(p) => pagination.onPaginate(p + 1)}
        />
      )}
    </ListContainer>
  )
}

List.defaultProps = {
  pagination: false,
}

export { List }
export type { ListProps }
