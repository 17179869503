import { Fragment } from 'react'

import { type ResellerGeneral } from '@reseller-data/providers'
import { DataBlock, DataSection } from '@shared/components'
import { TabName } from '@shared/events'
import { useIsMobile } from '@shared/hooks'
import { useTranslation } from '@shared/i18n'
import { getUserInitials } from '@shared/utils/get-user-initials'

interface ResumedInfoProps {
  general: ResellerGeneral
}

const ResumedInfo = ({ general }: ResumedInfoProps): JSX.Element => {
  const { t } = useTranslation('resumedInfo')
  const isMobile = useIsMobile()

  return (
    <DataSection
      filled
      data={{ code: general.geraId, name: general.name }}
      render={(data) => (
        <Fragment>
          {!isMobile && (
            <DataBlock
              title={t('dataBlock.registerName')}
              description={{ text: data.name }}
              avatar={getUserInitials(data.name)}
            />
          )}

          <DataBlock
            canCopy
            tabName={TabName.general}
            sectionName="dados-gerais"
            title={t('dataBlock.code')}
            description={{ text: data.code }}
          />
        </Fragment>
      )}
    />
  )
}

export { ResumedInfo }
