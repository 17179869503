import { type ReactNode } from 'react'

import { Flex, FloraTypography as Typography } from '@grupoboticario/flora-react'

import { env } from '@shared/env'

import { EmptyState } from '../empty-state'
import { ErrorState } from '../error-state'
import { LoadingState } from '../loading-state'
import { UnavailableState } from '../unavailable-state'
import { DataContainer, Section } from './data-section.styles'

interface DataSectionProps<T> extends DataSectionStateProps {
  title?: ReactNode
  description?: ReactNode
  data?: T
  render: (data: Exclude<Required<T>, undefined>, section?: string) => ReactNode
  fallbackHeight?: number | string
}

interface DataSectionStateProps {
  loading?: boolean
  filled?: boolean
  error?: boolean
  empty?: boolean
  unavailable?: boolean
  emptyTitle?: string
  emptyDescription?: string
  onRetry?: () => void
}

const DataSection = <T,>({
  title,
  description,
  error,
  loading,
  filled,
  unavailable,
  fallbackHeight,
  render,
  data,
  onRetry,
  empty,
  emptyTitle,
  emptyDescription,
  ...others
}: DataSectionProps<T>): JSX.Element => (
  <Section {...others}>
    {title && (
      <Flex as="h3" direction="column">
        <Typography
          fontSize="bodyLargeStandard"
          fontWeight="medium"
          css={{ color: '$nonInteractivePredominant' }}
        >
          {title}
        </Typography>
        {description && (
          <Typography
            fontSize="exceptionsAuxiliar"
            fontWeight="medium"
            css={{ color: '$nonInteractiveAuxiliar' }}
          >
            {description}
          </Typography>
        )}
      </Flex>
    )}

    <DataContainer
      css={
        loading || error || empty || unavailable ? { $$height: `${fallbackHeight}px` } : undefined
      }
      state={loading || error || empty || unavailable}
    >
      {!loading && empty && (
        <EmptyState size="md" title={emptyTitle} description={emptyDescription} />
      )}
      {loading && <LoadingState />}
      {error && <ErrorState onButtonClick={onRetry} />}
      {unavailable && <UnavailableState retryDelayMs={env.ERROR_POLLING_TIMEOUT} />}
      {!empty && filled && render(data as Exclude<Required<T>, undefined>)}
    </DataContainer>
  </Section>
)

export { DataSection, type DataSectionStateProps }
