import { type IEventTracker } from '@vdi/event-tracker'
import {
  ActionType,
  CategoryType,
  EventName,
  InteractionDetail,
  TabName,
  type Event,
  type EventBaseData,
} from './events.types'

interface ProfileEvents {
  clickProfileTab: () => void
  clickOrderPagination: (pageNum: number) => void
  clickOrderAccordion: (orderNumber: string, status: string) => void
  clickOrderDetail: (orderNumber: string) => void
  clickOrderCopyComposition: (orderNumber: string) => void
  clickDownloadTitle: (orderNumber: string) => void
}

const createProfileEvents = (
  tracker: IEventTracker<Event, string>,
  data: EventBaseData,
): ProfileEvents => ({
  clickProfileTab: function () {
    tracker.track({
      event: 'event',
      action: ActionType.clickButton,
      category: CategoryType.profile,
      label: 'menu_perfil_re',
    })
  },
  clickOrderPagination: function (pageNumber: number) {
    tracker.track({
      event: 'event',
      action: ActionType.clickButton,
      category: CategoryType.profile,
      label: `pedidos:paginacao-${pageNumber}`,
    })
  },
  clickOrderAccordion: function (orderNumber: string, status: string) {
    tracker.track({
      event: 'event',
      action: ActionType.clickAccordion,
      category: CategoryType.profile,
      label: `ver-detalhes:${orderNumber}:${status}`,
    })

    tracker.track({
      event: 'analytics-event',
      event_parameters: {
        event_name: EventName.interactionAttendance,
        cd_interaction_detail: `${InteractionDetail.click}:accordion`,
        cd_codigo_atendimento: data.attendanceId,
        cd_codigo_representante: data.reId,
        cd_section: `${TabName.profile}:pedido`,
        transaction_id: orderNumber,
        cd_status_pedido: status,
      },
    })
  },
  clickOrderDetail: function (orderNumber: string) {
    tracker.track({
      event: 'event',
      action: ActionType.clickButton,
      category: CategoryType.profile,
      label: `botao_ver_detalhes_${orderNumber}`,
    })
  },
  clickOrderCopyComposition: function (orderNumber: string) {
    tracker.track({
      event: 'event',
      action: ActionType.clickIcon,
      category: CategoryType.profile,
      label: `copiar_composicao_pedido_${orderNumber}`,
    })
  },
  clickDownloadTitle(titleId) {
    tracker.track({
      event: 'analytics-event',
      event_parameters: {
        event_name: EventName.interactionAttendance,
        cd_interaction_detail: `${InteractionDetail.click}:baixar-arquivo`,
        cd_codigo_atendimento: data.attendanceId,
        cd_codigo_representante: data.reId,
        cd_section: `${TabName.profile}:pedido:detalhes:pagamentos`,
        transaction_id: titleId,
      },
    })
  },
})

export { createProfileEvents, type ProfileEvents }
