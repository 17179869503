import { type IEventTracker } from '@vdi/event-tracker'
import { CategoryType, EventName, InteractionDetail, type Event } from './events.types'

interface OrderEvents {
  clickOrderButton: (buttonName: string) => void
  clickOrderFilter: (filterName: string[]) => void
  clickDownloadBill: (status: Array<string>) => void
  clickCopyOrderComposition: () => void
}

const createOrderEvents = (tracker: IEventTracker<Event, string>): OrderEvents => ({
  clickOrderButton: function (buttonName: string) {
    tracker.track({
      event: 'analytics-event',
      event_parameters: {
        EventName: EventName.interactionAttendance,
        cd_interaction_detail: `${CategoryType.orders}:${InteractionDetail.click}:${buttonName}`,
      },
    })
  },

  clickOrderFilter: function (filterName: string[]) {
    if (!filterName.length) {
      return
    }

    const filterNameString = filterName.join(',').toLowerCase()

    tracker.track({
      event: 'analytics-event',
      event_parameters: {
        EventName: EventName.interactionAttendance,
        cd_interaction_detail: `${CategoryType.orders}:${InteractionDetail.filter}:${filterNameString}`,
      },
    })
  },

  clickDownloadBill: function (status: Array<string>) {
    const stringStatus = status.join(',').toLowerCase()

    tracker.track({
      event: 'analytics-event',
      event_parameters: {
        EventName: EventName.interactionAttendance,
        cd_interaction_detail: `${CategoryType.orders}:${stringStatus}:baixar-boleto`,
      },
    })
  },

  clickCopyOrderComposition: function () {
    tracker.track({
      event: 'analytics-event',
      event_parameters: {
        EventName: EventName.interactionAttendance,
        cd_interaction_detail: `${CategoryType.orders}:${InteractionDetail.click}:copiar-composicao`,
      },
    })
  },
})

export { createOrderEvents, type OrderEvents }
