import React, { createContext } from 'react'

import { ResellerDataTab } from '@reseller-data/reseller-data.types'
import {
  ResellerDataTabsContextType,
  ResellerDataTabsProviderProps,
} from './reseller-data-tabs-provider.types'

const ResellerDataTabsContext = createContext<ResellerDataTabsContextType | null>(null)

const ResellerDataTabsProvider: React.FC<ResellerDataTabsProviderProps> = ({ children }) => {
  const [activeTab, setActiveTab] = React.useState<string>(ResellerDataTab.GENERAL)

  const value = React.useMemo(
    () => ({
      activeTab,
      setActiveTab,
    }),
    [activeTab, setActiveTab],
  )

  return (
    <ResellerDataTabsContext.Provider value={value}>{children}</ResellerDataTabsContext.Provider>
  )
}

export { ResellerDataTabsContext, ResellerDataTabsProvider }
