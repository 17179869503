import { Flex, Pagination, FloraTypography as Typography } from '@grupoboticario/flora-react'
import { GridMobile } from '@shared/components'
import { useTranslation } from '@shared/i18n'

interface CCRPaginationProps {
  loading: boolean
  setPage: (p: number) => void
  page: number
  totalItems: number
  pageSize: number
  amountItems: number
}

const CCRMovementsPagination: React.FC<CCRPaginationProps> = ({
  loading,
  setPage,
  page,
  totalItems,
  pageSize,
  amountItems,
}) => {
  const { t } = useTranslation('resellerFidelityMovements')

  return (
    <GridMobile templateColumns="1fr 1fr 1fr" mobileTemplateColumns="1fr" gap="$4">
      <Flex
        align="center"
        css={{ justifyContent: 'center', '@mobile': { justifyContent: 'start' } }}
      >
        <Typography
          fontSize="bodySmallShort"
          fontWeight="medium"
          css={{
            color: '$nonInteractiveAuxiliar',
          }}
        >
          {loading
            ? t('loadingData')
            : t('showingOfResults', {
                pageSize: amountItems,
                totalItems: totalItems,
              })}
        </Typography>
      </Flex>

      <Flex justify="center" align="center">
        <Pagination
          page={page - 1}
          totalRecords={totalItems}
          itemsPerPage={pageSize}
          onChange={(p) => setPage(p + 1)}
          css={{
            button: {
              marginRight: '$1',
              '&:last-child': {
                marginRight: 0,
              },
            },
          }}
        />
      </Flex>
    </GridMobile>
  )
}

export { CCRMovementsPagination }
