import { styled } from '@grupoboticario/flora-react'

const OrderHistoryContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  gap: '$2',
})

export { OrderHistoryContainer }
