import { useContext } from 'react'

import {
  ResellerDataContext,
  type ResellerAddresses,
  type ResellerContact,
  type ResellerDataContextProps,
  type ResellerDataDTO,
  type ResellerGeneral,
  type ResellerStatuses,
} from '@reseller-data/providers'
import { ResellerAttendanceStatus } from '@shared/types'

interface UseResellerData extends Omit<ResellerDataContextProps, 'data'> {
  model?: RessellerDataModel
  isReleasedAttendance: boolean
}

interface RessellerDataModel {
  additionalInformation: string
  statuses: ResellerStatuses
  general: ResellerGeneral
  contact: ResellerContact
  address: ResellerAddresses
}

const useResellerData = (): UseResellerData => {
  const context = useContext(ResellerDataContext)
  if (!context) throw new Error('Reseller data context not found')

  const { data, ...state } = context

  return {
    model: toResellerGeneralModel(data),
    isReleasedAttendance: canReleaseAttendance(data?.actionStatus),
    ...state,
  }
}

const canReleaseAttendance = (actionStatus?: ResellerAttendanceStatus): boolean =>
  actionStatus === ResellerAttendanceStatus.ATTENDANCE_RELEASED ||
  actionStatus === ResellerAttendanceStatus.IN_ATTENDANCE_BY_YOURSELF

const toResellerGeneralModel = (data?: ResellerDataDTO): RessellerDataModel | undefined => {
  if (!data) return

  const { address, contact, ...others } = data
  const { isBlocked, isDefaulter, actionStatus, additionalInformation, ...general } = others

  return {
    statuses: { isBlocked, isDefaulter, actionStatus },
    general: { ...general, overdue: isDefaulter },
    additionalInformation,
    address,
    contact,
  }
}

export { useResellerData, type RessellerDataModel }
