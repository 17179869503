import { useEffect, useState } from 'react'

import { Box, Flex, Tag, Tooltip, FloraTypography as Typography } from '@grupoboticario/flora-react'
import { ArrowLeftIcon, QuestionMarkCircleIcon } from '@grupoboticario/flora-react-icons'

import { useCurrentAttendanceContext } from '@register-attendance/providers'

import { useResellerData } from '@reseller-data/hooks'

import { Avatar, AvatarContainer } from '@shared/components/data-block'
import { useTranslation } from '@shared/i18n'
import { getUserInitials } from '@shared/utils'

import { EndAttendanceButton } from '../end-attendance-button'

const HeaderMobile: React.FC = () => {
  const [expanded, setExpanded] = useState(true)

  const { model } = useResellerData()
  const { t } = useTranslation(['resellerData', 'generalInfo'])
  const { setCurrentAttendance } = useCurrentAttendanceContext()

  useEffect(() => {
    const handleScroll = (event: MessageEvent): void => {
      if (event.data.name === 'reseller-data-scrolled') {
        if (event.data.scrolledToTop) {
          setExpanded(true)
        } else {
          setExpanded(false)
        }
      }
    }

    window.addEventListener('message', handleScroll)
    return () => {
      window.removeEventListener('message', handleScroll)
    }
  }, [])

  const toggleHeader = (): void => {
    setExpanded(!expanded)
  }

  return (
    <div>
      <Flex
        align="center"
        css={{
          height: expanded ? 56 : 0,
          padding: '0 $2',
          overflow: 'hidden',
          transition: 'height 0.2s ease-out',
        }}
      >
        <Box
          onClick={() => setCurrentAttendance(null)}
          role="button"
          aria-label={t('leaveAttendanceMobile')}
          css={{ cursor: 'pointer', padding: '$2' }}
        >
          <ArrowLeftIcon size={20} />
        </Box>

        <Typography fontSize="bodyLargeStandard" fontWeight="medium">
          {t('mobileTitle')}
        </Typography>
      </Flex>

      <Flex
        direction="column"
        gap="$2"
        css={{
          backgroundColor: '$backgroundPrimary',
          padding: '$2 $4',
          position: !expanded ? 'fixed' : 'initial',
          top: expanded ? 120 : 0,
          right: 0,
          left: 0,
          zIndex: !expanded ? '$overlay' : '$auto',
          overflow: 'hidden',
          transition: 'top 0.2s ease-out',
        }}
        onClick={toggleHeader}
      >
        <Flex>
          <Flex align="center">
            <AvatarContainer
              css={{
                position: 'initial',
                height: '$8',
              }}
            >
              <Avatar
                css={{
                  height: '$8',
                  width: '$8',
                }}
              >
                <Typography fontSize="bodyLargeStandard">
                  {getUserInitials(model?.general.name)}
                </Typography>
              </Avatar>
            </AvatarContainer>
          </Flex>

          <Flex
            align="flex-start"
            direction="column"
            gap="$2"
            css={{ flex: 1, minWidth: 0, maxWidth: '100%' }}
          >
            <Flex gap="$2" align="center" css={{ minWidth: 0, maxWidth: '100%' }}>
              <div
                style={{
                  whiteSpace: expanded ? 'initial' : 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                <Typography fontSize="bodyLargeStandard" fontWeight="medium">
                  {model?.general.name}
                </Typography>
              </div>

              <Tooltip
                text={t('generalInfo:dataBlock.registerNameTooltip')}
                icon={<QuestionMarkCircleIcon />}
                iconLabel={t('toolTipBalloonDefault')}
                direction="bottom"
                showArrow
              >
                <QuestionMarkCircleIcon size="16px" />
              </Tooltip>
            </Flex>

            <Tag variant="disabled">{model?.general.geraId}</Tag>
          </Flex>
        </Flex>

        {expanded && <EndAttendanceButton size="medium" css={{ position: 'initial' }} isFull />}
      </Flex>
    </div>
  )
}

export { HeaderMobile }
