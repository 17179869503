import { styled } from '@grupoboticario/flora-react'

const CardHeaderLeft = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  gap: '$1',

  '& > span': {
    width: 'max-content',
  },
})

export { CardHeaderLeft }
