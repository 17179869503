import { styled } from '@grupoboticario/flora-react'

const ListItem = styled('div', {
  display: 'inline-flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '100%',
  minHeight: '56px',
  fontSize: '$bodyLarge',
  fontWeight: '$medium',
  lineHeight: '$short',
  padding: '$2 $8',

  small: {
    display: 'inline-block',
    width: '100%',
  },
})

const TopProductsContent = styled('div', {
  minHeight: 35,
})

export { ListItem, TopProductsContent }
