import { StyledSkeleton } from './loading-skeleton.styles'

interface SkeletonProps {
  size: 'small' | 'medium' | 'large' | 'full'
}

const Skeleton = ({ size }: SkeletonProps): JSX.Element => {
  return <StyledSkeleton size={size} />
}

export { Skeleton }
