import { useCallback } from 'react'

import { useAttendanceListContext } from '@register-attendance/providers'

import { ErrorTypes, MetaState, useApi, useAsyncState, type AsyncState } from '@shared/api'

import { type AttendanceItem } from '../modules/attendance-list/attendance-list.types'

interface UseAttendanceList extends AsyncState {
  getAttendanceList: () => void
  attendances: AttendanceItem[]
  notFound: boolean
}

const useAttendanceList = (): UseAttendanceList => {
  const { list, setList, status } = useAttendanceListContext()
  const asyncState = useAsyncState(status)
  const { getAttendances } = useApi()

  const getAttendanceList = useCallback(async () => {
    setList({ status: MetaState.LOADING })
    const result = await getAttendances<AttendanceItem[]>()

    const notFound = !result.ok && result.error.messageType === ErrorTypes.NOT_FOUND
    const isEmpty = result.ok && result.value.length === 0

    if (notFound || isEmpty) {
      setList({ status: MetaState.NOT_FOUND })
    } else if (result.ok) {
      setList({ list: result.value, status: MetaState.FILLED })
    } else {
      setList({ status: MetaState.ERROR })
    }
  }, [getAttendances, setList])

  return {
    attendances: list,
    getAttendanceList,
    ...asyncState,
  }
}

export { useAttendanceList }
