import { TagProps as TagPropsFlora } from '@grupoboticario/flora-react'

enum ResellerPaymentStatus {
  OPENED = 'ABERTO',
  PAID_OFF = 'LIQ_CANC_RENE',
}

enum ResellerTitleStatus {
  OVERDUE = 'VENCIDO',
  NOT_OVERDUE = 'NAO_VENCIDO',
}

interface TagProps {
  text: string
  color: TagPropsFlora['variant']
}

export { ResellerPaymentStatus, ResellerTitleStatus, type TagProps }
