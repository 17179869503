import React, { Fragment } from 'react'

import { Flex, FloraTypography as Typography } from '@grupoboticario/flora-react'
import { GridMobile, Table } from '@shared/components'
import { useTranslation } from '@shared/i18n'

import { Alert } from '@grupoboticario/flora-team-react-alert'
import { useIsMobile } from '@shared/hooks'
import { OrderProduct } from '../../order-detail.types'
import { ProductItem } from './product-item'

interface ProductListProps {
  products: OrderProduct[]
  title: string
  alert?: boolean
}

const ProductList: React.FC<ProductListProps> = ({ products, title, alert = false }) => {
  const { t } = useTranslation('orders', { keyPrefix: 'orderHistory.detail.products' })
  const isMobile = useIsMobile()

  const alertComponent = () => {
    return !isMobile ? (
      <tr data-testid="alert-no-gifts">
        <td colSpan={3}>
          <Alert status="info" hideCloseButton={true} title={t('notFoundGifts')} />
        </td>
      </tr>
    ) : (
      <tr data-testid="alert-no-gifts">
        <GridMobile
          templateColumns="4fr 4fr"
          mobileTemplateColumns="4fr"
          gap="$1"
          css={{ padding: '$4 $4' }}
        >
          <Flex direction="column">
            <Alert status="info" hideCloseButton={true} title={t('notFoundGifts')} />
          </Flex>
        </GridMobile>
      </tr>
    )
  }

  const desktopColumnsList = [t('quantity'), t('values')]

  const renderDesktopColumns =
    !isMobile &&
    desktopColumnsList.map((item) => (
      <th key={item}>
        <Typography as="span" screenReaderOnly>
          {item}
        </Typography>
      </th>
    ))

  return (
    <Fragment>
      <Table css={{ padding: '0px' }}>
        <thead>
          <tr>
            <th scope="col">{title}</th>
            {renderDesktopColumns}
          </tr>
        </thead>

        <tbody>
          {alert && products.length <= 0 && alertComponent()}
          {products.map((p) => (
            <ProductItem key={p.code} product={p} />
          ))}
        </tbody>
      </Table>
    </Fragment>
  )
}

export { ProductItem, ProductList }
