import { useState } from 'react'

import { Alert } from '../alert'

import {
  CheckCircleIcon,
  CrossCircleIcon,
  TwoPiledSquaresIcon,
} from '@grupoboticario/flora-react-icons'

import { useEvents } from '@shared/events'
import { useTranslation } from '@shared/i18n'

import { Container } from './can-copy-button.styles'
import { AlertProps, CanCopyButtonProps } from './can-copy-button.types'

const WHITE_BACKGROUND = '$backgroundPrimary'

const CanCopyButton = ({
  textToCopy,
  clipboardText,
  textName,
  sectionName,
  tabName,
  onCopy,
}: CanCopyButtonProps): JSX.Element => {
  const { t } = useTranslation('canCopyButton')
  const events = useEvents()

  const [alert, setAlert] = useState<AlertProps>({
    show: false,
    type: 'success',
    text: '',
  })

  const copyContentText = t('copyContent', { content: textName })
  const contentCopiedText = t('contentCopied', { content: textName })
  const copyContentErrorText = t('error')

  const copyContent = async (): Promise<void> => {
    onCopy?.()
    events.clickCopyData(textName, sectionName, tabName)

    const selectedText = textToCopy ?? clipboardText

    try {
      await navigator.clipboard.writeText(selectedText)
      setAlert({
        show: true,
        type: 'success',
        text: contentCopiedText,
      })
    } catch {
      setAlert({
        show: true,
        type: 'error',
        text: copyContentErrorText,
      })
    }
  }

  const handleAlertClose = (): void => {
    setAlert({ show: false, type: 'success', text: '' })
  }

  const ariaLabelText = alert.type === 'success' ? contentCopiedText : copyContentErrorText

  const checkCircleIcon = <CheckCircleIcon color={WHITE_BACKGROUND} size="14px" />

  const crossCircleIcon = <CrossCircleIcon color={WHITE_BACKGROUND} size="14px" />

  const alertIcon = alert.type === 'success' ? checkCircleIcon : crossCircleIcon

  return (
    <Container aria-label={copyContentText} onClick={copyContent}>
      <TwoPiledSquaresIcon aria-label={copyContentText} color="$actionableDefault" />

      <Alert
        show={alert.show}
        type={alert.type}
        aria-label={ariaLabelText}
        icon={alertIcon}
        description={alert.text}
        onClose={handleAlertClose}
      />
    </Container>
  )
}

export { CanCopyButton }
export type { CanCopyButtonProps }
