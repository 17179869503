import { Fragment } from 'react'

import { useCurrentAttendanceContext } from '@register-attendance/providers'
import { ErrorState, LoadingState, UnavailableState } from '@shared/components'

import { env } from '@shared/env'
import { useEvents } from '@shared/events'
import { useTranslation } from '@shared/i18n'

import { useResellerData } from '../../hooks'

import { GeneralDataContainer } from './general-data.styles'
import { ReleasedInfo } from './released-info'
import { UnreleasedInfo } from './unreleased-info'

const GeneralData = (): JSX.Element => {
  const { t } = useTranslation(['generalData', 'common'])
  const { model, isReleasedAttendance, refetch, ...status } = useResellerData()
  const { currentAttendance } = useCurrentAttendanceContext()
  const events = useEvents()

  const handleRetry = (): void => {
    events.clickRetryGeneralData()
    refetch()
  }

  return (
    <GeneralDataContainer loading={status.loading}>
      {status.loading && (
        <LoadingState
          title={t('loadingState.title')}
          description={t('loadingState.subtitle')}
          containerHeight="calc(100vh - (48px * 5))"
        />
      )}

      {status.unavailable && <UnavailableState retryDelayMs={env.ERROR_POLLING_TIMEOUT} />}

      {status.error && (
        <ErrorState
          title={t('errorState.title')}
          description={t('errorState.subtitle')}
          buttonText={t('common:tryAgain')}
          onButtonClick={handleRetry}
        />
      )}

      {status.filled && model && (
        <Fragment>
          {isReleasedAttendance && (
            <ReleasedInfo
              model={model}
              attendanceId={currentAttendance?.attendanceId}
              onUpdateAdditionalInfo={refetch}
            />
          )}

          {!isReleasedAttendance && <UnreleasedInfo general={model.general} />}
        </Fragment>
      )}
    </GeneralDataContainer>
  )
}

export { GeneralData }
